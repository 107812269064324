export default function convertToRenderFormat(data) {
  const transformation_name = data.transformation_name;
  const input_df = data.input_df || "";
  const output_df = data.output_df || "";
  const expression_list = {};

  // Extract the prefix dynamically
  Object.keys(data).forEach((key) => {
    const match = key.match(/(.+?):\d+$/); // Match any prefix followed by an colon and numbers
    if (match && typeof data[key] === "object") {
      expression_list[`${transformation_name}:${data[key].sequence}`] =
        data[key];
    }
  });

  const transformedData = {};
  transformedData[transformation_name] = {
    transformation_name,
    input_df,
    output_df,
    expression_list,
  };

  return transformedData;
}