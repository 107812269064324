import * as React from 'react';
import { useTheme, Box, Button, Select, Typography } from "@mui/material";
import { DataGrid, GridToolbar, useGridApiContext } from '@mui/x-data-grid';
import UsersActions from './UsersActions';
import FlexBetween from "../components/FlexBetween";
import Header from "../components/Header";
import PropTypes from "prop-types";
import ArrowDropDownCircleRoundedIcon from '@mui/icons-material/ArrowDropDownCircleRounded';
import WrapTextRoundedIcon from '@mui/icons-material/WrapTextRounded';
import HighlightAltRoundedIcon from '@mui/icons-material/HighlightAltRounded';
import {
  useLazyGetLnkuserprojectsByIdandNameQuery,
  useLazyGetLnkuserprojectsRowsandColumnsQuery
} from '../state/apiSlice'
import useStyles from 'useStyles';
import Loading from 'components/elements/Loading';
const SelectEditInputCell = (props) => {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();
  const [options, setOptions] = React.useState([]);
  const [getLnkuserprojectsByIdandName] = useLazyGetLnkuserprojectsByIdandNameQuery();
  React.useEffect(() => {
    const fetchOptions = async () => {
      const response = await getLnkuserprojectsByIdandName();
      if (field === 'user_id') {
        // fetch options for user_id
        const json = response.data;
        setOptions(json[0].result.users.map((user) => ({ value: user.id, label: user.name })));
      } else if (field === 'program_id') {
        // fetch options for program_id
        const json = response.data;
        setOptions(json[0].result.programs.map((program) => ({ value: program.id, label: program.name })));
      } else if (field === 'project_id') {
        // fetch options for project_id
        // const json = response?.data;
        const json = response.data || []; // handle null or undefined

        setOptions(json[0].result.projects.map((project) => ({ value: project.id, label: project.name })));
      }
    };

    fetchOptions();
  }, [field]);

  const handleChange = async (event) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
  };

  return (
    <Select
      value={value}
      onChange={handleChange}
      size="small"
      sx={{ height: 1 }}
      native
      autoFocus
    >
      {options.map((option) => (
        <option key={option.label} value={option.label}>
          {option.label}
        </option>
      ))}
    </Select>
  );
};

SelectEditInputCell.propTypes = {
  /** he column field of the cell that triggered the event. */
  field: PropTypes.string.isRequired,
  /** The grid row id.  */
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /** The cell value, If the column has `valueGetter`, use `params.row` to directly access the fields.*/
  value: PropTypes.any,
};

const renderSelectEditInputCell = (params) => {
  return <SelectEditInputCell {...params} />;
};

const UserAccess = () => {
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const styles = useStyles();
  const [rows, setRows] = React.useState([]);

  const [rowId, setRowId] = React.useState(null);

  let pageTitle = "User Access";
  let pageSubTitle = "Access to User Permissions";
  const [getLnkuserprojectsRowsandColumns] = useLazyGetLnkuserprojectsRowsandColumnsQuery();

  React.useEffect(() => {

    const fetchRowsAndColumns = async () => {
      try {
        const response = await getLnkuserprojectsRowsandColumns();
        const data = response.data;
        setRows(data.rows[0].result);
        setLoading(false); 
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchRowsAndColumns();
  }, []);

  const columns = React.useMemo(() => [
    {
      field: "id",
      headerName: "ID",
      type: "text",
      headerAlign: "center",
      // width: 25,
      editable: false,
      flex: 0.1
    },
    {
      field: "user_id",
      headerName: "User",
      type: "select",
      renderEditCell: renderSelectEditInputCell,
      // width: 170,
      editable: true,
      flex: 0.4,
      renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <ArrowDropDownCircleRoundedIcon fontSize="small" />
          <Typography fontSize={15} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>

        </div>
      ),
    },
    {
      field: "program_id",
      headerName: "Program",
      type: "select",
      renderEditCell: renderSelectEditInputCell,
      // width: 170,
      editable: true,
      flex: 0.7, renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <ArrowDropDownCircleRoundedIcon fontSize="small" />
          <Typography fontSize={15} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>

        </div>
      ),
    },
    {
      field: "project_id",
      headerName: "Project",
      type: "select",
      renderEditCell: renderSelectEditInputCell,
      // width: 170,
      editable: true,
      flex: 0.8,
      renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <ArrowDropDownCircleRoundedIcon fontSize="small" />
          <Typography fontSize={15} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>

        </div>
      ),

    },
    {
      field: "read_role",
      headerName: "Read",
      type: "boolean",
      headerAlign: "center",
      // width: 100,
      editable: true,
      flex: 0.25,
      renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <HighlightAltRoundedIcon fontSize="small" />
          <Typography fontSize={15} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>

        </div>
      ),
    },
    {
      field: "write_role",
      headerName: "Write",
      type: "boolean",
      headerAlign: "center",
      // width: 100,
      editable: true,
      flex: 0.25,
      renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <HighlightAltRoundedIcon fontSize="small" />
          <Typography fontSize={15} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>

        </div>
      ),
    },
    {
      field: "execute_role",
      headerName: "Execute",
      type: "boolean",
      headerAlign: "center",
      //  width: 100,
      editable: true,
      flex: 0.3,
      renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <HighlightAltRoundedIcon fontSize="small" />
          <Typography fontSize={15} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>
        </div>
      ),
    },
    {
      field: "is_active",
      headerName: "Active",
      align: 'center',
      type: "text",
      headerAlign: "center",
      // width: 100,
      editable: true,
      flex: 0.28,
      renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <WrapTextRoundedIcon fontSize="small" />
          <Typography fontSize={15} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>
        </div>
      ),
    },
    {
      field: "created_by",
      headerName: "Created By",
      type: "text",
      headerAlign: "center",
      // width: 100,
      editable: true,
      flex: 0.4,
      renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <WrapTextRoundedIcon fontSize="small" />
          <Typography fontSize={15} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>
        </div>
      ),
    },

    {
      field: "updated_by",
      headerName: "Updated By",
      type: "text",
      headerAlign: "center",
      // width: 100,
      editable: true,
      flex: 0.45, renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: "2px" }}>
          <WrapTextRoundedIcon fontSize="small" />
          <Typography fontSize={15} fontWeight={500}>
            {params.colDef.headerName}
          </Typography>
        </div>
      ),
    },

    {
      field: "actions",
      headerName: "Actions",
      type: "actions",
      headerAlign: "center",
      //  width: 150,
      flex: 0.28,
      renderCell: (params) => (
        <UsersActions {...{ params, rowId, setRowId }} />
      ),
    },
  ], [rowId]
  )


  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      { id: "", user_id: "", program_id: "", read_role: false, write_role: false, execute_role: false, }
    ]);
    setRowId(null);
  };

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <FlexBetween sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
          <Header title={pageTitle} subtitle={pageSubTitle} />
          <Button
            variant="contained"
            sx={styles.myBackground}
            onClick={handleAddRow}
          >
            Grant Permissions
          </Button>
        </FlexBetween>

      </Box>
      {loading ? (
        <Loading />
        ) : (
      <Box m="1.5rem 2.5rem"
        height="75vh"
        sx={{
          "& .MuiDataGrid-row.Mui-odd": {
            backgroundColor: "var(--tertiary-color) !important",
          },
          "& .MuiDataGrid-row.Mui-disabled-row": {
            backgroundColor: "lightgrey",
          },


          "& .MuiDataGrid-root": {
            border: "none",
            fontWeight: "600",
            fontSize: "12px !important",
            fontFamily: "Poppins",
          },

          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "var(--primary-color) !important",
            border: "none",
            color: "#ffffff!important",
            fontSize: "15px!important",
            fontWeight: "bold!important",
            lineHeight: "38px!important",
            minHeight: "38px!important",
            maxHeight: "38px!important",
            fontFamily: "Poppins",
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#f5f5f5",
            minHeight: "352px!important",
            // maxHeight: "323px!important",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "var(--pagination-bottom-color) !important",
            lineHeight: "38px!important",
            minHeight: "38px!important",
            maxHeight: "38px!important",
            borderRadius: "0 0 6px 6px",
            marginTop: "5px!important",
            marginTop: "5px!important",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "black!important",
            fontSize: "12px",
            width: "100px",
            marginBottom: "1px",
            fontFamily: "Poppins",
          },
          "& .MuiCheckbox-root.Mui-checked": {
            color: "#4b437cd1!important",
          },
          "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
            color: 'white', // Ensure filter icons and three-dot menu icons are white
          },

        }}
      >

<DataGrid
         rowHeight={40}
         rows={rows}
         columns={columns}
         components={{ Toolbar: GridToolbar }}
         getRowClassName={(params) =>
               
           params.indexRelativeToCurrentPage % 2 === 0
             ? "Mui-odd"
             : "Mui-even"
         }
         getRowId={row => row.id}
         pageSize={10}
         rowsPerPageOptions={[10, 20,30]}
         // // checkboxSelection
         disableSelectionOnClick
       onCellEditCommit={params => setRowId(params.id)}

                  />

      </Box>
    )}
    </>
  );
};

export default UserAccess;