import React from "react";
import {
  Select as MuiSelect,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";

const Select = (props) => {
  const {
    field_id,
    display_name,
    options,
    handleBlur,
    handleChange,
    touched,
    errors,
    values,
  } = props;

  return (
    <FormControl
      sx={{ 
        gridColumn: "span 4", 
        width: "360px!important", 
        marginLeft: "12px", 
        minHeight: "10px" 
      }}
      error={!!touched[field_id] && !!errors[field_id]}
    >
      <InputLabel
        id={`${field_id}-label`}
        style={{ color: 'black' }}
        shrink
        sx={{
          '&.MuiInputLabel-shrink': {
            fontSize: '18px', // Adjust this value as needed for the larger font size when shrunk
            backgroundColor: 'white', // Adds white background behind the label
            padding: '0 4px', // Optional: Adds some padding for better appearance
            marginLeft: '-4px', // Optional: Adjusts margin to avoid shifting the label
          },
        }}
      >
        {display_name}
      </InputLabel>

      <MuiSelect
        labelId={`${field_id}-label`}
        id={`${field_id}-select`}
        label={display_name}
        name={field_id}
        fullWidth
        value={values != null ? values[field_id] : ""}
        onBlur={handleBlur}
        onChange={handleChange}
        displayEmpty
        sx={{ gridColumn: "span 4" }}
      >
        <MenuItem value="">
          <em style={{ color: 'grey' }}>None</em>
        </MenuItem>

        {Array.isArray(options) &&
          options.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
      </MuiSelect>
      {touched[field_id] && errors[field_id] && (
        <FormHelperText>{errors[field_id]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
