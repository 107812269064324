import React, { useState, useRef, useEffect } from "react";
import { TextField } from "@mui/material";

const OptimizedTextField = ({
  field_id,
  display_name,
  field_type,
  handleBlur,
  handleChange,
  disabled,
  touched,
  errors,
  values,
  ...otherProps
}) => {
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(values[field_id] || "");

  // Effect to update input value if form values change externally
  useEffect(() => {
    if (inputRef.current && inputRef.current.value !== values[field_id]) {
      setInputValue(values[field_id] || "");
    }
  }, [values, field_id]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setInputValue(value); // Update local state first to manage the cursor
    handleChange({
      target: {
        name: field_id,
        value,
      },
    });
  };

  return (
    <TextField
      id={field_id}
      fullWidth
      variant="outlined"
      type={field_type}
      label={display_name}
      value={inputValue} // Controlled input using local state
      InputLabelProps={{
        style: { color: "black", fontSize: "18px" },
        shrink: true, // Force label to shrink always
      }}
      onBlur={handleBlur}
      onChange={handleInputChange}
      disabled={disabled}
      name={field_id}
      error={!!touched[field_id] && !!errors[field_id]}
      helperText={touched[field_id] && errors[field_id]}
      sx={{
        gridColumn: "span 4",
        width:
        ["input_col_name", "output_col_name", "expression_value"].includes(
          field_id
        )
          ? "160px!important"
          : "360px!important",
        marginLeft: "12px",
        minHeight: "10px",
        fontFamily: 'Poppins, sans-serif',
        '&.MuiInputLabel-shrink': {
          fontSize: '16px', // Adjust this value as needed for the larger font size when shrunk
          backgroundColor: 'white', // Adds white background behind the label
          padding: '0 4px', // Optional: Adds some padding for better appearance
          marginLeft: '-4px', // Optional: Adjusts margin to avoid shifting the label
        },
      }}
      inputRef={inputRef} // Reference to directly manipulate DOM if necessary
      {...otherProps} // Spread any additional props
    />
  );
};

// Memoize the component to prevent unnecessary re-renders
export default React.memo(OptimizedTextField);
