import Close from "@mui/icons-material/Close";
import {
  Box,
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
  InputAdornment,
  CircularProgress
} from "@mui/material";
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useDispatch, useSelector } from "react-redux";
import { setOpenLogin, setCredentials, setAuthType } from "../state/authSlice";
import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  useLoginMutation,
  useRegisterUserMutation,
  useForgetPasswordMutation,
} from "../state/apiSlice";
import {toast} from 'react-hot-toast';
import MsalLogin from "./MsalLogin";
import { saveTokenInLocalStorage } from "../state/authService";
import useStyles from "useStyles";
const LoginForm = (props) => {
  const { handleLoginInput, loginRef, handlePwdInput, passRef } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <TextField
        fullWidth
        label="User Name"
        variant="outlined"
        type="text"
        name="login_id"
        onChange={handleLoginInput}
        inputRef={loginRef}
        sx={{ gridColumn: "span 4", marginBottom: "4%", '& .MuiInputBase-input': { fontSize: '1.1rem' } }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
      />

      <TextField
        fullWidth
        label="Password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        name="password"
        onChange={handlePwdInput}
        inputRef={passRef}
        sx={{ gridColumn: "span 4", 
            marginBottom: "4%", 
            '& .MuiInputBase-input': { 
              fontSize: '1.1rem' 
            },
            'input::-ms-reveal': {
              display: 'none',
            }
         }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

const RegisterForm = (props) => {

  const {
    handleFullNameInput,
    nameRef,
    handleEmailInput,
    emailRef,
    handleLoginInput,
    loginRef,
    handlePwdInput,
    passRef,
    handleConfimPwdInput,
    confirmPassRef,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <TextField
        fullWidth
        label="Full Name"
        variant="outlined"
        type="text"
        name="full_name"
        onChange={handleFullNameInput}
        inputRef={nameRef}
        sx={{ gridColumn: "span 4", marginBottom: "4%", '& .MuiInputBase-input': { fontSize: '1.1rem' } }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
      />
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        type="text"
        name="user_email"
        onChange={handleEmailInput}
        inputRef={emailRef}
        sx={{ gridColumn: "span 4", marginBottom: "4%", '& .MuiInputBase-input': { fontSize: '1.1rem' } }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
      />
      <TextField
        fullWidth
        label="User Name"
        variant="outlined"
        type="text"
        name="login_id"
        onChange={handleLoginInput}
        inputRef={loginRef}
        sx={{ gridColumn: "span 4", marginBottom: "4%", '& .MuiInputBase-input': { fontSize: '1.1rem' } }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
      />
      
      <TextField
        fullWidth
        label="Password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        name="password"
        onChange={handlePwdInput}
        inputRef={passRef}
        sx={{ gridColumn: "span 4", 
            marginBottom: "4%", 
            '& .MuiInputBase-input': { 
              fontSize: '1.1rem' 
            },
            'input::-ms-reveal': {
              display: 'none',
            }
         }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        label="Confirm Password"
        variant="outlined"
        type={showConfirmPassword ? 'text' : 'password'}
        name="confirmPassword"
        onChange={handleConfimPwdInput}
        inputRef={confirmPassRef}
        sx={{ gridColumn: "span 4", 
            marginBottom: "4%", 
            '& .MuiInputBase-input': { 
              fontSize: '1.1rem' 
            },
            'input::-ms-reveal': {
              display: 'none',
            }
         }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                onMouseDown={handleMouseDownConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
const AdminForm = (props) => {

  const {
    handleFullNameInput,
    nameRef,
    handleEmailInput,
    emailRef,
    handleLoginInput,
    loginRef,
    handlePwdInput,
    passRef,
    handleConfimPwdInput,
    confirmPassRef,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((prev) => !prev);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowConfirmPassword = () => setShowConfirmPassword((prev) => !prev);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <TextField
        fullWidth
        label="Full Name"
        variant="outlined"
        type="text"
        name="full_name"
        onChange={handleFullNameInput}
        inputRef={nameRef}
        sx={{ gridColumn: "span 4", marginBottom: "4%", '& .MuiInputBase-input': { fontSize: '1.1rem' } }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
      />
      <TextField
        fullWidth
        label="Email"
        variant="outlined"
        type="text"
        name="user_email"
        onChange={handleEmailInput}
        inputRef={emailRef}
        sx={{ gridColumn: "span 4", marginBottom: "4%", '& .MuiInputBase-input': { fontSize: '1.1rem' } }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
      />
      <TextField
        fullWidth
        label="User Name"
        variant="outlined"
        type="text"
        name="login_id"
        onChange={handleLoginInput}
        inputRef={loginRef}
        sx={{ gridColumn: "span 4", marginBottom: "4%", '& .MuiInputBase-input': { fontSize: '1.1rem' } }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
      />
      <TextField
        fullWidth
        label="Password"
        variant="outlined"
        type={showPassword ? 'text' : 'password'}
        name="password"
        onChange={handlePwdInput}
        inputRef={passRef}
        sx={{ gridColumn: "span 4", 
            marginBottom: "4%", 
            '& .MuiInputBase-input': { 
              fontSize: '1.1rem' 
            },
            'input::-ms-reveal': {
              display: 'none',
            }
         }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                onMouseDown={handleMouseDownConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        fullWidth
        label="Confirm Password"
        variant="outlined"
        type={showConfirmPassword ? 'text' : 'password'}
        name="confirmPassword"
        onChange={handleConfimPwdInput}
        inputRef={confirmPassRef}
        sx={{ gridColumn: "span 4", 
            marginBottom: "4%", 
            '& .MuiInputBase-input': { 
              fontSize: '1.1rem' 
            },
            'input::-ms-reveal': {
              display: 'none',
            }
         }}
        InputLabelProps={{
          style: { color: "black" },
        }}
        autoComplete="off"
        required
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};
const ForgetPasswordForm = (props) => {

  const { handleEmailInput, emailRef } = props;

  return (
    <TextField
      fullWidth
      label="Email"
      variant="outlined"
      type="text"
      name="user_email"
      onChange={handleEmailInput}
      inputRef={emailRef}
      sx={{ width: "500px", gridColumn: "span 4", marginBottom: "4%", '& .MuiInputBase-input': { fontSize: '1.1rem' } }}
      InputLabelProps={{
        style: { color: "black" },
      }}
      autoComplete="off"
      required
    />
  );
};

const Login = (fetchAdminUser) => {
  const styles = useStyles();
  useEffect(() => {

    console.log(fetchAdminUser)
  }, [fetchAdminUser])

  const loginRef = useRef();
  const passRef = useRef();
  const nameRef = useRef();
  const confirmPassRef = useRef();
  const emailRef = useRef();
  const errRef = useRef();

  const [title, setTitle] = useState("");
  const [formType, setFormType] = useState(0);
  const [loginId, setLoginId] = useState("");
  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const navigate = useNavigate();

  const [login, { isLoading }] = useLoginMutation();
  const [registerUser] = useRegisterUserMutation();
  const [forgotPassword] = useForgetPasswordMutation();
  const dispatch = useDispatch();
  const openLogin = useSelector((state) => state.auth.openLogin);


  useEffect(() => {
    switch (formType) {
      case 0:
        {
          setTitle(fetchAdminUser?.fetchAdminUser?.data?.length !== 0 ? "Login" : "Admin Registration");
          break;
        }

      case 1:
        setTitle("Register");
        break;
      case 2:
        setTitle("Forgot Password");
        break;
      case 3:
        setTitle("Login");
        break;
      default:
        break;
    }
  }, [formType]);

  useEffect(() => {
    setErrMsg("");
  }, [loginId, pwd]);

  const handleLoginInput = (e) => setLoginId(e.target.value);
  const handlePwdInput = (e) => setPwd(e.target.value);
  const handleConfimPwdInput = (e) => setConfirmPwd(e.target.value);
  const handleFullNameInput = (e) => setFullName(e.target.value);
  const handleEmailInput = (e) => setEmail(e.target.value);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    switch (formType) {
      case 0:
        dispatch(setAuthType({authType: "userInput"}))
        try {
          const userData = await login({
            login_id: loginId,
            password: pwd,
          }).unwrap();
          let { token, ...userInfo } = userData;
          let { ...userTokenDetails } = userData;
          dispatch(setCredentials({ user: userInfo, token }));
          saveTokenInLocalStorage(userTokenDetails);
          setLoginId("");
          setPwd("");
          handleClose();
          navigate("/dashboard");
        } catch (err) {
          if (err.status === 400) {
            setErrMsg("Incorrect Username or Password");
          } else if (err.status === 401) {
            setErrMsg("Unauthorized");          
          }
          else if (err.status === 403) {
            setErrMsg("Your account is inactive. Please contact the admin for assistance.");
          }
           else if (!err?.status) {
            // isLoading: true until timeout occurs
            setErrMsg("No Server Response");
          } else {
            setErrMsg("Login Failed");
          }

          errRef.current.focus();
        }
        break;
      case 3:
        try {
          const userData = await login({
            login_id: loginId,
            password: pwd,
          }).unwrap();
          let { token, ...userInfo } = userData;
          let { ...userTokenDetails } = userData;
          dispatch(setCredentials({ user: userInfo, token }));
          saveTokenInLocalStorage(userTokenDetails);
          setLoginId("");
          setPwd("");
          handleClose();
          navigate("/dashboard");
        } catch (err) {
          if (err.status === 400) {
            setErrMsg("Incorrect Username or Password");
          } else if (err.status === 401) {
            setErrMsg("Unauthorized");
          } else if (!err?.status) {
            // isLoading: true until timeout occurs
            setErrMsg("No Server Response");
          } else {
            setErrMsg("Login Failed");
          }
          errRef.current.focus();
        }
        break;
      case 1:
        if (pwd !== confirmPwd) {
          setErrMsg("Password do not Match.");
        } else {
          try {
            const userSaved = await registerUser({
              full_name: fullName,
              login_id: loginId,
              password: pwd,
              user_email: email,
              user_phone: "123456789",
            });
            console.log(`User: ${userSaved?.data?.login_id} saved.`);
            if (userSaved?.data?.login_id !== undefined) {
              toast.success(`User Created successfully`);
            } else {
              toast.error("Some error while Registering");

            }

          } catch (err) {
            console.log(`User not saved.`);

            setErrMsg("User Registration Failed");
          }
          errRef.current.focus();
        }
        break;
      case 2:
        try {
          const { data } = await forgotPassword({
            user_email: email,
            CLIENT_URL: window.location.origin,
          });
          setErrMsg(data.message);
          setEmail("");
        } catch (err) {
          setErrMsg(err.data?.error || "User with this email does not exist");
          alert(
            "User with this email does not exist. An email has been sent to the admin for further action"
          );
        }
        break;

      default:
        break;
    }
  };
  const handleFormAdminSubmit = async (e) => {
    e.preventDefault();

    if (pwd !== confirmPwd) {
      setErrMsg("Password does not match.");
    } else {
      try {
        const userSaved = await registerUser({
          full_name: fullName,
          login_id: loginId,
          password: pwd,
          user_email: email,
          user_phone: "123456789",
          is_active: "Y",
          role: "ADMIN"
        });

        console.log(`User: ${userSaved?.data?.login_id} saved.`);

        if (userSaved?.data?.login_id !== undefined) {
          toast.success(`User created successfully`);
        } else {
          toast.error("Some error while registering");
        }

      } catch (err) {
        console.log(`User not saved.`);
        setErrMsg("User Registration Failed");
      }
      errRef.current.focus();
    }

  };

  const handleClose = () => {
    dispatch(setOpenLogin({ openLogin: false }));
  };

  function simulateSwitch(param) {
    switch (param) {
      case 0:
        return (
          <div>
            {fetchAdminUser?.fetchAdminUser?.data?.length !== 0 ? (
              // User Login Form
              <form onSubmit={handleFormSubmit}>
                <LoginForm
                  handleLoginInput={handleLoginInput}
                  loginRef={loginRef}
                  handlePwdInput={handlePwdInput}
                  passRef={passRef}
                />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" flexDirection="column">
                    <Button
                      title="Don't you have an account? Create one now!"
                      onClick={() => setFormType(1)}
                      style={styles.btnTextCss}
                    >
                      Register User
                    </Button>

                    <Button
                      title="Forgot Password. Check here to"
                      onClick={() => setFormType(2)}
                      style={styles.btnTextCss}
                    >
                      Forgot Password?
                    </Button>
                  </Box>

                  <Button
                    type="submit"
                    sx={{
                      ...styles.myBackground,
                    }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>Logging-In</Box>
               <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>          
                 <CircularProgress
                variant="determinate"
                value={100} // Value for the static gray background ring
                sx={{
                 color: "white", // Static ring color
                 position: "absolute",
                }}
               size={15}
               thickness={4}
               />
              <CircularProgress
               variant="indeterminate" // Rotating progress
               disableShrink // Keeps the size constant during rotation
               sx={{
               color: "gray", // Rotating color
               }}
               size={15}
               thickness={3}
             />
             </Box> 
          </Box>
       
          ) : (
                   <Box>Login</Box> )}
                  </Button>
                </Box>
                <MsalLogin />
              </form>
            ) : (
              // Admin Account Creation Form
              <form onSubmit={handleFormAdminSubmit}>
                <div>
                  <AdminForm

                    handleFullNameInput={handleFullNameInput}
                    nameRef={nameRef}
                    handleEmailInput={handleEmailInput}
                    emailRef={emailRef}
                    handleLoginInput={handleLoginInput}
                    loginRef={loginRef}
                    handlePwdInput={handlePwdInput}
                    passRef={passRef}
                    handleConfimPwdInput={handleConfimPwdInput}
                    confirmPassRef={confirmPassRef}
                  />
                  <Button
                    type="submit"
                    style={styles.btnAdmin}
                  >
                    Create an Admin Account
                  </Button>
                 
                </div>

                <Box>
                  <Button
                    onClick={() => setFormType(3)}
                    sx={{ width: "80px", textAlign: "left" }}
                    style={styles.btnTextCss}
                    title="Already have an Account?"
                  >
                    Sign in
                  </Button>
                </Box>
                <Box>
                  <Button
                    title="Forgot Password. Check here to reset"
                    onClick={() => setFormType(2)}
                    sx={{ width: "150px" }}
                    style={styles.btnTextCss}
                  >
                  </Button>
                </Box>
              </form>
            )}
          </div>
        );


      case 1:
        return (
          <form onSubmit={handleFormSubmit}>
            <RegisterForm
              handleFullNameInput={handleFullNameInput}
              nameRef={nameRef}
              handleEmailInput={handleEmailInput}
              emailRef={emailRef}
              handleLoginInput={handleLoginInput}
              loginRef={loginRef}
              handlePwdInput={handlePwdInput}
              passRef={passRef}
              handleConfimPwdInput={handleConfimPwdInput}
              confirmPassRef={confirmPassRef}
            />
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" flexDirection="column">
                <Button
                  onClick={() => setFormType(0)}
                  style={styles.btnTextCss}
                  title="Do you have an Account?"
                >
                  Sign in
                </Button>
                <Button
                  Title="Forgot Password. Check here to reset"
                  onClick={() => setFormType(2)}
                  style={styles.btnTextCss}
                >
                  Reset Password
                </Button>
              </Box>
        
              <Button
                type="submit"
                disabled={isLoading}
                sx={{
                  ...styles.myBackground,
                }}
              >
                Register
              </Button>
            </Box>



          </form>
        );
      case 2:
        return (
          <form onSubmit={handleFormSubmit}>
            <ForgetPasswordForm
              handleEmailInput={handleEmailInput}
              emailRef={emailRef}
            />

            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button
                onClick={() => setFormType(0)}
                Title="Do you have an Account?"
                style={styles.btnTextCss}
              >
                Sign in
              </Button>
              <Button
                type="submit"
             sx={{
                      ...styles.myBackground,
                    }}
                disabled={isLoading}
              >
                Submit
              </Button>
            </Box>
          </form>
        );
      case 3:
        return (
          <form onSubmit={handleFormSubmit}>

            <LoginForm
              handleLoginInput={handleLoginInput}
              loginRef={loginRef}
              handlePwdInput={handlePwdInput}
              passRef={passRef}
            />

            <Box>
              <Button
                title="Don't you have an account? Create one now!"
                sx={{ width: "120px" }}
                onClick={() => setFormType(1)}
                style={styles.btnTextCss}
              >
                Register User
              </Button>

              <Button
                type="submit"
                sx={{
                  ...styles.myBackground,
                }}
                disabled={isLoading}
              >
                Login
              </Button>
              {/* </div> */}
            </Box>
            <Box>
              <Button
                title="Forgot Password. Check here to"
                sx={{ width: "160px" }}
                onClick={() => setFormType(2)}
                style={styles.btnTextCss}
              >
                {/* Reset Password */}
                Forget Password ?
              </Button>

            </Box>

          </form>
        );
      default:
        return <></>;
    }
  }

  return (
    <Dialog open={openLogin} onClose={handleClose}>
      <DialogTitle
        sx={{
          backgroundColor: "#0f295c",
          color: "white", // Set the color of the title text
          paddingBottom: "10px",
          height: "45px",
          fontSize: "20px",
          fontWeight: "700",
          display: "flex",
          alignItems: "center",
          position: "relative", // Make sure the icon button is positioned correctly
        }}
      >
        {title}
        <IconButton
          sx={{
            position: "absolute",
            top: "50%",
            right: 8,
            transform: "translateY(-50%)",
            color: "white",
          }}
          onClick={handleClose}
        >
          <Close sx={{ fontSize: 25 }} />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText >
            <Box sx={{ fontWeight: 'semibold', color: 'black', fontSize: 14 }}>
              Please fill your Information in the fields below.
            </Box>
        </DialogContentText>
        <div style={styles.errorMsg}>
          <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p>
        </div>
        <Box
          display="grid"
          gap="20px"
        // gridTemplateColumns="repeat(4, minmax(0, 1fr))"
        >
          {/* <p
            ref={errRef}
            className={errMsg ? "errmsg" : "offscreen"}
            aria-live="assertive"
          >
            {errMsg}
          </p> */}

          {simulateSwitch(formType)}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Login;
