import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Visibility } from "@mui/icons-material";

const Input = ({
  field_id,
  display_name,
  field_type,
  handleBlur,
  handleChange,
  touched,
  errors,
  values,
  ...otherProps
}) => {
  const [inputValue, setInputValue] = useState(values[field_id] || "");
  const [openPopup, setOpenPopup] = useState(false);

  // Sync inputValue with external changes to values[field_id]
  useEffect(() => {
    if (values[field_id] !== inputValue) {
      setInputValue(values[field_id] || "");
    }
  }, [values[field_id]]);

  // Handle input change and sync with form state
  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputValue(value);

    // Update form state
    handleChange({
      target: {
        name: field_id,
        value,
      },
    });
  };

  // Handle popup open/close logic
  const handleEyeIconClick = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  // Memoize select_columns to avoid unnecessary recalculations
  const { leftColumns, rightColumns } = useMemo(() => {
    let left = [];
    let right = [];
    const select_columns = values?.select_columns;

    if (select_columns) {
      select_columns.split(",").forEach((item) => {
        if (item.endsWith("_right")) {
          right.push(item.replace("_right", ""));
        } else if (item.endsWith("_left")) {
          left.push(item.replace("_left", ""));
        }
      });
    }

    return { leftColumns: left, rightColumns: right };
  }, [values?.select_columns]);

  // Determine columns to display based on the display name
  const columnsToDisplay =
    display_name === "Right Source Dataframe" ? rightColumns : leftColumns;

  return (
    <>
      <TextField
        id={field_id}
        fullWidth
        variant="outlined"
        type={field_type}
        label={display_name}
        onBlur={handleBlur}
        onChange={handleInputChange} // Use handleInputChange for controlled behavior
        value={inputValue} // Input is controlled by inputValue state
        InputLabelProps={{
          style: { color: "black", fontSize: "15px" },
          shrink: true, // Force label to shrink
        }}
        name={field_id}
        error={!!touched[field_id] && !!errors[field_id]}
        helperText={touched[field_id] && errors[field_id]}
        InputProps={{
          endAdornment:
            (display_name === "Left Source Dataframe" ||
              display_name === "Right Source Dataframe") && (
              <InputAdornment position="end">
                <IconButton onClick={handleEyeIconClick} edge="end">
                  <Visibility />
                </IconButton>
              </InputAdornment>
            ),
          readOnly: ["input_col_name", "expression_value"].includes(field_id),
        }}
        sx={{
          gridColumn: "span 4",
          width:
            ["input_col_name", "output_col_name", "expression_value"].includes(
              field_id
            )
              ? "160px!important"
              : "360px!important",
          marginLeft: "12px",
          minHeight: "10px",
          display: ["input_df", "output_df"].includes(field_id) ? "none" : "",
        }}
        {...otherProps}
      />

      <Dialog open={openPopup} onClose={handleClosePopup}>
        <DialogTitle style={{ color: "black", fontWeight: "bold" }}>
          Select Values for {display_name}
        </DialogTitle>
        <DialogContent>
          <ul>
            {columnsToDisplay.map((column, index) => (
              <li key={index}>{column}</li>
            ))}
          </ul>
        </DialogContent>

        <DialogActions>
          <Button
            sx={{
              backgroundColor: "rgb(0, 72, 190)!important",
              color: "white",
            }}
            onClick={handleClosePopup}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(Input);
