import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useSelector } from "react-redux";
import { logOut, setToken, selectCurrentUser } from "./authSlice";
import { saveTokenInLocalStorage } from "./authService";
import { BiObjectsHorizontalRight } from "react-icons/bi";
export const useCurrentUser = () => {
  return useSelector(selectCurrentUser);
};
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.token;

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus === 403) {
    console.log("sending refresh token");

    const refreshResult = await baseQuery("api/refresh", api, extraOptions);

    if (refreshResult?.data) {
      const user = api.getState().auth.user;

      api.dispatch(setToken(refreshResult.data.token));

      const updatedUserDetails = {
        ...user,
        token: refreshResult.data.token,
        expiresIn: refreshResult.data.expiresIn,
      };

      saveTokenInLocalStorage(updatedUserDetails);

      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
    }
  }
  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  reducerPath: "adminApi",
  tagTypes: [
    "User",
    "Task",
    "Program",
    "Project",
    "Pipeline",
    "Connections",
    "ConnectionForm",
    "Object",
  ],
  endpoints: (build) => ({
    login: build.mutation({
      query: (credentials) => ({
        url: "/auth",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    azureLogin: build.mutation({
      query: (credentials) => ({
        url: "/auth/azuremsal",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    getAdminUser: build.query({
      query: () => `/auth/admin`,
      providesTags: ["User"],
    }),
    getUser: build.query({
      query: (id) => `api/users/${id}`,
      providesTags: ["User"],
    }),
    getPrograms: build.query({
      query: (user) => `api/programs?login_id=${user}`,
      // query: (user) => `api/programs`,

      providesTags: ["Object"],
    }),

    getObjectById: build.query({
      query: ({ object, id }) => `api/${object.toLowerCase()}s/${id}`,
      providesTags: ["Object"],
    }),
    getProgramById: build.query({
      query: (id) => `api/programs/${id}`,
      providesTags: ["Program"],
    }),
    getProjects: build.query({
      query: ({ id, user }) =>
        `api/projects/findAllById/${id}?login_id=${user}`,
      providesTags: ["Object"],
    }),
    getProjectById: build.query({
      query: (id) => `api/projects/${id}`,
      providesTags: ["Project"],
    }),
    getPipelines: build.query({
      query: ({ id, user }) =>
        `api/pipelines/findAllById/${id}?login_id=${user}`,

      providesTags: ["Pipeline"],
    }),

    getPipelinesAudit: build.query({
      query: ({ row, pipelineName, id }) =>
        `api/audit/executePipelineRun/${pipelineName}/${id}`,
      providesTags: ["Pipeline"],
    }),
    getTaskAudit: build.query({
      query: ({ row, taskName, pipeline_id }) =>
        `api/audit/executePipelineRun/${taskName}/${pipeline_id}`,
      providesTags: ["Task"],
    }),

    getPipelineById: build.query({
      query: (id) => `api/pipelines/${id}`,
      providesTags: ["Pipeline"],
    }),
    getPipelineFlowById: build.query({
      query: (id) => `api/pipelines/pipelineFlow/${id}`,
      providesTags: ["Pipeline"],
    }),
    getTransformationFlowById: build.query({
      query: (id) => `api/tasks/transformationFlow/${id}`,
      providesTags: ["Tasks"],
    }),
    checkUserExist: build.query({
      query: (login_id) => `/api/auth/userexists/${login_id}`,
      providesTags: ["Auth"],
    }),

    getUserbyname: build.query({
      query: (user_id) => `/api/users/findbyname/${user_id}`,
      providesTags: ["Users"],
    }),
    getPreviewFile: build.query({
      query: ({ connectionType,filetype, params }) => ({
        url: `/api/preview/${connectionType}/${filetype}`,
        params: { ...params },
      }),
      providesTags: ["Users"],
    }),
    getPreviewDatabase: build.query({
      query: ({ connectionType, params }) => ({
        url: `/api/preview/${connectionType}/`,
        params: { ...params },
      }),
      providesTags: ["Users"],
    }),

    getProgrambyname: build.query({
      query: (program_id) => `/api/programs/findbyname/${program_id}`,
      providesTags: ["Program"],
    }),
    getProjectbyname: build.query({
      query: (project_id) => `/api/projects/findbyname/${project_id}`,
      providesTags: ["Project"],
    }),
    getlnkuserprojects: build.query({
      query: (id) => `/api/lnkuserprojects/${id}`,
      providesTags: ["lnkuserprojects"],
    }),
    getDataQualityColumns: build.query({
      query: ({
        srcconnectionType,
        src_connection_name,
        src_table_name,
        src_schema,
      }) =>
        `/api/preview/${srcconnectionType}/?connection_id=${src_connection_name}&table_name=${src_table_name}&schema_name=${src_schema}`,
      providesTags: ["Task"],
    }),
    getDataQualityColumnsFiles: build.query({
      query: ({
        srcconnectionType,
        src_connection_name,
        src_file_type,
        src_file_name,
        src_file_path,
        src_delimiter,
        src_encoding,
      }) => {
        return `/api/preview/${srcconnectionType}/${src_file_type}?connection_id=${src_connection_name}&table_name=${src_file_name}&filepath=${src_file_path}&header=Y&delimiter=${src_delimiter}&encoding=${src_encoding}`;
      },
      providesTags: ["PreviewData"],
    }),

    getLnkuserprojectsByIdandName: build.query({
      query: () => `/api/lnkuserprojects/getidandname`,
      providesTags: ["Lnkuserprojects"],
    }),
    getLnkuserprojectsRowsandColumns: build.query({
      query: () => `/api/lnkuserprojects/getRowsandColumns`,
      providesTags: ["Lnkuserprojects"],
    }),
    // getPreviewDataSelect: build.query({
    //   query: (srcconnectionType,src_connection_name,src_table_name) => `/api/preview/${srcconnectionType}/?connection_id=${src_connection_name}&table_name=${src_table_name}&schema_name=public`,
    //   providesTags: ["PreviewData"],
    // }),
    getSourceColumns: build.query({
      query: ({ srcconnectionType, src_connection_name, src_table_name ,src_schema_name,database_type,query}) =>
        `/api/preview/${srcconnectionType}/?connection_id=${src_connection_name}&table_name=${src_table_name}&schema_name=${src_schema_name}&database_type=${database_type}&query=${query}`,
      providesTags: ["PreviewData"],
    }),
    getSourceColumnsForFile: build.query({
      query: ({ srcconnectionType, src_connection_name, src_file_type,src_file_name,src_file_path,src_header,src_delimiter,src_encoding }) =>
        `/api/preview/${srcconnectionType}/${src_file_type}?connection_id=${src_connection_name}&table_name=${src_file_name}&filepath=${src_file_path}&file_type=${src_file_type}&header=${src_header}&delimiter=${src_delimiter}&encoding=${src_encoding}`,
      providesTags: ["PreviewData"],
    }),

    getTasks: build.query({
      query: ({ id, user }) =>
        `api/tasks/getTasksForPipelineID/${id}?login_id=${user}`,
      providesTags: ["Tasks"],
    }),
    getTasksDQ: build.query({
      query: (task_id) => `/api/task_parameters/DQ/${task_id}`,
      providesTags: ["Tasks"],
    }),

    getTasksSqlExecution: build.query({
      query: (task_id) => `/api/task_parameters/SqlExecution/${task_id}`,
      providesTags: ["Tasks"],
    }),
    getUsers: build.query({
      query: () => "api/users",
      providesTags: ["Object"],
    }),
    getUserById: build.query({
      query: (id) => `api/users/${id}`,
      providesTags: ["Users"],
    }),
    getConnections: build.query({
      query: (user) => `api/connections/connectionlist?loginId=${user}`,
      providesTags: ["Connections"],
    }),
    getConnectionById: build.query({
      query: (id) => `/api/connections/connectionlist/${id}`,
      providesTags: ["Connections"],
    }),
    getConnectionDetailsDelete: build.query({
      query: () => `api/connection_details/delete1`,
      providesTags: ["ConnectionDetailsDelete"],
    }),
    getConnectionType: build.query({
      query: () => `api/configurations/connectionType`,
      providesTags: ["ConnectionsType"],
    }),
    getConnectionSubType: build.query({
      query: (connection_type) =>
        `api/configurations/connectionSubType/${connection_type}`,
      providesTags: ["ConnectionsSubType"],
    }),
    getConfiguration: build.query({
      query: () => `api/configurations`,
      providesTags: ["Configuration"],
    }),
    getConfigurationForm: build.query({
      query: () => `api/configurations/getConfigurations`,
      providesTags: ["ConfigurationForm"],
    }),
    getConfigurationDetails: build.query({
      query: (id) => `api/configuration_details/findAllById/${id}`,
      providesTags: ["ConfigurationDetails"],
    }),
    getConfigurationDetailsForm: build.query({
      query: (name) => `api/configuration_details/getConfigurationDetails`,
      providesTags: ["ConfigurationDetailsForm"],
    }),
    getConfigurationOptions: build.query({
      query: (id) => `api/configuration_options/findAllById/${id}`,
      providesTags: ["ConfigurationOptions"],
    }),
    getConfigurationOptionForm: build.query({
      query: (name) => `api/configuration_options/getConfigurationOptions`,
      providesTags: ["ConfigurationOptionForm"],
    }),

    getProjectCount: build.query({
      query: (id) => `api/configurations/getProjectCount`,
      providesTags: ["ProjectCount"],
    }),

    getDashboardCount: build.query({
      query: (user) => `api/configurations/getdashboardcount?login_id=${user}`,
      providesTags: ["Dashboard"],
    }),

    getFrequentlyUsedObjects: build.query({
      query: (user) =>
        `api/configurations/getfrequentlyusedobjects?login_id=${user}`,
      providesTags: ["Dashboard"],
    }),

    getGitBranches: build.query({
      query: () => `/api/tasks/getgitbranch`,
      providesTags: ["Tasks"],
    }),
    getCommits: build.query({
      query: ({ filePath }) => {
        const queryParams = new URLSearchParams({ filePath });
        return `api/git/listCommits/?${queryParams}`;
      },
    }),
    fetchPublishedJson: build.query({
      query: ({ commitSha, filePath }) => ({
        url: `/api/git/fetchPublishedJson`,
        params: { commitSha, filePath },
      }),
    }),
    restructureJson: build.mutation({
      query: (json) => ({
        url: "/api/git/restructure",
        method: "POST",
        body: json,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Tasks"],
    }),

    getTaskLogCapture: build.query({
      query: (row) => ({
        url: `/api/tasks/taskLogCapture?project=${row?.project_name}&taskName=${row?.task_name}&run_id=${row?.last_run_id}&pipelineName=${row?.pipeline_name}`,
      }),
      providesTags: ["Tasks"],
    }),

    getGitBranchesWithFile: build.query({
      query: ({ variables }) => {
        return `/api/pipelines/getGitBranch/${variables.type}/${variables.name}/${variables.project}?`;
      },
      providesTags: ["Tasks"],
    }),

    getGitBranchesWithFileMulti: build.query({
      query: ({ variables }) => {
        const projects = variables.projectsAndTasks
          .map((pt) => pt.project)
          .join(",");
        const names = variables.projectsAndTasks.map((pt) => pt.name).join(",");
        return `/api/tasks/getGitBranchMultiple?type=task&projects=${projects}&names=${names}`;
      },
      providesTags: ["Tasks"],
    }),
    getGitBranchesForTask: build.query({
      query: ({ variables }) => {
        return `/api/tasks/getGitBranch/${variables.projectName}/${variables.taskName}`;
      },
      providesTags: ["Tasks"],
    }),

    addConnection: build.mutation({
      query: ({ data }) => ({
        // var connection_subtype = data.connectionSubType
        url: `/api/connections/`,
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Connections"],
    }),
    gitConnectionData: build.mutation({
      query: ({ data, branch }) => ({
        // var connection_subtype = data.connectionSubType
        url: `/api/connections/gitConnectionData/${branch}`,
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Connections"],
    }),
    testRestApi: build.query({
      query: ({ subType, authentication_type, values }) => ({
        url: `/api/testconnection/${subType}/${authentication_type}`,
        params: { ...values },
      }),
      providesTags: ["testRestApi"],
    }),

    testConnections: build.query({
      query: ({ subType, values }) => ({
        url: `/api/testconnection/${subType}`,
        params: { ...values },
      }),
      providesTags: ["testConnections"],
    }),

    insertPreview: build.mutation({
      query: (records) => ({
        url: "api/columnreference",
        method: "POST",
        body: records,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["PreviewInsert"],
    }),

    deleteColumnReference: build.mutation({
      query: ({ connectionId, schemaName, tableName }) => ({
        url: `/api/columnreference/delete/${connectionId}&${schemaName}&${tableName}`,
        method: "DELETE",
        body: { connectionId, schemaName, tableName },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["PreviewInsert"],
    }),
    taskGitData: build.mutation({
      query: ({ id, branch }) => ({
        url: `/api/tasks/taskGitData/${id}/${branch}`,
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Tasks"],
    }),
    taskGitDataTransformation: build.mutation({
      query: ({ id, branch, login_id }) => ({
        url: `/api/tasks/taskGitDataTransformation/${login_id}/${id}/${branch}`,
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Tasks"],
    }),
    taskBulkData: build.mutation({
      query: ({ id, branch }) => ({
        url: `/api/tasks/taskBulkData/${id}/${branch}`,
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Tasks"],
    }),

    updatelnkuserprojects: build.mutation({
      query: ({
        id,
        user_id,
        program_id,
        project_id,
        read_role,
        write_role,
        execute_role,
        is_active,
        created_by,
      }) => ({
        url: `/api/lnkuserprojects/${id}`,
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          id,
          user_id,
          program_id,
          project_id,
          read_role,
          write_role,
          execute_role,
          is_active,
          created_by,
        }),
      }),
      invalidatesTags: ["lnkuserprojects"],
    }),
    insertlnkuserprojects: build.mutation({
      query: ({
        user_id,
        program_id,
        project_id,
        read_role,
        write_role,
        execute_role,
        is_active,
        created_by,
      }) => ({
        url: `/api/lnkuserprojects/`,
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify({
          user_id,
          program_id,
          project_id,
          read_role,
          write_role,
          execute_role,
          is_active,
          created_by,
        }),
      }),
      invalidatesTags: ["lnkuserprojects"],
    }),
    pipelineGitData: build.mutation({
      query: ({ id, branch }) => ({
        url: `/api/pipelines/pipelineGitData/${id}/${branch}`,
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Data"],
    }),

    // addGitData: build.mutation({
    //       query: ({ object, data,id,branch }) => ({
    //         url: `/api/tasks/getGitData/${id}/${branch}`,
    //         method: "POST",
    //         headers: {
    //           "Content-type": "application/json; charset=UTF-8",
    //         },
    //       }),
    //       invalidatesTags: ["Data"],
    //     }),

    updateConnection: build.mutation({
      query: ({ data, connection_id }) => ({
        url: `/api/connections/${connection_id}`,
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Connections"],
    }),
    addTask: build.mutation({
      query: ({ data }) => ({
        // var connection_subtype = data.connectionSubType
        url: `/api/tasks`,
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Tasks"],
    }),
    getTasksLogCapture: build.query({
      query: ({ id, user }) =>
        `api/tasks/getTasksForPipelineID/${id}?login_id=${user}`,
      providesTags: ["Tasks"],
    }),
    getPipelinesLogCapture: build.query({
      query: ({ id, user }) =>
        `api/pipelines/findAllById/${id}?login_id=${user}`,
      providesTags: ["Pipelines"],
    }),

    addPythonRun: build.mutation({
      query: ({ data }) => ({
        // var connection_subtype = data.connectionSubType
        url: `/api/tasks/run-python-script`,
        method: "POST",
        body: JSON.stringify({
          data,
        }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Tasks"],
    }),

    runPythonScript: build.mutation({
      query: ({ project_name, task_name, selectedBranch }) => ({
        url: "/api/tasks/run-python-script",
        method: "POST",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
        body: { project_name, task_name, selectedBranch },
      }),
      invalidatesTags: ["Tasks"],
    }),

    addTaskParameter: build.mutation({
      query: ({ data }) => ({
        // var connection_subtype = data.connectionSubType
        url: `/api/task_parameters`,
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Task"],
    }),
    addTaskParameterSQL: build.mutation({
      query: ({ data }) => ({
        // var connection_subtype = data.connectionSubType
        url: `/api/task_parameters/createSQL`,
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Task"],
    }),

    updateTask: build.mutation({
      query: ({ data, task_id }) => ({
        url: `/api/tasks/${task_id}`,
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Tasks"],
    }),
    updateTaskSequence: build.mutation({
      query: ({ data }) => ({
        url: `/api/tasks/updateTaskSequence`,
        method: "PATCH",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Tasks"],
    }),
    updateTaskDetails: build.mutation({
      query: ({ data, id }) => ({
        url: `/api/tasks/updateTask/${id}`,
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Tasks"],
    }),

    updateTaskParameter: build.mutation({
      query: ({ data, task_id }) => ({
        url: `/api/task_parameters/${task_id}`,
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Task"],
    }),
    updateTaskParameterSQL: build.mutation({
      query: ({ data, task_id }) => ({
        url: `/api/task_parameters/updateSQL/${task_id}`,
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Task"],
    }),

    updateTaskParameterBulk: build.mutation({
      query: ({ data, task_id }) => ({
        url: `/api/task_parameters/updateBulk/${task_id}`,
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Task"],
    }),

    copyDataToTask: build.mutation({
      query: ({ object, data, id }) => ({
        url: `/api/tasks/copy/${id}`,
        method: "GET",
        body: data,

        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Tasks"],
    }),
    addObject: build.mutation({
      query: ({ object, data, user }) => ({
        url: `/api/${object.toLowerCase()}s?login_id=${user}`,
        method: "POST",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Object"],
    }),
    updateObject: build.mutation({
      query: ({ object, data, user }) => ({
        url: `/api/${object.toLowerCase()}s/${data.id}?login_id=${user}`,
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["Object"],
    }),
    activateUser: build.mutation({
      query: (id) => ({
        url: `/api/users/activate/${id}`,
        method: "PUT",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["User"],
    }),

    changePassword: build.mutation({
      query: (payload) => ({
        url: `auth/changepassword`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),

    forgetPassword: build.mutation({
      query: (payload) => ({
        url: `/auth/forgetpassword`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    resetPassword: build.mutation({
      query: (payload) => ({
        url: `auth/resetpassword`,
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
    }),
    registerUser: build.mutation({
      query: (data) => ({
        url: `/register`,
        method: "POST",
        body: { ...data },
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: ["User"],
    }),
    getConnectionForm: build.query({
      query: (user) => `api/configurations/connectionForm?login_id=${user}`,
      providesTags: ["ConnectionForm"],
    }),

    getTransformationForm: build.query({
      query: (user) => `api/configurations/transformationForm?login_id=${user}`,
      providesTags: ["Tasks"],
    }),
    getExpressionDescriptions: build.query({
      query: (user) => `/api/expression_operator/expressionOptions`,
      providesTags: ["Tasks"],
    }),

    getConnectionDelete: build.query({
      query: () => `api/connection_details/delete1/90`,
      providesTags: ["ConnectionForm"],
    }),
    getTaskForm: build.query({
      query: (user) => `api/configurations/taskForm?login_id=${user}`,
      providesTags: ["TaskForm"],
    }),

    getSqlOrchestrationForm: build.query({
      query: (user) => `api/configurations/sqlorchForm?login_id=${user}`,
      providesTags: ["TaskForm"],
    }),
    getBulkIngestionForm: build.query({
      query: (user) => `api/configurations/bulkIngestionForm?login_id=${user}`,
      providesTags: ["TaskForm"],
    }),
    getDataQualityForm: build.query({
      query: (user) => `api/configurations/dataQualityForm?login_id=${user}`,
      providesTags: ["TaskForm"],
    }),
    getConnectionOptions: build.query({
      query: (user) => `api/bulkIngestion/connectionsOptions?login_id=${user}`,
      providesTags: ["TaskForm"],
    }),

    getBulkIngPreviewFile: build.query({
      query: ({ connectionType, filetype, params }) => ({
        url: `/api/bulkIngestion/${connectionType}/`,
        params: { ...params },
      }),
      providesTags: ["Users"],
    }),

    getCheckUsers: build.query({
      query: (login_id) => `api/users/userexists/${login_id}`,
      providesTags: ["NewObject"],
    }),
    getObjectForm: build.query({
      query: ({ name, user }) =>
        `api/configurations/objectform/${name}?login_id=${user}`,
      providesTags: ["Object"],
    }),
    getPreviewData: build.query({
      query: (connectionType) => ({
        url: `api/preview/${connectionType}/`,
        method: "GET",
        body: { connectionType },
      }),
      providesTags: ["Preview"],
    }),
    getSchedulerForm: build.query({
      query: (user) => `api/configurations/getSchedulerForm?login_id=${user}`,
      providesTags: ["TaskForm"],
    }),
    validateScheduler: build.query({
      query: (schedule) =>
        `api/scheduler/validate/${encodeURIComponent(schedule)}`,
      providedTags: ["Scheduler"],
    }),
    scheduleDescription : build.query({
      query : (schedule) =>
        `api/scheduler/description/${encodeURIComponent(schedule)}`,
      providesTags : ["Scheduler"],
    }),
    addScheduler: build.mutation({
      query: ({ data }) => ({
        url: `/api/scheduler/schedule`,
        method: "PUT",
        body: data,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }),
      invalidatesTags: (_, __, { object }) => {
        return object === "Task" ? ["Tasks"] : ["Pipeline"];
      },
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetAdminUserQuery,
  useGetTasksQuery,
  useGetTasksDQQuery,
  useLazyGetTasksQuery,
  useGetTasksDataQualityQuery,
  useGetgitConnectionDataQuery,
  useGetTasksSqlExecutionQuery,
  useGetProgramsQuery,
  useGetProjectsQuery,
  useGetPipelinesQuery,
  useGetPipelinesAuditQuery,
  useGetTaskAuditQuery,
  useLazyCheckUserExistQuery,
  useLazyGetPipelineFlowByIdQuery,
  useLazyGetTransformationFlowByIdQuery,
  useLazyGetUserbynameQuery,
  useLazyGetPreviewFileQuery,
  useLazyGetPreviewDatabaseQuery,
  useLazyGetProgrambynameQuery,
  useLazyGetProjectbynameQuery,
  useLazyGetlnkuserprojectsQuery,
  useLazyGetDataQualityColumnsQuery,
  useLazyGetDataQualityColumnsFilesQuery,
  useLazyGetSourceColumnsQuery,
  useLazyGetSourceColumnsForFileQuery,
  useLazyGetLnkuserprojectsByIdandNameQuery,
  useLazyGetLnkuserprojectsRowsandColumnsQuery,
  useGetConnectionsQuery,
  useGetConnectionFormQuery,
  useGetTransformationFormQuery,
  useGetTasksLogCaptureQuery,
  useGetPipelinesLogCaptureQuery,
  useGetConnectionDeleteQuery,
  useGetConnectionDetailsDeleteQuery,
  useGetProjectCountQuery,
  useGetDashboardCountQuery,
  useGetFrequentlyUsedObjectsQuery,
  useGetGitBranchesQuery,
  useGetGitBranchesWithFileQuery,
  useGetGitBranchesWithFileMultiQuery,
  useGetGitBranchesForTaskQuery,
  useLazyTestRestApiQuery,
  useLazyTestConnectionsQuery,
  useGetTaskLogCaptureQuery,
  useTaskGitDataMutation,
  useTaskGitDataTransformationMutation,
  useTaskBulkDataMutation,
  usePipelineGitDataMutation,
  useInsertPreviewMutation,
  useDeleteColumnReferenceMutation,
  // useAddGitDataMutation,
  useRunPythonScriptMutation,
  useGetConnectionTypeQuery,
  useGetConnectionSubTypeQuery,
  useGetConnectionByIdQuery,
  useGetObjectFormQuery,
  useGetUsersQuery,
  useGetConfigurationQuery,
  useGetConfigurationFormQuery,
  useGetConfigurationDetailsQuery,
  useGetExpressionDescriptionsQuery,
  useCopyDataToTaskMutation,

  useGetConfigurationDetailsFormQuery,
  useGetConfigurationOptionsQuery,
  useGetConfigurationOptionFormQuery,
  useAddObjectMutation,
  useAddConnectionMutation,
  useGitConnectionDataMutation,
  useUpdateConnectionMutation,
  useAddTaskMutation,
  useAddPythonRunMutation,
  useAddTaskParameterMutation,
  useAddTaskParameterSQLMutation,
  useUpdateTaskMutation,
  useUpdateTaskSequenceMutation,
  useUpdateTaskDetailsMutation,
  useUpdateTaskParameterMutation,
  useUpdateTaskParameterSQLMutation,
  useUpdateTaskParameterBulkMutation,
  useUpdateObjectMutation,
  useGetObjectByIdQuery,
  useGetTaskFormQuery,
  useGetSqlOrchestrationFormQuery,
  useGetBulkIngestionFormQuery,
  useGetDataQualityFormQuery,
  useGetConnectionOptionsQuery,
  useLazyGetBulkIngPreviewFileQuery,
  useLazyGetTaskFormQuery,
  useLoginMutation,
  useAzureLoginMutation,
  useActivateUserMutation,
  useRegisterUserMutation,
  useChangePasswordMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  useGetCheckUsersQuery,
  useLazyGetPreviewDataQuery,
  useUpdatelnkuserprojectsMutation,
  useInsertlnkuserprojectsMutation,
  useGetSchedulerFormQuery,
  useValidateSchedulerQuery,
  useScheduleDescriptionQuery,
  useAddSchedulerMutation,
  useFetchPublishedJsonQuery,
  useRestructureJsonMutation,
  useGetCommitsQuery,
} = apiSlice;
