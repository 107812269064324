import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
 
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { ModeEditOutline } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useStyles from "useStyles";
import Element from '../components/Element';
import Grid from '@mui/material/Grid';
 
const DQForm = (props) => {
  const styles = useStyles();
  const { taskProps, onSelectionChange ,dqValues} = props;
  const fieldsListData = taskProps.fieldList;
  const [selectedValue, setSelectedValue] = useState("");
 
  const [formData, setFormData] = useState({});
  const fieldsList = [...fieldsListData].sort(
    (a, b) => a.display_sequence - b.display_sequence
  );
 
  useEffect(() => {
    if (formData) {
      setSelectedValue(formData.dq_check);
    }
  }, [formData]);
  const [rows, setRows] = useState([]);
 
  const [selectedRowIdx, setSelectedRowIdx] = useState(null); // Change to dq_idx
 
  const formProps = {
    values: formData,
    handleChange: (e) =>
      setFormData({ ...formData, [e.target.name]: e.target.value }),
    handleBlur: () => {},
    errors: {},
  };
 useEffect(() => {
  const dataQuality = props?.dqValues?.length
    ? props.dqValues
    : props?.formProps?.initialValues?.DataQuality;
 
  if (dataQuality) {
    // Append existing DataQuality rows to the rows state
    const existingRows = dataQuality.map((item) => ({
      ...item,
      id: item.dq_idx.toString().trim(), // Use dq_idx as id and trim it
    }));
    setRows(existingRows);
  }
}, [props?.dqValues, props?.formProps?.initialValues?.DataQuality]);
 
  const [columnOptions, setColumnOptions] = useState([]);
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const columnString = props?.formProps?.values?.src_select_columns
          ? props?.formProps.values.src_select_columns
          : props?.formProps.values.src_alias_columns;
 
        console.log("columnString:", columnString); // Debug log
 
        const actualColumnsArray = columnString ? columnString.split(",") : [];
 
        console.log("actualColumnsArray:", actualColumnsArray); // Debug log
 
        setColumnOptions(actualColumnsArray);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };
 
    fetchData();
  }, [
    props?.formProps?.values?.src_select_columns,
    props?.formProps?.values?.src_alias_columns,
  ]);
 
  useEffect(() => {
    console.log("columnOptions updated:", columnOptions); // Debug log
  }, [columnOptions]);
 
  const handleRowClick = (params) => {
    setSelectedRowIdx(params.row.dq_idx); // Change to dq_idx
    setFormData(params.row);
    setSelectedValue(params.row.someField);
  };
 
  const handleDelete = () => {
    if (selectedRowIdx !== null) {
      setRows(rows.filter((row) => row.dq_idx !== selectedRowIdx)); // Change to dq_idx
      setFormData({});
      setSelectedRowIdx(null);
    }
  };
 
 
  const [viewRowIndex, setViewRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
 
  const handleViewClick = (rowData) => {
    setViewRowIndex(rowData.dq_idx); // Set to view mode
    setEditRowIndex(null); // Ensure edit mode is off
    setFormData(rowData);
    setSelectedRowIdx(rowData.dq_idx);
  };
 
  const handleEditClick = (rowData) => {
    setEditRowIndex(rowData.dq_idx); // Set to edit mode
    setViewRowIndex(null); // Ensure view mode is off
    setFormData(rowData);
    setSelectedRowIdx(rowData.dq_idx);
  };
  const handleCancel = () => {
    setFormData({});
    setSelectedRowIdx(null);
    setViewRowIndex(null);
    setEditRowIndex(null);
    setSelectedValue("");
  };
 
  const handleFormSubmit = () => {
    if (editRowIndex !== null) {
      setRows(
        rows.map((row) =>
          row.dq_idx === editRowIndex
            ? { ...formData, dq_idx: editRowIndex }
            : row
        )
      );
      handleCancel();
    } else {
      // Add new row
      const newRow = { ...formData, dq_idx: rows.length + 1 };
      setRows([...rows, newRow]);
      handleCancel(); // Reset state to default
    }
  };
 
  const buttonText = () => {
    if (editRowIndex !== null) return "Update";
    if (viewRowIndex !== null) return "Cancel";
    return "Add";
  };
 
  const columns = [
    { field: "dq_idx", headerName: "DQ Index", flex: 0.3,
      align:"center",},
    { field: "dq_check", headerName: "DQ Check", flex: 1 },
    { field: "dq_column", headerName: "Column Name", flex: 0.6,},
 
    {
      field: "dq_threshold_bad_records",
      headerName: "Threshold Bad Records",
      flex: 0.6,
      align:"center",
    },
    {
      field: "delete",
      headerName: "Delete",
      flex: 0.2,
      align:"center",
      renderCell: (params) => (
        <Box
          variant="outlined"
          onClick={() => handleDelete(params.row)}
          sx={{
            color: "red",
            cursor: "pointer",
          }}
        >
          <DeleteOutlineIcon />
        </Box>
      ),
    },
    {
      field: "edit",
      headerName: "Edit",
      flex: 0.2,
      align:"center",
      renderCell: (params) => (
        <Box
          variant="outlined"
          onClick={() => handleEditClick(params.row)}
          sx={{
            cursor: "pointer",  // Add cursor pointer
            fontSize: "13px",  // Adjust font size for the icon
          }}
        >
          <ModeEditOutline />
        </Box>
      ),
    },
    {
      field: "view",
      headerName: "View",
      flex: 0.2,
      align:"center",
      renderCell: (params) => (
        <Box
          variant="outlined"
          onClick={() => handleViewClick(params.row)}
          sx={{
            cursor: "pointer",  // Add cursor pointer
            fontSize: "13px",  // Adjust font size for the icon
          }}
        >
          <VisibilityIcon />
        </Box>
      ),
    },
  ];
 
  const handleSelectionChange = (selectionModel) => {
    const selectedRowData = rows.filter((row) =>
      selectionModel.includes(row.dq_idx)
    ); // Change to dq_idx
    onSelectionChange(selectedRowData);
  };
 
  const splitIndex = 3;
  const firstHalfFields = fieldsList.slice(0, splitIndex);
  const secondHalfFields = fieldsList.slice(
    splitIndex,
    splitIndex + splitIndex
  );
 
 
  const renderField = (field) => {
    //to render second_half based on dq_check selection
    if (field.field_type === "component") {
      if (!Array.isArray(field.params_field_list)) {
        console.error("params_field_list is not an array or is missing.");
        return <Box>No parameters to display</Box>;
      }
 
      const filteredParams = field.params_field_list
        .flat()
        .filter((paramSet) => paramSet.check_name === selectedValue);
 
      if (filteredParams.length === 0) {
        return <Box>No parameters to display</Box>;
      }
 
      const sortedParams = filteredParams
        .map((paramSet) => ({
          ...paramSet,
          param_field_list: [...paramSet.param_field_list].sort(
            (a, b) => a.display_sequence - b.display_sequence
          ),
        }))
        .sort(
          (a, b) =>
            a.param_field_list[0].display_sequence -
            b.param_field_list[0].display_sequence
        );
 
      return (
        <Box style={{ width: "310%" }}>
          {sortedParams.map((paramSet, index) => (
            <Grid
              container
              spacing={2}
              key={index}
              style={{ marginBottom: "16px" }}
            >
              {paramSet.param_field_list.map((param) => {
                // Format the options correctly
                const formattedOptions = columnOptions.map(option => ({
                  id: option,
                  name: option
                }));
 
                const paramWithOptions = {
                  ...param,
                  option_list: formattedOptions,
                };
 
 
                return (
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    key={paramWithOptions.field_id}
                    style={{ marginBottom: "8px" }}
                  >
                    <Element
                      field={paramWithOptions}
                      values={formData}
                      touched={{}}
                      errors={{}}
                      handleBlur={() => {}}
                      handleChange={(e) => {
                        const value = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
                        setFormData({ ...formData, [e.target.name]: value });
                      }}
                      selectedCheck={selectedValue}
                      setFormValues={setFormData}
                      options={paramWithOptions.option_list}
                    />
                  </Grid>
                );
              })}
            </Grid>
          ))}
        </Box>
      );
    }
 
    return (
      <Element
        key={field.field_id}
        field={field}
        values={formData}
        touched={{}}
        errors={{}}
        handleBlur={() => {}}
        handleChange={(e) => {
          const value = e.target.type === 'checkbox' ? (e.target.checked ? 1 : 0) : e.target.value;
          setFormData({ ...formData, [e.target.name]: value });
          if (field.field_id === 'dq_check') {
            setSelectedValue(value);
          }
        }}
        selectedCheck={selectedValue}
        setFormValues={setFormData}
      />
    );
  };
 
  return (
    <Box display="flex" flexDirection="column" gap="16px">
      {/* Parent Container - Set to flex for horizontal layout */}
      <Box display="flex" flexDirection="row" gap="16px">
        {firstHalfFields.map(renderField)}
      </Box>
 
      {/* Second Child Container - Set to flex for horizontal layout */}
      <Box display="flex" flexDirection="row" gap="16px">
        {secondHalfFields.map(renderField)}
      </Box>
 
      {/* Action Button */}
      <Box display="flex" justifyContent="space-between" mt={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={viewRowIndex !== null ? handleCancel : handleFormSubmit}
          sx={styles.myBackground}
        >
          {buttonText()}
        </Button>
      </Box>
 
      <Box
        m="10px 0 0 0"
        sx={{
          height: 500,
          "& .MuiDataGrid-row.Mui-odd": {
            backgroundColor: "var(--tertiary-color) !important",
          },
          "& .MuiDataGrid-row.Mui-disabled-row": {
            backgroundColor: "lightgrey",
          },
          "& .MuiDataGrid-root": {
            border: "none",
            fontWeight: "600",
            fontSize: "12px !important",
            fontFamily: "Poppins",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "var(--primary-color) !important",
            border: "none",
            color: "#ffffff!important",
            fontSize: "15px!important",
            fontWeight: "bold!important",
            lineHeight: "38px!important",
            minHeight: "38px!important",
            maxHeight: "38px!important",
            fontFamily: "Poppins",
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "#f5f5f5",
            minHeight: "352px!important",
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: "var(--pagination-bottom-color) !important",
            lineHeight: "38px!important",
            minHeight: "38px!important",
            maxHeight: "38px!important",
            borderRadius: "0 0 6px 6px",
            marginTop: "5px!important",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "black!important",
            fontSize: "12px",
            width: "100px",
            marginBottom: "1px",
            fontFamily: "Poppins",
          },
          "& .MuiCheckbox-root.Mui-checked": {
            color: "#4b437cd1!important",
          },
          "& .MuiDataGrid-columnHeader .MuiSvgIcon-root": {
            color: 'white',
          },
        }}
      >
        <DataGrid
          rowHeight={40}
          components={{ Toolbar: GridToolbar }}
          rows={rows}
          columns={columns}
          checkboxSelection
          disableSelectionOnClick
          getRowId={(row) => row.dq_idx}
          onRowClick={handleRowClick}
          onSelectionModelChange={(newSelection) =>
            handleSelectionChange(newSelection)
          }
        />
      </Box>
    </Box>
  );
};
 
export default DQForm;
