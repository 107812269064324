import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    mode: "light",
    openLogin: false,
    authType: "userInput",
    user: null,
    token: null,
  },
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setAuthType: (state, action) => {
      const { authType } = action.payload;
      state.authType = authType;
    },  

    setOpenLogin: (state, action) => {
      const { openLogin } = action.payload;
      state.openLogin = openLogin;
    },
    setCredentials: (state, action) => {
      const { user, token } = action.payload;
      state.user = user;
      state.token = token;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    logOut: (state, action) => {
      state.user = null;
      state.token = null;
      state.authType = "userInput";
    },
  },
});

export const { setMode, setOpenLogin, setCredentials, setToken, logOut, setAuthType } =
  authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
