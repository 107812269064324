import { Box, CircularProgress } from "@mui/material";
import React from "react";

const Loading = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="75vh"
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="75vh"
      >
        <CircularProgress
          variant="determinate"
          value={100} // Value for the static gray background ring
          sx={{
            color: "gray", // Static ring color
            position: "absolute",
          }}
          size={60}
          thickness={3}
        />
        <CircularProgress
          variant="indeterminate" // Rotating progress
          disableShrink // Keeps the size constant during rotation
          sx={{
            color: "var(--primary-color)", // Rotating color
          }}
          size={60}
          thickness={3}
        />
      </Box>
    </Box>
  );
};

export default Loading;
