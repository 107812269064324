import React from "react";
import { at } from "lodash";
import { useField } from "formik";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from "@mui/material";

const HeaderCheckBox = (props) => {
  const { field_id, display_name, values, handleChange, ...rest } = props;

  const _onChange = (evt) => {
    const newValue = evt.target.checked ? "Y" : "N";
    handleChange({ target: { id: field_id, value: newValue } });
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          id={field_id}
          checked={values != null ? values[field_id] === "Y" : false}
          onChange={_onChange}
          color="primary"
          value={values[field_id] || ""}
          sx={{
            "&.Mui-checked": {
              color: "var(--primary-color) !important",
            },
            marginLeft: "12px",
            
          }}
          {...rest}
        />
      }
      label={display_name}
      sx={{ gridColumn: "span 4" }}
    />
  );
};

const RegularCheckBox = (props) => {
  const { field_id, display_name, values, handleChange, errors, touched, ...rest } = props;

  const _onChange = (evt) => {
    const newValue = evt.target.checked ? "Y" : "N";
    handleChange({ target: { name: field_id, value: newValue } });
  };

  function _renderHelperText() {
    if (touched && errors && touched[field_id] && errors[field_id]) {
      return <FormHelperText>{errors[field_id]}</FormHelperText>;
    }
  }

  return (
    <FormControl {...rest}>
      <FormControlLabel
        value={values && values[field_id] === "Y"}
        checked={values && values[field_id] === "Y"}
        control={
          <Checkbox
            name={field_id}
            onChange={_onChange}
            sx={{
              "&.Mui-checked": {
                color: "var(--primary-color) !important",
              },
              marginLeft: "12px",
            }}
          />
        }
        label={display_name}
      />
      {_renderHelperText()}
    </FormControl>
  );
};


const CheckBox = (props) => {
  const { display_name } = props;

  if (display_name === "Header") {
    return <HeaderCheckBox {...props} />;
  } else {
    return <RegularCheckBox {...props} />;
  }
};

export default CheckBox;
