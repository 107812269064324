import React, { useRef, useEffect, useState } from "react";
import { TextField } from "@mui/material";

const PythonScriptTextArea = ({
  field_id,
  display_name,
  values,
  handleChange,
  touched,
  errors,
}) => {
  const inputRef = useRef(null);
  const [localValue, setLocalValue] = useState(values[field_id] || "");

  // Sync the local state with the form value only when necessary
  useEffect(() => {
    if (inputRef.current && inputRef.current.value !== values[field_id]) {
      setLocalValue(values[field_id] || "");
    }
  }, [values[field_id]]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setLocalValue(value); // Update local state first to manage the cursor position
    handleChange({
      target: {
        name: field_id,
        value,
      },
    });
  };

  return (
    <TextField
      fullWidth
      multiline
      rows={5}
      variant="outlined"
      label={display_name}
      sx={{
        gridColumn: "span 4",
        width: "360px!important",
        marginLeft: "12px",
        minHeight: "10px",
        fontFamily: 'Poppins, sans-serif',
        '&.MuiInputLabel-shrink': {
          fontSize: '18px', // Larger font when shrunk
          backgroundColor: 'white',
          padding: '0 4px',
          marginLeft: '-4px',
        },
      }}
      InputLabelProps={{
        style: { color: "black", fontSize: "18px" },
        shrink: true, // Always shrink label when filled
      }}
      onChange={handleInputChange}
      value={localValue} // Controlled input using local state to avoid re-renders
      name={field_id}
      error={!!touched[field_id] && !!errors[field_id]}
      helperText={touched[field_id] && errors[field_id]}
      inputRef={inputRef}
    />
  );
};

// Memoizing to avoid unnecessary re-renders when unrelated props change
const MemoizedPythonScriptTextArea = React.memo(PythonScriptTextArea);

export default MemoizedPythonScriptTextArea;
