import React, { useState, useEffect } from "react";
import Popup from "../components/Popup";
import {
  Box,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { selectCurrentUser } from "state/authSlice";
import { useSelector } from "react-redux";
import ToggleButtonElement from "components/elements/ToggleButtonElement";
import { Formik } from "formik";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import GitHubIcon from '@mui/icons-material/GitHub';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import * as yup from "yup";
import Element from "../components/Element";
import crypto from "crypto";
import { Buffer } from "buffer";
import {
  useLazyTestRestApiQuery,
  useLazyTestConnectionsQuery,
  useGitConnectionDataMutation,
  useGetGitBranchesWithFileQuery,
} from "../state/apiSlice";
import { selectCurrentToken } from "state/authSlice";
import axios from '../api/axios';
import {toast } from 'react-hot-toast';
import useStyles from "useStyles";
import ToggleButtonElement2 from "components/elements/ToggleButtonElement2";
window.Buffer = Buffer;

function encrypt(message) {
  const KEY = "8ookgvdIiH2YOgBnAju6Nmxtp14fn8d3";
  const IV = "rBEssDfxofOveRxR";
  const cipher = crypto.createCipheriv("aes-256-gcm", KEY, IV);
  let encrypted = cipher.update(message, "utf8", "hex");
  encrypted += cipher.final("hex");
  const tag = cipher.getAuthTag();
  return encrypted + tag.toString("hex");
}

const ConnectionForm = (props) => {
  const styles = useStyles();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const accessToken = useSelector(selectCurrentToken);
  const [openPopup, setOpenPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { formData, value, addOrEditConn, setOpenPopupForClosing } = props;
  const created_by = value?.created_by;

  const [TestRestApi] = useLazyTestRestApiQuery();
  const [TestConnections] = useLazyTestConnectionsQuery();
  const [resetKey, setResetKey] = useState(0);

  const [currConnType, setCurrConnType] = useState(null);
  const [currSubConnType, setCurrSubConnType] = useState(null);
  const [connType, setConnType] = useState(null);
  const [connSubType, setConnSubType] = useState(null);
  const [initialValues, setInitialValues] = useState(value);
  const [connFieldForm, setConnFieldForm] = useState([value]);
  const [userSchema, setUserSchema] = useState(null);
  const [errors, setErrors] = useState(null);
  const [isConnSuccess, setIsConnSuccess] = useState(null);
  const [isSaveSuccess, setIsSaveSuccess] = useState(null);
  const currentUser = useSelector(selectCurrentUser);


  const [rowpublish, setRowPublish] = useState(null);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showPublishCancelDialog, setShowPublishCancelDialog] = useState(false);
  const [publishSuccessDialogOpen, setPublishSuccessDialogOpen] =
    useState(false);
  const publishRow = async (value) => {
    setShowPublishDialog(true);
    const updated_by = currentUser?.login_id;

    setRowPublish({
      ...value, // Copy the existing properties from 'value' object
      created_by,
      updated_by

    });
  };

  const handlePublishDialogClose = () => {
    setShowPublishDialog(false);
    setShowPublishCancelDialog(true);
  };
  const handleNoBranchDialogClose = () => {
    setShowPublishDialog(false);
  };

  const handleDialogClose = () => {
    setShowPublishCancelDialog(false);
  };

  const [branchNames, setBranchNames] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(currentUser?.bitbucket_branch || "main");

  console.log(currentUser?.bitbucket_branch, "currentUser?.bitbucket_branchcurrentUser?.bitbucket_branch")
  const connectionName = value?.connection_name

  const { data: branchData, error: branchError } = useGetGitBranchesWithFileQuery({
    variables: {
      user: currentUser?.login_id,
      project: value?.project_id,
      name: connectionName,
      type: "connection"
    },
  }
  );

  useEffect(() => {
    if (branchData) {
      const branchesWithStatus = branchData.branches.map((branch) => {
        return {
          ...branch,
          statusMessage: branch.pathExists ? 'File Path Exists  ' : 'File Path Does Not Exist',
        };
      });
      setBranchNames(branchesWithStatus);
    }
  }, [branchData]);
  const handlePublishSuccessDialogClose = () => {
    setPublishSuccessDialogOpen(false);
    setOpenPopupForClosing(false);
  };

  const [gitaddConnection] = useGitConnectionDataMutation();

  const handlePublishConfirm = async (resetForm) => {
    setShowPublishDialog(false);
    let values = rowpublish;
    let connectionValues = {
      ...values,
      connection_type: currConnType,
      connection_subtype: currSubConnType,
    };

    let tblConnection = {
      connection_type: currConnType,
      connection_subtype: currSubConnType,
      connection_name: values.connection_name,
      project_id: values.project_id,
      id: values?.id || 0,
    };
    Object.keys(tblConnection).forEach((prop) => delete connectionValues[prop]);

    const connDetails = [];
    Object.keys(connectionValues).forEach((key, index) => {
      connDetails.push({
        key_01: key,
        value_01: values[key],
        sequence: index + 1,
      });
    });

    const connectionRecord = {
      ...tblConnection,
      details: [...connDetails],
    };
    const publishRecord = JSON.parse(JSON.stringify(connectionRecord));

    try {
      if (selectedBranch) {
        await gitaddConnection({ data: publishRecord, branch: selectedBranch, login_id: currentUser?.login_id });
      } else {
        await gitaddConnection({ data: publishRecord, branch: "main", login_id: currentUser?.login_id });
      }
    } catch (error) {
      console.log(error);
    }
    setPublishSuccessDialogOpen(true);
  };

  const loadConnSubType = (subTypeValue) => {
    try {
      let subConnTypeList = formData.filter(
        (item) => item.connection_subtype === subTypeValue
      );

      let fieldList = Object.assign([], subConnTypeList[0].fields_list);
      fieldList.sort(function (a, b) {
        return a.display_sequence - b.display_sequence;
      });

      setConnFieldForm(fieldList);
      // set UserSchema
      loadUserSchema(fieldList);
      // set the initalValues.
      if (value?.connection_type != null && value?.connection_type !== "") {
        let fields = Object.assign({}, ...value.fields_list);
        const val = {
          connection_name: value.connection_name,
          connection_type: value.connection_type,
          connection_subtype: value.connection_subtype,
          project_id: value.project_id,
          id: value?.id || 0,
        };
        const defaulValue = { ...val, ...fields };
        setInitialValues(defaulValue);
      } else {
        setInitialValues(subConnTypeList[0].initialvalues);
      }
    } catch (err) {
      setErrors("Error while reading Records");
    }
  };

  const loadUserSchema = (fields_list) => {
    let validationObject = {};
    fields_list.forEach(({ field_id, display_label, required }) => {
      if (required === "Y") {
        validationObject[field_id] = yup
          .string()
          .required(`${field_id} is required`);
        if (
          field_id === "connection_name"
        ) {
          validationObject[field_id] = validationObject[field_id].matches(
            /^\w+$/,
            `${field_id} must be alphanumeric and may contain underscores only`
          );
        }
      }
    });
    const validationSchema = yup.object().shape(validationObject);
    setUserSchema(validationSchema);
  };

  const fetchConnSubType = (newValue) => {
    let subType = formData
      .filter((item) => item.connection_type === newValue)
      .map((value) => value.connection_subtype)
      .filter((value, index, _arr) => _arr.indexOf(value) === index);

    setConnSubType(subType);
    if (value?.connection_subtype != null && value?.connection_subtype !== "" && value?.connection_type === newValue) {
      setCurrSubConnType(value["connection_subtype"]);
      loadConnSubType(value["connection_subtype"]);
    } else {
      setCurrSubConnType(subType[0]);
      loadConnSubType(subType[0]);
    }
  };

  if (formData && !connType) {
    const connList = formData
      .map((rec) => rec.connection_type)
      .filter((rec, index, _arr) => _arr.indexOf(rec) === index);
    setConnType(connList);
    if (value?.connection_type != null && value?.connection_type !== "") {
      setCurrConnType(value["connection_type"]);
    } else {
      setCurrConnType(connList[0]);
    }
  }

  if (formData && connType && !connSubType) {
    fetchConnSubType(currConnType);
    const newConnType = currConnType;
    setCurrConnType(newConnType);
  }

  const handleConnTypeChange = (event, newValue) => {
    if (newValue != null) {
      fetchConnSubType(newValue);
      setCurrConnType(newValue);
      if (value.connection_type != newValue) {
        const result = props.formData.find(obj => obj.connection_type === newValue);
        const initalval = result.initialvalues;
        const data = Object.assign({}, initalval);
        data.id = value.id;
        setInitialValues(data);
      }
    }
  };

  const handleConnSubTypeChange = (event, newValue) => {
    try {
      if (newValue != null) {

        setCurrSubConnType(newValue);
        loadConnSubType(newValue);
        if (value.connection_subtype != newValue) {
          const result = props.formData.find(obj => obj.connection_subtype === newValue);
          const initalval = result.initialvalues;
          const data = Object.assign({}, initalval);
          data.id = value.id;
          setInitialValues(data);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const testConnection = async (values) => {
    try {
      const subType = values.connection_subtype.replace(/ /g, "").toLowerCase();

      if ("password" in values) {
        values.password = encrypt(values.password);
      }

      let response;
      if (subType === "restapi") {
        response = await TestRestApi({
          subType,
          authentication_type: values.authentication_type,
          values,
        });
      } else {
        response = await TestConnections({
          subType,
          values,
        });
      }

      if (response.error) {
        throw new Error(response?.error?.data?.message);
      }

      setIsConnSuccess(true);
      setOpenPopup(true);
    } catch (error) {
      setIsConnSuccess(false);
      setErrorMessage(`${error}`);
      setOpenPopup(true);
    }
  };

  const handleOkBtnClick = () => {
    setOpenPopup(false); // Close the popup on No button click
  };

  const handleFormSubmit = async (values) => {
    let connectionValues = {
      ...values,
      connection_type: currConnType,
      connection_subtype: currSubConnType,
    };

    let tblConnection = {
      connection_type: currConnType,
      connection_subtype: currSubConnType,
      connection_name: values.connection_name,
      project_id: values.project_id,
      id: values?.id || 0,
    };

    Object.keys(tblConnection).forEach((prop) => delete connectionValues[prop]);

    const connDetails = [];
    Object.keys(connectionValues).forEach((key, index) => {
      connDetails.push({
        key_01: key,
        value_01: values[key],
        sequence: index + 1,
      });
    });

    const connectionRecord = {
      ...tblConnection,
      details: [...connDetails],
    };

    try {
      addOrEditConn(connectionRecord);
      setIsSaveSuccess(true)
      toast.success(`Connection Saved Successfully`);
      setOpenPopup(true);

    } catch (error) {
      console.error("Form Submission Error: ", error);
      toast.error("Form Submission Error");
      setOpenPopup(true);
    }
  };

  const checkConnectionNameExists = async (connection_name, setFieldError, validateForm) => {
    try {
      const response = await axios.get(
        `${baseURL}/api/connections/connectionexists/${connection_name}`,
        {
          headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const exists = response.data.exists;
 
      console.log(userSchema);
     
      updateValidationSchema(exists);
 
      // Trigger validation immediately after schema update
      if (exists) {
        setFieldError('connection_name', 'Connection name already exists');
      } else {
        // Clear the error if the connection name does not exist
        setFieldError('connection_name', undefined);
      }
    } catch (error) {
      console.error(error);
    }
  };
 
  const updateValidationSchema = (exists) => {
    setUserSchema((prevSchema) =>
      prevSchema.shape({
        ...prevSchema.fields,
        connection_name: yup.string()
          .test(
            'unique-connection-name',
            'Connection name already exists',
            () => !exists
          )
          .matches(/^\w+$/, 'Connection Name must be alphanumeric and may contain underscores only')
          .required('Connection Name is required'),
      })
    );
  };


  return (
    <>
      <Box>
        <Popup
          title="Publish Connection To Github?"
          openPopup={showPublishDialog}
          width={"md"}
          setOpenPopup={setShowPublishDialog}
          handleYesBtnClick={handlePublishConfirm}
          handleNoBtnClick={handlePublishDialogClose}
          yesBtn={true}
          noBtn={true}
        >
          <p>
            Do you want to publish this task {rowpublish?.connection_name} to
            GitHub
          </p>

          <FormControl style={{ width: "20%" }}>
            <InputLabel>Select Branch</InputLabel>
            <Select
              value={selectedBranch}
              onChange={(event) => setSelectedBranch(event.target.value)}
              style={{ width: "100%" }}
            >
              {branchNames.map((branch) => (
                <MenuItem key={branch.branchName} value={branch.branchName}>
                  {branch.branchName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {/* Display whether path exists or not */}
          {selectedBranch && (
            <p
              style={{
                color: branchNames.find(
                  (branch) => branch.branchName === selectedBranch
                )?.pathExists
                  ? "green"
                  : "red",
              }}
            >
              {branchNames.find(
                (branch) => branch.branchName === selectedBranch
              )?.pathExists
                ? `Task : ${rowpublish?.connection_name} exists in ${selectedBranch} branch. Do you want to overwrite?`
                : `Task : ${rowpublish?.connection_name} Does Not Exist in ${selectedBranch} branch. Do you want to create it?`}
            </p>
          )}
        </Popup>
        <Popup
          title="Canceled"
          openPopup={showPublishCancelDialog}
          setOpenPopup={setShowPublishCancelDialog}
          width={"md"}
          handleOkBtnClick={handleDialogClose}
          okBtn={true}
          noBtn={false}
        >
          <p>
            Publishing connection: {rowpublish?.connection_name} record to
            GitHub is Canceled.
          </p>
        </Popup>

        <Popup
          title="Connection Published"
          openPopup={publishSuccessDialogOpen}
          setOpenPopup={setShowPublishCancelDialog}
          width={"md"}
          handleOkBtnClick={handlePublishSuccessDialogClose}
          okBtn={true}
          noBtn={false}
        >
          <div style={{ padding: '20px', textAlign: 'left' }}>
            <strong>
              <p>
                Connection Name: {rowpublish?.connection_name} has been successfully uploaded
                to GitHub.
              </p>
              <p>Branch: {selectedBranch || "main"}</p>
              <p>Created by: {rowpublish?.created_by}</p>
              <p>Updated by: {rowpublish?.updated_by}</p>
            </strong>
          </div>


        </Popup>

        <Popup
          title={isConnSuccess ? "Connection Successful" : "Connection failure"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          handleOkBtnClick={handleOkBtnClick}
          okBtn={true}
        >
          {isConnSuccess
            ? "Connection to the server was successful!"
            : `${errorMessage}`}
        </Popup>
      </Box>
      {errors ? (
        <Box display="flex" flexDirection="row" sx={{ m: "3rem 1.5rem" }}>
          <Typography variant="h4">{errors}</Typography>
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"

          >
            <ToggleButtonElement2
              alignment="center"
              value={currConnType}
              connList={connType}
              handleChange={handleConnTypeChange}
            />
          </Box>
          <Box margin="1rem 0rem" display="flex">
            <Box  sx={{
    marginTop: "100px", // Margin from the top
  }}>
              <ToggleButtonElement
                alignment="vertical"
                value={currSubConnType}
                connList={connSubType}
                handleChange={handleConnSubTypeChange}
              />
            </Box>


            <Box >
              <Formik
                enableReinitialize={true}
                onSubmit={handleFormSubmit}
                validationSchema={userSchema}
                initialValues={initialValues}
                key={resetKey}
              >
                {({
                  values,
                  errors,
                  touched,
                  dirty,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  handleReset,
                  isSubmitting,
                  validateField,
                  setFieldError
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      gap="20px"
                    >
                      <Box
                        display="flex"
                        justifyContent="center"
                        gap="15px"
                      >
                        <Box>
                          <Typography>
                            Connection Type:{" "}
                            <span style={{ color: "var(--primary-color) !important", fontFamily: "Poppins", fontWeight: "600" }}>
                              {currConnType}
                            </span>
                          </Typography>
                        </Box>
                        <Box>
                          <Typography>
                            Connection Sub Type:{" "}
                            <span style={{ color: "var(--primary-color) !important", fontFamily: "Poppins", fontWeight: "600" }}>
                              {currSubConnType}
                            </span>
                          </Typography>
                        </Box>
                      </Box>

                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        gap="10px"
                      >
                        {connFieldForm
                          ? connFieldForm.map((formElement, index) => (
                            <Element
                              key={formElement.field_id}
                              field={formElement}
                              values={values}
                              type={currConnType}
                              touched={touched}
                              errors={errors}
                              handleBlur={handleBlur}
                              handleChange={(event) => {
                              handleChange(event)
                              if (formElement.field_id === 'connection_name') {
                                const value = event.target.value;
                                checkConnectionNameExists(value, setFieldError, validateField);
                              }
                            }}
                            />
                          ))
                          : null}
                      </Box>
                    </Box>

                    <Box display="flex" justifyContent="center" gap="10px" mt="30px">
                      <Button
                        type="button"
                        sx={styles.cssBtnTest}
                        onClick={() =>
                          testConnection({
                            connection_type: currConnType,
                            connection_subtype: currSubConnType.replace(/\s/g, ""),
                            ...values,
                          })
                        }
                      >
                       <PublishedWithChangesIcon sx={{ marginRight: "6px" }} /> Test Connection
                      </Button>

                      <Button
                        type="button"
                        sx={
                          isSaveSuccess && isConnSuccess
                            ? styles.cssBtnEnabled
                            : styles.cssBtnDisabled
                        }
                        variant="contained"
                        onClick={() => {
                          publishRow(values);
                        }}
                        disabled={!isSaveSuccess || !isConnSuccess}
                      >
                      < GitHubIcon sx={{ marginRight: "6px" }}/> Publish To GitHub
                      </Button>

                      <Button
                        type="button"
                        onClick={() => {
                          handleReset();
                          setResetKey((prevKey) => prevKey + 1);
                        }}
                        sx={styles.cssBtnResets}
                        disabled={!dirty || isSubmitting}
                      >
                       <RestartAltIcon sx={{ marginRight: "6px" }} /> Reset
                      </Button>

                      <Button
                        type="submit"
                        sx={isConnSuccess ? styles.cssBtnEnabled : styles.cssBtnDisabled}
                        variant="contained"
                        onClick={handleFormSubmit}
                        disabled={isSubmitting || !isConnSuccess}
                      >
                       <SaveIcon sx={{ marginRight: "6px" }}/> Save Connection
                      </Button>
                   
                    </Box>

                  </form>
                )}
              </Formik>
            </Box>
          </Box>
        </>




      )}
    </>
  );
};

export default ConnectionForm;
