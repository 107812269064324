export const findSectionField = (formObject, section, field = null) => {
    const sectionRecord = formObject?.find((rec) => rec.section === section);
    if (field) {
      const fieldRecord = sectionRecord.fields_list.find(
        (rec) => rec.field_id === field
      );
      return fieldRecord;
    }
    return sectionRecord;
  };

  const findLabel = (obj) => {
    try {
      for (const key in obj) {
        if (
          obj[key] &&
          typeof obj[key] === "object" &&
          obj[key].hasOwnProperty("output_df")
        ) {
          return obj[key].output_df;
        }
      }
      return null; // or throw an error if you prefer
    } catch (error) {
      console.log("Error in finding label", error);
    }
  };

export const checkLabel = (sourceNode) => {
    try {
      let edgeLabel;
      if (sourceNode.data.label === "Filter") {
        edgeLabel = findLabel(sourceNode.data.Filter);
        return edgeLabel;
      } else if (sourceNode.data.label === "Expression") {
        edgeLabel = findLabel(sourceNode.data.Expression);
        return edgeLabel;
      } else {
        edgeLabel = sourceNode.data.output_df;
        return edgeLabel;
      }
    } catch (error) {
      console.log("Error in fetching label", error);
    }
  };

// node handler functions

const handleNodeData = (node, findConnectedNodes, setSelection, selectionKey) => {
    const inputNodes = findConnectedNodes(node.id);
    const inputData = inputNodes.map(inputNode => {
      const { displayName, ...restOfData } = inputNode.data;
      return restOfData;
    });
    setSelection({ [selectionKey]: node.id, inputs: inputData });
  };

export const handleNodeSetup = (node, setSelectedNodeId, setNodeName, formValues, handleResetForm, setRenderChoice, setOpen, setNodeLabel) => {
    setSelectedNodeId(node.id);
    setNodeName(node.data.displayName);
    const nodeFormValues = formValues[node.id] || {};
    handleResetForm(nodeFormValues, node.data.label);
    setRenderChoice(true);
    setOpen(true);
    setNodeLabel(node.data.label);
  };
  
  export const handleJoinerNode = (node, findConnectedNodes, setJoinerSelection) => {
    const handle1Id = `handle_${node.id}`;
    const handle2Id = `handle_${node.id}1`;
  
    const inputNodes1 = findConnectedNodes(node.id, handle1Id);
    const inputNodes2 = findConnectedNodes(node.id, handle2Id);
  
    const inputData1 = inputNodes1.map(inputNode => ({
      source: 'inputNodes1',
      ...inputNode.data
    }));
  
    const inputData2 = inputNodes2.map(inputNode => ({
      source: 'inputNodes2',
      ...inputNode.data
    }));
  
    const inputData = [...inputData1, ...inputData2];
    setJoinerSelection({ joinerId: node.id, inputs: inputData });
  };

  export const handleFilterNode = (node, findConnectedNodes, setFilterSelection) => {
    handleNodeData(node, findConnectedNodes, setFilterSelection, "filterId");
  };
  
  export const handleOutputNode = (node, findConnectedNodes, setOutputSelection) => {
    handleNodeData(node, findConnectedNodes, setOutputSelection, "outputId");
  };
  
  export const handleSQLNode = (node, findConnectedNodes, setSQLSelection) => {
    handleNodeData(node, findConnectedNodes, setSQLSelection, "sqlId");
  };
  
  export const handlePythonNode = (node, findConnectedNodes, setPythonSelection) => {
    handleNodeData(node, findConnectedNodes, setPythonSelection, "pythonId");
  };
  
  export const handleExpressionNode = (node, findConnectedNodes, setExpressionDataSelection, setChoice) => {
    handleNodeData(node, findConnectedNodes, setExpressionDataSelection, "expressionId");
    setChoice(""); // Additional behavior for Expression node
  };

  export const addTaskDetail = (taskDetails, label, transformation_name, key, value, index) => {
    taskDetails.push({
      task_type: label,
      parameter_type: transformation_name || "id",
      key_01: key,
      value_01: value,
      sequence: index + 1,
      is_active: "Y",
    });
  };

  // Helper function to process nested objects
export const processNestedObject = (taskDetails, label, parameterType, obj, sequence) => {
  for (const key in obj) {
    const value = obj[key];
    if (typeof value === 'object' && value !== 'select_columns') {
      processNestedObject(taskDetails, label, key, value, sequence); // Recursively process nested objects
    } else {
      if (key !== 'label' && key !== 'displayName' && key !== 'select_columns') {
        addTaskDetail(taskDetails, label, parameterType, key, value, sequence);
      }
    }
  }
};

// Processing different node types
export const processFilterNode = (taskDetails, node, index) => {
  const { label, transformation_name } = node.data;
  const Filter = node.data.Filter;
  for (const filterKey in Filter) {
    processNestedObject(taskDetails, label, transformation_name || filterKey, Filter[filterKey], index);
  }
};

export const processExpressionNode = (taskDetails, node, index) => {
  const { label, transformation_name } = node.data;
  const expression = node.data.Expression;
  for (const exprKey in expression) {
    processNestedObject(taskDetails, label, transformation_name || exprKey, expression[exprKey], index);
  }
};

export const processSQLNode = (taskDetails, node, index) => {
  const { label, transformation_name } = node.data;
  for (const key in node.data) {
    if (key !== 'label' && key !== 'displayName') {
      const values = node.data[key]?.split(',').map(item => item.trim()) || [];
      values.forEach((value, idx) => {
        addTaskDetail(taskDetails, label, transformation_name, key, value, idx);
      });
    }
  }
};

export const processPythonNode = (taskDetails, node, index) => {
  const { label, transformation_name } = node.data;
  for (const key in node.data) {
    if (key !== 'label' && key !== 'displayName') {
      let values = typeof node.data[key] === 'string' ? node.data[key].trim().split(',').map(item => item.trim()) : [node.data[key]];
      values.forEach((value, idx) => {
        addTaskDetail(taskDetails, label, transformation_name, key, value, idx);
      });
    }
  }
};

export const processJoinerNode = (taskDetails, node, index) => {
  const { label, transformation_name } = node.data;
  for (const key in node.data) {
    const value = node.data[key];
    if (key !== 'label' && key !== 'displayName') {
      addTaskDetail(taskDetails, label, transformation_name, key, value, index);
    }
  }
};

export const updateEdgeLabel = (edge, sourceNode) => {
    const edgeLabel = checkLabel(sourceNode);
    return {
      ...edge,
      data: {
        ...edge.data,
        label: edgeLabel,
      },
    };
  };
  
export const findSourceNode = (edge, nodes) => nodes.find((node) => node.id === edge.source);