import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Table, TableBody, TableCell, TableRow } from '@mui/material';
import Element from "../components/Element";
import { useFormik } from "formik";
import { selectCurrentUser } from "state/authSlice";
import { useSelector } from "react-redux";
import Switch from '@mui/material/Switch';
import {
    useAddSchedulerMutation,
    useValidateSchedulerQuery,
    useScheduleDescriptionQuery
} from "../state/apiSlice";
import { toast } from 'react-hot-toast';
import useStyles from "useStyles";
import Papa from "papaparse";
import timezoneCSV from "../assets/timezone.csv";

const helpBoxContent = {
    minute: {
        rows: [
            { symbol: '*', description: 'any value' },
            { symbol: ',', description: 'value list separator' },
            { symbol: '-', description: 'range of values' },
            { symbol: '/', description: 'step values' },
            { symbol: '0-59', description: 'allowed values' },
        ]
    },
    hour: {
        rows: [
            { symbol: '*', description: 'any value' },
            { symbol: ',', description: 'value list separator' },
            { symbol: '-', description: 'range of values' },
            { symbol: '/', description: 'step values' },
            { symbol: '0-23', description: 'allowed values' },
        ]
    },
    day_of_month: {
        rows: [
            { symbol: '*', description: 'any value' },
            { symbol: ',', description: 'value list separator' },
            { symbol: '-', description: 'range of values' },
            { symbol: '/', description: 'step values' },
            { symbol: '1-31', description: 'allowed values' },
        ]
    },
    month: {
        rows: [
            { symbol: '*', description: 'any value' },
            { symbol: ',', description: 'value list separator' },
            { symbol: '-', description: 'range of values' },
            { symbol: '/', description: 'step values' },
            { symbol: '1-12', description: 'allowed values' },
            { symbol: 'JAN-DEC', description: 'alternative single values' },
        ]
    },
    day_of_week: {
        rows: [
            { symbol: '*', description: 'any value' },
            { symbol: ',', description: 'value list separator' },
            { symbol: '-', description: 'range of values' },
            { symbol: '/', description: 'step values' },
            { symbol: '0-6', description: 'allowed values' },
            { symbol: 'SUN-SAT', description: 'alternative single values' },
        ]
    },
    general: {
        rows: [
            { symbol: '*', description: 'any value' },
            { symbol: ',', description: 'value list separator' },
            { symbol: '-', description: 'range of values' },
            { symbol: '/', description: 'step values' },
            { symbol: '@yearly', description: '(non-standard)' },
            { symbol: '@annually', description: '(non-standard)' },
            { symbol: '@monthly', description: '(non-standard)' },
            { symbol: '@weekly', description: '(non-standard)' },
            { symbol: '@daily', description: '(non-standard)' },
            { symbol: '@hourly', description: '(non-standard)' },
        ]
    }
};

/**
 * Scheduler component for managing task scheduling form and handling form submission.
 * 
 * @param {Object} tasksRecords - Array of task records with field and initial values.
 * @param {Object} taskValues - Object containing job-specific information.
 * @param {Object} cronValues - Object containing cron specific details.
 * @param {String} jobType - Type of job i.e., task or pipeline.
 * @param {State} setOpenPopup - State to manage closing of popup after form submission. 
 */
const Scheduler = ({ tasksRecords, taskValues, cronValues, jobType, setOpenPopup }) => {

    const currentUser = useSelector(selectCurrentUser);
    const user = currentUser.login_id;
    const styles = useStyles();

    // Mutations for adding and updating scheduler objects
    const [addObject] = useAddSchedulerMutation();
    const cronFields = ['minute', 'hour', 'day_of_month', 'month', 'day_of_week'];

    // State for managing 
    const [schedule, setSchedule] = useState('* * * * *');
    const [isValid, setIsValid] = useState(true);
    const [selectedField, setSelectedField] = useState('general')
    const [isActive, setIsActive] = useState(false);
    const [scheduleDescription, setScheduleDescription] = useState("");
    const [data, setData] = useState([]);

    //Rtk for validation
    const { data: validation } = useValidateSchedulerQuery(schedule);
    const { data: description } = useScheduleDescriptionQuery(schedule);

    // Handle switch toggle event
    const handleToggle = (event) => {
        setIsActive(event.target.checked); // Update state based on the switch's new checked value
    };

    const handleFieldInteraction = (fieldId) => {
        console.log(fieldId)
        if (fieldId === "time_zone") {
            setSelectedField("general");  // Set to "general" if fieldId is "timezone"
        } else {
            setSelectedField(fieldId);  // Otherwise, set to the given fieldId
        }
    };

    // State to set cron active status and fetch timezones from csv
    useEffect(() => {
        if (cronValues?.is_cron_active == 'Y') {
            setIsActive(true);
        }
        const fetchData = async () => {
            const response = await fetch(timezoneCSV);
            const reader = response.body.getReader();
            const result = await reader.read();
            const decoder = new TextDecoder("utf-8");
            const csvData = decoder.decode(result.value);
            const parsedData = Papa.parse(csvData, {
                header: true,
                skipEmptyLines: true,
            }).data;
            setData(parsedData);
        };
        fetchData();
    }, [cronValues])

    //Handle scheduling
    const handleFormSubmit = async (values, resetForm) => {
        try {
            let schedulerData = {
                id: cronValues?.cron_id,
                cron_scheduler_record: {
                    pipeline_id: jobType === 'task' ? taskValues?.pipeline_id : (jobType === 'pipeline' ? taskValues?.id : null),
                    task_id: jobType === 'task' ? taskValues?.id : null,
                    cron_schedule: schedule,
                    is_active: isActive ? 'Y' : 'N',
                    created_by: taskValues?.created_by,
                    updated_by: user,
                    time_zone: values.time_zone,
                },
                job_type: jobType,
            };

            const response = await addObject({ object: jobType.charAt(0).toUpperCase() + jobType.slice(1), data: schedulerData });

            if (response) {
                if (response.data.message) {
                    toast.success(response.data.message);
                }
                else if (response.data.error) {
                    toast.error(response.data.error);
                }
            }
            setTimeout(() => {
                setOpenPopup(false);
            }, 500);
        } catch (error) {
            console.error("Error submitting the form:", error);
        }
    };

    //Initializes the form's initial values based on whether a cron schedule exists.
    const initialValuesFunction = () => {
        try {
            if (cronValues.cron_schedule == null) {
                return tasksRecords[0].initial_values; // Return the default initial values
            } else {
                // If a cron schedule exists, split it and map it to the corresponding form fields
                const valuesArray = cronValues.cron_schedule.split(' ');
                let newObject = cronFields.reduce((acc, fieldId, index) => {
                    acc[fieldId] = valuesArray[index];  // Map each value from the cron_schedule to the corresponding field id
                    return acc;
                }, {});
                newObject = {
                    ...newObject,
                    time_zone: cronValues?.cron_time_zone
                }
                return newObject;
            }
        } catch (error) {
            console.error("Error initializing form values:", error);
            return {};
        }
    };

    // Extract the fields list from task records and sort them by display sequence
    let sortedFieldsList;
    try {
        let fieldsList = tasksRecords[0]?.fields_list;
        sortedFieldsList = fieldsList.map(field => ({ ...field })).sort((a, b) => a.display_sequence - b.display_sequence);
        const timezoneFieldIndex = sortedFieldsList.findIndex((field) => field.field_id === "time_zone");
        if (timezoneFieldIndex !== -1) {
            // Create a copy of the timezone field and update its options
            const updatedTimezoneField = {
                ...sortedFieldsList[timezoneFieldIndex],
                option_list: data, // Assign the new options array here
            };

            // Reassign the updated object back to sortedFieldsList
            sortedFieldsList[timezoneFieldIndex] = updatedTimezoneField;
        }
    } catch (error) {
        console.error("Error while fetching scheduler fields list", error);
    }

    //Formik initialization
    const formik = useFormik({
        onSubmit: handleFormSubmit,
        initialValues: initialValuesFunction(tasksRecords),
    });

    //Validate a schedule and give the description of it in a human readable language.
    useEffect(() => {
        const cronSchedule = cronFields
            .map(fieldId => formik.values[fieldId])
            .join(' ');

        setSchedule(cronSchedule);

        // Trigger validation only if schedule is updated 
        if (cronSchedule) {
            setIsValid(validation?.isValid);
            // Set description only if cron is valid.
            if (validation?.isValid) {
                setScheduleDescription(description?.description);
            }
            else {
                setScheduleDescription('');
            }
        }
    }, [formik.values, validation, description]);


    return (

        <>
            <form onSubmit={formik.handleSubmit}>
                <Box
                    sx={{
                        p: 2,
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        backgroundColor: '#f9f9f9',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    {/* Display pipeline and task names */}
                    <Box>
                        <Typography variant="h4" sx={{ fontWeight: 'bold', color: 'var(--primary-color) !important', mb: 1 }}>
                            Pipeline Name: {taskValues?.pipeline_name}
                        </Typography>
                        {taskValues?.task_name && (
                            <Typography variant="h6" sx={{ fontWeight: 'medium', color: '#757575' }}>
                                Task Name: {taskValues?.task_name}
                            </Typography>
                        )}
                    </Box>

                    <Box sx={{ display: 'flex', gap: '10px' }}>
                        {/* Schedule button */}
                        <Button
                            type="submit"
                            sx={{ ...styles.myBackground }}
                        >
                            Schedule
                        </Button>
                    </Box>
                </Box>


                <Box
                    sx={{
                        p: 2,
                        display: 'flex',
                        // justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '1%',
                        marginTop: '1%',
                    }}
                >

                    <Box sx={{ width: '100px', flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                color: isValid ? 'green' : 'red', // Set color based on validity
                                fontWeight: '600',
                            }}
                        >
                            Validation: {isValid ? 'Valid' : 'Invalid'}
                        </Typography>
                    </Box>

                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: '600',
                            }}
                        >
                            {scheduleDescription}
                        </Typography>
                    </Box>

                    <Box sx={{ width: '100px', flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <Switch
                            checked={isActive}
                            onChange={handleToggle} // Updates state when toggled
                            sx={{
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: 'var(--primary-color)', // Color of the switch when active
                                },
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: 'var(--primary-color)', // Track color when active
                                },
                                '& .MuiSwitch-switchBase': {
                                    color: 'var(--tertiary-color)', // Color of the switch when inactive
                                },
                                '& .MuiSwitch-switchBase + .MuiSwitch-track': {
                                    backgroundColor: 'var(--tertiary-color)', // Track color when inactive
                                },
                            }}
                        />
                    </Box>
                </Box>


                <Box display="flex" gap="20px" marginTop="10px" alignItems="center" margin="auto">
                    {sortedFieldsList
                        ? sortedFieldsList.map((field) => (
                            <Element
                                key={field.id}
                                field={field}
                                values={formik.values}
                                touched={formik.touched}
                                errors={formik.errors}
                                handleBlur={(e) => {
                                    formik.handleBlur(e);
                                }}
                                handleFocus={(e) => {
                                    formik.handleFocus(e);
                                }}
                                handleChange={(e) => {
                                    formik.handleChange(e);
                                    handleFieldInteraction(field.field_id);  // Trigger help content on change
                                }}
                            />
                        ))
                        : null}
                </Box>

                <Box display="flex" mt="30px">
                    <Box flex="1">
                        {selectedField && helpBoxContent[selectedField] && (
                            <Box
                                sx={{
                                    maxWidth: '80%',
                                    mx: 'auto',
                                    backgroundColor: 'white',
                                    p: 2,
                                    borderRadius: '8px',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="h6" sx={{ mb: 1, textAlign: 'center' }}>
                                    Helper Box for {selectedField.replace(/_/g, ' ')}
                                </Typography>
                                <Table sx={{ tableLayout: 'fixed' }}>
                                    <TableBody>
                                        {helpBoxContent[selectedField].rows.length <= 5 ? (
                                            // If the number of rows is odd and 5, render them in one column
                                            helpBoxContent[selectedField].rows.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell
                                                        sx={{ width: '50%', textAlign: 'center', fontWeight: '600' }}
                                                    >
                                                        {row.symbol}
                                                    </TableCell>
                                                    <TableCell>{row.description}</TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            // Otherwise, split rows into two columns
                                            helpBoxContent[selectedField].rows.reduce((result, row, index) => {
                                                if (index % 2 === 0) result.push([]);
                                                result[result.length - 1].push(row);
                                                return result;
                                            }, []).map((rowPair, rowIndex) => (
                                                <TableRow key={rowIndex}>
                                                    {rowPair.map((row, colIndex) => (
                                                        <React.Fragment key={colIndex}>
                                                            <TableCell
                                                                sx={{
                                                                    width: '20%',
                                                                    textAlign: 'center',
                                                                    fontWeight: '600'
                                                                }}
                                                            >
                                                                {row.symbol}
                                                            </TableCell>
                                                            <TableCell>{row.description}</TableCell>
                                                        </React.Fragment>
                                                    ))}
                                                    {rowPair.length < 2 && (
                                                        <>
                                                            <TableCell></TableCell> {/* Placeholder to fill remaining cell */}
                                                            <TableCell></TableCell>
                                                        </>
                                                    )}
                                                </TableRow>
                                            ))
                                        )}
                                    </TableBody>
                                </Table>
                            </Box>
                        )}
                    </Box>
                </Box>

            </form>
        </>
    );
};

export default Scheduler;
