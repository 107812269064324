import React, { useState } from "react";

import { useMsal } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { setCredentials, setAuthType } from "../state/authSlice";
import { useNavigate } from "react-router-dom";
import azure from "../svg/azure.svg";
import { Box, Button, Typography } from "@mui/material";
import { graphConfig, loginRequest } from "../msalAuthConfig";
import {
  useLazyCheckUserExistQuery,
  useRegisterUserMutation,
  useAzureLoginMutation,
} from "state/apiSlice";
import { saveTokenInLocalStorage } from "../state/authService";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */
export async function getUserGroupsFromMsal(accessToken) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers,
  };

  try {
    const IKART_ADMIN = "QK-IKART-DEV-ADMIN";
    const IKART_USER = "QK-IKART-DEV-USER";

    var groupList = [];

    const groupListResponse = await fetch(graphConfig.groups, options);
    const groupsResponse = await groupListResponse.json();
    const { value } = groupsResponse; // No need to parse JSON twice.
    groupList = value.map((a) => a.displayName);
    console.log("groupList: ", groupList);

    const isAdmin = groupList.includes(IKART_ADMIN);
    const isUser = groupList.includes(IKART_USER);

    console.log("Is User an Admin: ", isAdmin);
    console.log("Is User a Normal User: ", isUser);

    const userRole = isAdmin ? "ADMIN" : isUser ? "USER" : "NONE";
    return userRole;
  } catch (error) {
    console.log("Error fetching user groups: ", error);
    return "USER"; // Return default role if an error occurs
  }
}

const MsalLogin = () => {
  const { instance } = useMsal();
  const [checkUserExist] = useLazyCheckUserExistQuery();
  const [registerUser] = useRegisterUserMutation();
  const [azureLogin, { isLoading }] = useAzureLoginMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = () => {
    const accounts = instance.getAllAccounts();
    if (accounts.length === 0) {
      // No accounts found, perform an interactive login
      instance
        .loginPopup(loginRequest)
        .then(async (loginResponse) => {
          // Handle successful login
          handleTokenAcquisition(loginResponse.account);
        })
        .catch((error) => {
          console.error("Login failed:", error);
        });
    } else {
      // If an account exists, try to acquire the token silently
      handleTokenAcquisition(accounts[0]);
    }
  };

  const handleTokenAcquisition = (account) => {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: account,
      })
      .then(async (response) => {
        console.log("Response: ", response);

        // Fetch user profile and role, with fallback role in case of error
        const userRole =
          (await getUserGroupsFromMsal(response.accessToken)) || "USER";

        const userInfo = {
          full_name: account.name,
          login_id: account.username, // You can use account.id if needed
          email: account.username,
          role: userRole,
          is_active: "Y",
        };

        dispatch(setAuthType({ authType: "AzureMsal" }));

        // Check if the user exists in tbl_users
        const userExist = await checkUserExist(account.username);
        const isActive = userRole === "ADMIN" ? "Y" : "N";
        if (userExist?.data?.exists !== true) {
          const userSaved = await registerUser({
            full_name: userInfo.full_name,
            login_id: userInfo.email,
            role: userRole,
            user_email: userInfo.email,
            user_phone: "91199999",
            created_by: "azure",
            password: "NONE",
            is_active: isActive,
            isAzure: true,
          });
          console.log(`User: ${userSaved?.data?.login_id} saved.`);
        }

        try {
          const userData = await azureLogin({
            login_id: account.username,
            password: "NONE",
          }).unwrap();
          let { token, ...userInfo } = userData;
          let { ...userTokenDetails } = userData;
          dispatch(setCredentials({ user: userInfo, token }));
          saveTokenInLocalStorage(userTokenDetails);
          navigate("/dashboard");
        } catch (error) {
          console.error("Azure login error:", error?.data?.error);
          setErrorMessage(error?.data?.error);
        }
      })
      .catch((error) => {
        console.error("Token acquisition failed:", error);
        // Handle error (you can choose to navigate or show a fallback)
      });
  };


  return (
    <>
      <Box display="flex" alignItems="center" my={2}>
        <Box sx={{ flex: 1, height: "1px", backgroundColor: "#ccc" }} />
        <Typography
          sx={{ margin: "0 10px", fontWeight: "600", fontSize: "14px" }}
        >
          OR Login with
        </Typography>
        <Box sx={{ flex: 1, height: "1px", backgroundColor: "#ccc" }} />
      </Box>
      {errorMessage && (
        <Box my={2}>
          <Typography
            color="error"
            sx={{ fontSize: "14px", fontWeight: "600" }}
          >
            {errorMessage}
          </Typography>
        </Box>
      )}
      <Button
        fullWidth
        variant="contained"
        onClick={handleLogin}
        sx={{
          backgroundColor: "var(--primary-color) !important",
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          transition: "transform 0.3s ease",
          "&:hover": { transform: "scale(1.05)" },
        }}
      >
        <Box
          component="span"
          sx={{
            width: 27,
            height: 27,
            backgroundColor: "white",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: 1,
          }}
        >
          <img
            src={azure}
            alt="Azure Icon"
            style={{ width: "80%", height: "80%" }}
          />
        </Box>
        Sign in with Microsoft Azure
      </Button>
    </>
  );
};

export default MsalLogin;
