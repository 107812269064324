import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  FormHelperText,
  StepLabel,
  Stepper,
  Dialog,
  DialogContent,
  DialogTitle,
  Step,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import PreviewData from "../components/PreviewData";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import TaskParamsForm from "./TaskParamsForm";
import {
  useGetConnectionOptionsQuery,
  useLazyGetSourceColumnsQuery,
  useLazyGetSourceColumnsForFileQuery,
  useLazyGetPreviewFileQuery,
  useLazyGetPreviewDatabaseQuery,
} from "../state/apiSlice";
import { useFormik } from "formik";
import BulkForm from "./BulkForm";

import * as yup from "yup";
import {toast } from 'react-hot-toast';

import Popup from "components/Popup";
import { selectCurrentUser } from "state/authSlice";
import { useSelector } from "react-redux";
import DQForm from "./DQForm";
import useStyles from "useStyles";
import TaskForm from "./TaskForm";

const findSectionField = (formObject, section, field = null) => {
  const sectionRecord = formObject?.find((rec) => rec.section === section);

  if (field) {
    const fieldRecord = sectionRecord.fields_list.find(
      (rec) => rec.field_id === field
    );

    return fieldRecord;
  }

  return sectionRecord;
};

const StyledTable = styled("table")({
  border: "1px solid black",
  borderCollapse: "collapse",
  width: "100%",
});

const StyledTh = styled("th")(({ theme }) => ({
  border: "1px solid black",
  padding: "8px",
  backgroundColor: theme.palette.grey[200],
}));

const StyledTd = styled("td")({
  border: "1px solid black",
  padding: "8px",
});
const DataQuality = (props) => {
  const { taskFormFields, values: editValue, addOrEditRecord } = props;
  const steps = ["Main", "Source", "Data Quality", "Target", "Review & Save"];

  const currentUser = useSelector(selectCurrentUser);
  const user = currentUser.login_id;

  
  const { data: connectionOptions } = useGetConnectionOptionsQuery(user);

  const mainRecord = findSectionField(taskFormFields, "Main");
  const sourceRecord = findSectionField(taskFormFields, "Source");

  const targetRecord = findSectionField(taskFormFields, "Target");
  const dqRecord = findSectionField(taskFormFields, "DataQuality");

  

  const [getPreviewFile] = useLazyGetPreviewFileQuery();
  const [getPreviewDatabase] = useLazyGetPreviewDatabaseQuery();
  

  function changeConnSubType(type, connSubType) {
    if (type === "source") {
      setCurrSrcConnSubType(connSubType);
      const srcSelectedItem = sourceFormData.filter(
        (item) => item.connection_subtype === connSubType
      );

      setSourceForm(srcSelectedItem[0]);
    }

    if (type === "target") {
      setCurrTgtConnSubType(connSubType);
      const tgtSelectedItem = targetFormData.filter(
        (item) => item.connection_subtype === connSubType
      );

      setTargetForm(tgtSelectedItem[0]);
    }
  }

  const mainForm = mainRecord?.fields_list || [];
  const sourceFormData = sourceRecord?.fields_list || [];
  const targetFormData = targetRecord?.fields_list || [];
  const dqFormData = dqRecord?.fields_list || [];

  const [getSourceColumns] = useLazyGetSourceColumnsQuery();
  const [getSourceColumnsForFile] = useLazyGetSourceColumnsForFileQuery();
  const [currSrcConnSubType, setCurrSrcConnSubType] = useState(null);
  const [currTgtConnSubType, setCurrTgtConnSubType] = useState(null);
  const [sourceForm, setSourceForm] = useState(null);
  const [targetForm, setTargetForm] = useState(null);
  const [dqForm, setDQForm] = useState(null);

  const sourceConnSubTypeList = sourceFormData
    .map((rec) => rec.connection_subtype)
    .filter((rec, index, _arr) => _arr.indexOf(rec) === index);

  

  const [previewInfo, setPreviewInfo] = useState(null);

  const sourceFormFieldIds = sourceForm?.field_list?.map(
    (field) => field.field_id
  );


  const targetFormFieldIds = targetForm?.field_list?.map(
    (field) => field.field_id
  );
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({});

  const [stepFormValues, setStepFormValues] = useState({});
  const [formType, setFormType] = useState(null);
  useEffect(() => {
    if (activeStep === 0) {
      setFormType("main");
    } else if (activeStep === 1) {
      setFormType("source");
    } else if (activeStep === 2) {
      setFormType("dataQuality");
    } else if (activeStep === 3) {
      setFormType("target");
    } else if (activeStep === 4) {
      setFormType("review");
    }
  }, [activeStep]);

  const handleNext = () => {
    setStepFormValues((prevStepFormValues) => {
      const fieldsForStep = getFieldsForStep();

      const currentStepFormValues = prevStepFormValues[activeStep] || {};
      const updatedValues = { ...currentStepFormValues };
      fieldsForStep.forEach((field) => {
        if (field.field_id !== "pipeline_id") {
          updatedValues[field.field_name] = formValues[field.field_name];
        }
      });

      return {
        ...prevStepFormValues,
        [activeStep]: updatedValues,
      };
    });

    setFormValues({});

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    const currentStepFormValues = stepFormValues[activeStep] || {};

    const updatedFormValues = { ...currentStepFormValues };
    const fieldsForStep = getFieldsForStep();
    fieldsForStep.forEach((field) => {
      if (field.field_id !== "pipeline_id") {
        updatedFormValues[field.field_name] = formValues[field.field_name];
      }
    });
    setFormValues(updatedFormValues);

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  let initValues = {};
  if (editValue === null || editValue === undefined) {
    initValues = {
      ...mainForm?.initialvalues,
      ...sourceForm?.initialvalues,
      ...targetForm?.initialvalues,
      ...dqFormData?.initialvalues,
    };
  } else {
    const { details, ...taskRecord } = editValue;
    if (Object.keys(details).length !== 0) {
      const { Source, Target, DataQuality } = details;
      let detailRecord = Object.assign({}, Source, Target, DataQuality);

      initValues = { ...taskRecord, ...detailRecord };
    } else {
      initValues = { ...taskRecord };
    }
  }


  const [errors, setErrors] = useState(null);

  if (sourceFormData === null || targetFormData === null) {
    setErrors("Form data for Main, Source or Target can't be null.");
  }

  const [dqValues, setDQValues] = useState([]);
  const handleSelectionChange = (rows) => {
    // setSelectedRows(rows);
    setDQValues(rows);
    console.log("Selected rows:", JSON.stringify(rows, null, 2));
  };

  const handleFormSubmit = async (values, tasksRecords, resetForm) => {
    const updatedDQValues = dqValues.map(item => ({
      ...item,
      dq_type: "pre_check"
    }));
    const updatedValues = {
      ...values,
      DataQuality: updatedDQValues,
    };
    const mainFormFields = mainForm
      .map((rec) => rec.field_id)
      .filter((rec, index, _arr) => _arr.indexOf(rec) === index);
 
    let taskParameterValues = {};
    let srcParameterType, tgtParameterType;
  
 
    let tblTask = {
      is_active: "Y",
      id: updatedValues?.id || 0,
    };
 
    // Collect task fields for table tbl_task
    mainFormFields.forEach((prop) => (tblTask[prop] = updatedValues[prop]));
 
    // Initialize taskParameterValues based on formType
    if (formType === "source") {
      srcParameterType = currSrcConnSubType;
      tgtParameterType = currTgtConnSubType;
 
      taskParameterValues = {
        ...updatedValues,
        src_parameter_type: srcParameterType,
        tgt_parameter_type: tgtParameterType,
      };
 
      if (sourceFormFieldIds) {
        sourceFormFieldIds.forEach((fieldId) => {
          if (fieldId.startsWith("src_")) {
            taskParameterValues[fieldId] = updatedValues[fieldId];
          }
        });
      }
 
      if (targetFormFieldIds) {
        targetFormFieldIds.forEach((fieldId) => {
          if (fieldId.startsWith("tgt_")) {
            taskParameterValues[fieldId] = updatedValues[fieldId];
          }
        });
      }
    } else if (formType === "target") {
      srcParameterType = updatedValues.src_parameter_type || currSrcConnSubType;
      tgtParameterType = updatedValues.tgt_parameter_type || currTgtConnSubType;
 
      taskParameterValues = {
        ...updatedValues,
        src_parameter_type: srcParameterType,
        tgt_parameter_type: tgtParameterType,
      };
 
      if (sourceFormFieldIds) {
        sourceFormFieldIds.forEach((fieldId) => {
          if (fieldId.startsWith("src_")) {
            taskParameterValues[fieldId] = updatedValues[fieldId];
          }
        });
      }
 
      if (targetFormFieldIds) {
        targetFormFieldIds.forEach((fieldId) => {
          if (fieldId.startsWith("tgt_")) {
            taskParameterValues[fieldId] = updatedValues[fieldId];
          }
        });
      }
    } else if (formType === "dataQuality" || formType === "review") {
      taskParameterValues = { ...updatedValues };
    }
 
 
    // Build task details
    const taskDetails = [];
 
    // Handle src_ and tgt_ fields
    Object.keys(taskParameterValues).forEach((key, index) => {
      let parameter_type = null;
 
      // Skip individual processing of DataQuality fields
      if (key.startsWith("dq_") || key === "DataQuality") {
        // Skip individual DataQuality fields
        return;
      }
 
      if (key.startsWith("src_")) {
        parameter_type = srcParameterType || currSrcConnSubType;
      } else if (key.startsWith("tgt_")) {
        parameter_type = tgtParameterType || currTgtConnSubType;
      }
 
      // Process non-DataQuality fields
      if (key !== "DataQuality") {
        let taskType = key.includes("src_")
          ? "Source"
          : key.includes("tgt_")
          ? "Target"
          : null;
 
        if (taskType) {
          taskDetails.push({
            task_type: taskType,
            parameter_type,
            key_01: key.replace("src_", "").replace("tgt_", ""),
            value_01: taskParameterValues[key],
            sequence: index + 1,
            is_active: "Y",
          });
        }
      }
    });
 
    // Handle DataQuality fields separately to avoid duplication
    if (updatedValues?.DataQuality) {
      updatedValues.DataQuality.forEach((dqField, index) => {
        Object.keys(dqField).forEach((key) => {
          // Remove 'dq_' prefix from the key if it starts with it
          const cleanedKey = key.startsWith("dq_") ? key.substring(3) : key;
 
          taskDetails.push({
            task_type: "DataQuality",
            parameter_type: dqField.dq_idx, // Use dq_idx as parameter_type
            key_01: cleanedKey, // Use cleaned key without 'dq_' prefix
            value_01: dqField[key],
            sequence: taskDetails.length + 1, // Keep sequence continuous
            is_active: "Y",
          });
        });
      });
    }
 
    let taskRecord = { ...tblTask, details: taskDetails };
 
    try {
      await addOrEditRecord(taskRecord, resetForm); // Ensure you await async function
 
      if (taskRecord?.id === 0) {
        toast.success(`Task Created Successfully`);
      } else {
        toast.success(`Task Updated Successfully`);
      }
    } catch (error) {
      console.error("Form Submission Error: ", error);
      toast.error("Form Submission Error");
    }
  };
 

  const formik = useFormik({
    initialValues: initValues,
    onSubmit: (values, { resetForm }) => {
      handleFormSubmit(values, resetForm);
    },
  });

  const srcSourceOption = formik?.values?.source_type;
  const tgtTargetOption = formik?.values?.target_type;
 
  useEffect(() => {
    const selectedConnection = connectionOptions?.find(
      (option) => option?.connection_type === srcSourceOption
    );

    if (selectedConnection) {
      const selectedConnectionList = selectedConnection.connection_list;
    } else {
      console.log("No matching connection type found for source_type");
    }
  }, [srcSourceOption, connectionOptions]);

  useEffect(() => {
    const selectedConnection = connectionOptions?.find(
      (option) => option?.connection_type === tgtTargetOption
    );

    if (selectedConnection) {
      const selectedConnectionList = selectedConnection.connection_list;
    } else {
      console.log("No matching connection type found for target_type");
    }
  }, [tgtTargetOption, connectionOptions]);

 

  function validateFormSchema(formData) {
    let validSchemaObject = {};
    formData?.forEach(({ field_id, required }) => {
      if (required === "Y") {
        validSchemaObject[field_id] = yup
          .string()
          .required(`${field_id} is required`);
      }
      if (field_id === "task_name") {
        validSchemaObject[field_id] = validSchemaObject[field_id]?.matches(
          /^[a-zA-Z0-9_]+$/,
          `${field_id} must be alphanumeric and may contain underscores only`
        );
      }
    });
    return yup.object().shape(validSchemaObject);
  }
  const getFieldsForStep = () => {
    switch (activeStep) {
      case 0:
        return mainForm;
      case 1:
        return sourceFormData;

      case 2:
        return targetFormData;

      default:
        return [];
    }
  };

  function validateSchemaObject(section) {
    let validationObject = {};
    switch (section) {
      case "main":
        if (mainForm != null) {
          validationObject[section] = validateFormSchema(mainForm);
        }
        break;

      case "source":
        if (sourceForm != null) {
          validationObject[section] = validateFormSchema(
            sourceForm?.field_list
          );
        }
        break;
      case "target":
        if (targetForm != null) {
          validationObject[section] = validateFormSchema(
            targetForm?.field_list
          );
        }
        break;
      case "dataQuality":
        if (dqFormData != null) {
          validationObject[section] = validateFormSchema(
            dqFormData[0]?.field_list
          );
        }
        break;
      default:
        break;
    }

    return validationObject;
  }

  if (formType === "source" && currSrcConnSubType === null) {
    if (editValue && editValue.source !== "none") {
      changeConnSubType("source", editValue.source);
    } else {
      changeConnSubType("source", sourceFormData[0].connection_subtype);
    }
  }

  if (formType === "target" && currTgtConnSubType === null) {
    if (editValue && editValue.target !== "none") {
      changeConnSubType("target", editValue.target);
    } else {
      changeConnSubType("target", targetFormData[0].connection_subtype);
    }
  }
  function handleSettingUpInitialValues(updatedInitialValues) {
    try {
      formik.setValues(updatedInitialValues);
    } catch (error) {
      console.log("Error while setting up values", error);
    }
  }
  const formTask = (formikProps) => {
    return (
      <BulkForm
        taskProps={mainRecord}
        formProps={formikProps}
        object={"Task"}
      />
    );
  };


  const formSourceTask = (formikProps, sourceConnType) => {
    return (
      <TaskParamsForm
        taskProps={{
          connSubTypeList: sourceConnSubTypeList,
          currSrcConnSubType: sourceConnType,
          fieldList: sourceForm?.field_list,
        }}
        formProps={formikProps}
        handleConnSubTypeChange={handleSourceConnSubTypeChange}
        getValuesForFormikSet={handleSettingUpInitialValues}
        object={"Source"}
      />
    );
  };

  const formTargetTask = (formikProps, targetConnType) => {
    return (
      <TaskParamsForm
        taskProps={{
       
          fieldList: targetForm?.field_list,
        }}
        formProps={formikProps}
        handleConnSubTypeChange={handleTargetConnSubTypeChange}
        getValuesForFormikSet={handleSettingUpInitialValues}
        object={"Target"}
      />
    );
  };
  const formDQTask = (formikProps) => {
    return (
      <DQForm
        taskProps={{
          fieldList: dqFormData[0].field_list,
        }}
        dqValues={dqValues}
        formProps={formikProps}
        onSelectionChange={handleSelectionChange}
        object={"DataQuality"}
      />
    );
  };

  let formComponent = null;
  if (formType === "main") {
    formComponent = formTask(formik);
  } else if (formType === "source") {
    formComponent = formSourceTask(formik, currSrcConnSubType);
  } else if (formType === "target") {
    formComponent = formTargetTask(formik);
  } else if (formType === "dataQuality") {
    formComponent = formDQTask(formik);
  }

  function handleSourceConnSubTypeChange(subTypeValue) {
    changeConnSubType("source", subTypeValue);
  }

  function handleTargetConnSubTypeChange(subTypeValue) {
    changeConnSubType("target", subTypeValue);
  }

  let dataGroup = [];

  const numericKeys = Object.keys(initValues)
    .filter((key) => typeof initValues[key] === "object")
    .map((key) => parseInt(key))
    .filter((key) => !isNaN(key));

  const minGroup = Math.min(...numericKeys);

  const maxGroup = Math.max(...numericKeys);

  for (let i = minGroup; i <= maxGroup; i++) {
    if (
      initValues[i] &&
      typeof initValues[i].source === "object" &&
      initValues[i].source !== null
    ) {
      const id = `${initValues[i].source.src_schema_name}_${initValues[i].source.src_object_name}`;
      dataGroup.push({
        ...initValues[i].source,
        id: id,
      });
    } else {
      console.error(
        `initValues[${i}] or initValues[${i}].source is undefined or not an object.`
      );
    }
  }

  const [groupSelect, setGroupSelect] = useState(dataGroup);

  let dataGrouptgt = [];
  for (let i = minGroup; i <= maxGroup; i++) {
    if (
      initValues[i] &&
      typeof initValues[i].target === "object" &&
      initValues[i].target !== null
    ) {
      const id = `${initValues[i].target.tgt_schema_name}_${initValues[i].target.tgt_object_name}`;
      const src_object_name = `${initValues[i].source.src_object_name}`;

      dataGrouptgt.push({
        ...initValues[i].target,
        id: id,
        src_object_name: src_object_name,
      });
    } else {
      console.error(
        `initValues[${i}] or initValues[${i}].target is undefined or not an object.`
      );
    }
  }

 

 



  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  let isButtonEnabled = false;
  let val = formik.values;

  if (formType === "source") {
    if (currSrcConnSubType === "REST API") {
      isButtonEnabled = !!val?.src_content_type && !!val?.src_connection_name;
    } else {
      isButtonEnabled =
        (!!val?.src_file_name &&
          !!val?.src_file_path &&
          !!val?.src_connection_name) ||
        (!!val?.src_schema &&
          !!val?.src_table_name &&
          !!val?.src_connection_name);
    }
  } else {
    if (currTgtConnSubType === "REST API") {
      isButtonEnabled = !!val?.tgt_content_type && !!val?.tgt_connection_name;
    } else {
      isButtonEnabled =
        (!!val?.tgt_table_name &&
          !!val?.tgt_schema &&
          !!val?.tgt_connection_name) ||
        (!!val?.tgt_file_name &&
          !!val?.tgt_file_path &&
          !!val?.tgt_connection_name);
    }
  }

  const [options, setOptions] = useState([]);
  const [actualoptions, setActualOptions] = useState([]);
  const handlePreview = async () => {
    let data = {};
    let filetype;
    let connectionType;
    try {
      setLoading(true);
      if (formType === "source") {
        connectionType = currSrcConnSubType.toLowerCase().replace(/\s/g, "");
        filetype = formik.values.src_file_type;
        if (
          connectionType === "remoteserver" ||
          connectionType === "awss3" ||
          connectionType === "localserver"
        ) {
          data = {
            connection_id: formik.values.src_connection_name,
            table_name: formik.values.src_file_name,
            filepath: formik.values.src_file_path,
            header: formik.values.src_header,
            file_type: formik.values.src_file_type,
            delimiter: formik.values.src_delimiter,
            encoding: formik.values.src_encoding,
          };
        } else {
          data = {
            connection_id: formik.values.src_connection_name,
            table_name: formik.values.src_table_name,
            schema_name: formik.values.src_schema,
            header: formik.values.src_select_columns,
            query: formik.values.src_query_params,
            content_type: formik.values.src_content_type,
            src_query: formik.values.src_query,
          };
        }
      }

      if (formType === "target") {
        connectionType = currTgtConnSubType.toLowerCase().replace(/\s/g, "");
        filetype = formik.values.tgt_file_type;
        if (
          connectionType === "awss3" ||
          connectionType === "localserver" ||
          connectionType === "remoteserver"
        ) {
          data = {
            connection_id: formik.values.tgt_connection_name,
            table_name: formik.values.tgt_file_name,
            filepath: formik.values.tgt_file_path,
            header: formik.values.tgt_header,
            file_type: formik.values.tgt_file_type,
            delimiter: formik.values.tgt_delimiter,
            encoding: formik.values.tgt_encoding,
          };
        } else {
          data = {
            connection_id: formik.values.tgt_connection_name,
            table_name: formik.values.tgt_table_name,
            schema_name: formik.values.tgt_schema,
            content_type: formik.values.tgt_content_type,
          };
        }
      }

      let response;

      if (
        connectionType === "awss3" ||
        connectionType === "localserver" ||
        connectionType === "remoteserver"
      ) {
        response = await getPreviewFile({
          connectionType,
          filetype,
          params: data,
        });
      } else {
        response = await getPreviewDatabase({ connectionType, params: data });
      }

      if (response.isSuccess) {
        setPreviewInfo(response.data);
        setOpen(true);
        setError(null); // Clear any previous errors
      } else {
        throw new Error(response.error.data.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Set loading state back to false after fetching preview
    }
  };
  const handleChange = async () => {
    if (formType === "source") {
      const srcconnectionType = currSrcConnSubType
        .toLowerCase()
        .replace(/\s/g, "");

      let data;
      if (
        srcconnectionType === "awss3" ||
        srcconnectionType === "localserver" ||
        srcconnectionType === "remoteserver"
      ) {
        const response = await getSourceColumnsForFile({
          srcconnectionType,
          src_connection_name: formik.values.src_connection_name,
          src_file_type: formik.values.src_file_type,
          src_file_name: formik.values.src_file_name,
          src_file_path: formik.values.src_file_path,
          src_delimiter: formik.values.src_delimiter,
          src_encoding: formik.values.src_encoding,
        });
        data = response.data;
      } else {
        const response = await getSourceColumns({
          srcconnectionType,
          src_connection_name: formik.values.src_connection_name,
          src_table_name: formik.values.src_table_name,
          src_schema_name: formik.values.src_schema,
        });
        data = response.data;
      }

      if (data) {
        const aliasColumnsObject = data.metaDataInfo.map(
          (item) => item.aliasColumns
        );
        const aliasColumnsArray = Object.values(aliasColumnsObject);
        const actualColumnsObject = data.metaDataInfo.map(
          (item) => item.actualColumns
        );
        const actualColumnsArray = Object.values(actualColumnsObject);
        setOptions(aliasColumnsArray);
        setActualOptions(actualColumnsArray);
        assignOptionList("src_select_columns", aliasColumnsArray);
        const aliasColumnsString = aliasColumnsArray.join(",");

        formik.values.src_alias_columns = aliasColumnsString;
      }
    }
  };

  const assignOptionList = (fieldId, options) => {
    if (sourceForm && Array.isArray(sourceForm.field_list)) {
      const updatedFieldList = sourceForm.field_list.map((field) => {
        if (
          field.field_id === fieldId &&
          (field.field_type === "multiselect" || field.option_list === null)
        ) {
          return { ...field, option_list: options };
        }
        return field;
      });

      setSourceForm({ ...sourceForm, field_list: updatedFieldList });
    }
  };

  const assignOptionList1 = (fieldId, actualoptions, options) => {
    if (sourceForm && Array.isArray(sourceForm.field_list)) {
      const updatedFieldList1 = sourceForm.field_list.map((field) => {
        if (field.field_id === "src_query") {
          if (formik.values.src_table_name) {
            if (formik.values.src_select_columns) {
              // Split the columns into an array
              const selectedColumns =
                formik.values.src_select_columns.split(",");
              // Create a mapping object for aliases to actual columns
              const aliasToActualMap = {};
              options.forEach((alias, index) => {
                const actualCol = actualoptions[index];
                aliasToActualMap[alias] = actualCol;
              });
              const formattedColumns = selectedColumns.map((selectedColumn) => {
                const actualCol =
                  aliasToActualMap[selectedColumn] || selectedColumn;
                if (actualCol === selectedColumn) {
                  return actualCol;
                } else {
                  return `${actualCol} AS ${selectedColumn.replace(/`/g, "")}`;
                }
              });
              if (initValues.src_query === undefined) {
                formik.values.src_query = `SELECT ${formattedColumns.join(
                  ", "
                )} FROM ${formik.values.src_schema}.${
                  formik.values.src_table_name
                }`;
              } else {
                formik.values.src_query = initValues.src_query;
                if (
                  formik.values.src_select_columns !==
                  initValues.src_select_columns
                ) {
                  formik.values.src_query = `SELECT ${formattedColumns.join(
                    ", "
                  )} FROM ${formik.values.src_schema}.${
                    formik.values.src_table_name
                  }`;
                }
              }
            } else {
              formik.values.src_query = `SELECT * FROM ${formik.values.src_schema}.${formik.values.src_table_name}`;
            }
          } else {
            formik.values.src_query = "";
          }
        }
        return field;
      });

      setSourceForm({ ...sourceForm, field_list: updatedFieldList1 });
    }
  };

  useEffect(() => {
    if (formik.values && formik.values.src_connection_name) {
      handleChange();
    }
  }, [formik.values, formik.values.src_connection_name, URL]);

  useEffect(() => {
    if (formik.values.src_table_name || formik.values.src_select_columns) {
      assignOptionList1("src_select_columns", actualoptions, options);
    }
  }, [formik.values.src_table_name, formik.values.src_select_columns]);
  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  
  const data = editValue?.details?.DataQuality?.DataQuality || [];
  const styles = useStyles();
  return (
    <>
      {errors ? (
        <Box display="flex" flexDirection="row" sx={{ m: "3rem 1.5rem" }}>
          <Typography variant="h4">{errors}</Typography>
        </Box>
      ) : (
        <Box sx={{ padding: 2 }}>
          <Grid container>
            <div style={{ width: "100%" }}>
              <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label, index) => (
                  <Step key={label}>
                    <StepLabel
                      StepIconComponent={(props) => {
                        const isActive = props.active;
                        const isCompleted = props.completed;
                        const bgColor = isActive
                          ? "var(--primary-color)" // Blue when active
                          : isCompleted
                          ? "#4caf50" // Green when completed
                          : "var(--secondary-color)"; // Light grey when inactive

                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: 30, // Increase size of the circle
                              height: 30, // Increase size of the circle
                              borderRadius: "50%",
                              backgroundColor: bgColor,
                              color: "#fff", // White text color
                            }}
                          >
                            {isActive ? (
                              <RadioButtonUnchecked />
                            ) : isCompleted ? (
                              <CheckCircle />
                            ) : (
                              <Typography variant="body2" fontSize={16}>
                                {index + 1}
                              </Typography>
                            )}
                          </div>
                        );
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{ fontFamily: "Poppins" }}
                      >
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>

            <Grid container>
              <Grid item xs={12} sx={{ padding: "20px" }}>
                {formComponent}
              </Grid>

              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error>{formik.errors.submit}</FormHelperText>
                </Grid>
              )}

              <Grid item xs={12}>
             

                {formType === "review" ? (
                  <div>
                    <h3>Summary</h3>
                    <p>
                      Please review the below and Save.
                      <br />
                      Click on Back to make changes
                    </p>
                    <h4>Source: {formik?.values?.src_table_name}</h4>
                    <h4>No. of DQ Checks: {dqValues?.length}</h4>
                    <h4>
                      Generate file for Bad Records:{" "}
                      {formik?.values?.tgt_generate_bad_record}
                    </h4>

                    <StyledTable>
                      <thead>
                        <tr>
                          <StyledTh>Check Name</StyledTh>
                          <StyledTh>Column Name</StyledTh>
                        </tr>
                      </thead>
                      <tbody>
                        {dqValues.map((row, index) => (
                          <tr key={index}>
                            <StyledTd>{row.dq_check}</StyledTd>

                            <StyledTd>{row.dq_column}</StyledTd>
                          </tr>
                        ))}
                      </tbody>
                    </StyledTable>
                  </div>
                ) : null}

                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="end"
                    sx={{ flexDirection: "row", gap: "16px", mt: "15px" }}
                  >
                    {formType === "source" ? (
                      <Button
                        variant="contained"
                        onClick={handlePreview}
                        style={{
                          backgroundColor: loading
                            ? "var(--fifth-color) !important"
                            : isButtonEnabled
                            ? "var(--primary-color) !important"
                            : "var(--secondary-color) !important",
                          position: "relative",
                          overflow: "hidden",
                          fontFamily: "Poppins",
                          padding: "4px 16px", // Adjust padding to match the height of the TextField
                          height: "35px", // Ensure consistent height
                          fontSize: "13px",
                          fontWeight: "600",
                          borderRadius: "3px",
                        }}
                        disabled={!isButtonEnabled || loading}
                      >
                        {loading ? (
                          <span
                            style={{ display: "inline-block", color: "white" }}
                          >
                            PREVIEWING
                            <span
                              style={{
                                position: "absolute",
                                overflow: "hidden",
                                width: "0",
                                animation:
                                  "ellipsis 35s steps(4, start) infinite",
                              }}
                            >
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                              <span>.</span>
                            </span>
                          </span>
                        ) : (
                          <span style={{ color: "white" }}>PREVIEW</span>
                        )}
                      </Button>
                    ) : null}
                        <style>
                {`
        @keyframes ellipsis {
          0% { width: 3%; }
          20% { width: 10%; }
          40% { width: 20%; }
          60% { width: 35%; }
          80% { width: 45%; }
          100% { width: 55%; }
        }
      `}
              </style>
                    <Button
                      type="submit"
                      variant="contained"
                      onClick={() => {
                        formik.handleSubmit();
                      }}
                      sx={styles.myBackground}
                    >
                      Save
                    </Button>

                    {formType !== "main" && (
                      <Button
                        onClick={handleBack}
                        disabled={activeStep === 0}
                        sx={styles.myBackground}
                      >
                        Back
                      </Button>
                    )}
                    {formType !== "review" && (
                      <Button
                        onClick={handleNext}
                        disabled={activeStep === steps.length - 1}
                        sx={styles.myBackground}
                      >
                        {activeStep === steps.length - 1 ? "Finish" : "Next"}
                      </Button>
                    )}

                    {error && (
                      <div
                        style={{
                          backgroundColor: "rgb(7, 0, 76)",
                          color: "white",
                          padding: "10px",
                          borderRadius: "10px",
                          marginLeft: "-1000px",
                          display: "flex",
                          flexDirection: "column",
                          position: "relative",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <div style={{ marginRight: "10px" }}>⚠️</div>{" "}
                            <div style={{ fontWeight: "bold" }}>
                              INFORMATION
                            </div>{" "}
                          </div>
                          <IconButton
                            style={{
                              position: "absolute",
                              bottom: "18px",
                              right: "5px",
                            }}
                            onClick={handleClose}
                            color="inherit"
                          >
                            <CloseIcon style={{ fontSize: "20px" }} />
                          </IconButton>
                        </div>
                        <div style={{ flex: "1", marginTop: "3px" }}>
                          {error}
                        </div>{" "}
                      </div>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                marginTop: "75px",
                minWidth: "90%",
                height: "90vh",
                maxWidth: "none",
                maxHeight: "none",
                resize: true,
              },
            }}
          >
            <DialogTitle
              style={{
          
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "var(--primary-color)",
                color: "white",
                fontFamily: "Poppins, sans-serif",
                fontWeight: "500",
                height:"45px",
                fontSize: "18px"
              }}
            >
              {formType === "source" ? (
                <>
                  {(() => {
                    const connectionType = currSrcConnSubType
                      ?.toLowerCase()
                      .replace(/\s/g, "");
                    return connectionType === "awss3" ||
                      connectionType === "localserver" ||
                      connectionType === "restapi" ||
                      connectionType === "remoteserver" ? (
                      <>
                        {" "}
                        <center></center>
                        PREVIEWING :{" "}
                        {formik?.values?.src_file_name || "REST API"}
                      </>
                    ) : (
                      <>
                        {" "}
                      
                        PREVIEWING DATABASE TABLES :{" "}
                        {formik?.values?.src_table_name}
                      </>
                    );
                  })()}
                </>
              ) : null}

              {formType === "target" ? (
                <>
                  {(() => {
                    const connectionType = currTgtConnSubType
                      ?.toLowerCase()
                      .replace(/\s/g, "");
                    return connectionType === "awss3" ||
                      connectionType === "localserver" ||
                      connectionType === "restapi" ||
                      connectionType === "remoteserver" ? (
                      <>
                        {" "}
                        <center></center>
                        PREVIEWING :{" "}
                        {formik?.values?.tgt_file_name || "REST API"}
                      </>
                    ) : (
                      <>
                        {" "}
                        <center></center>
                        PREVIEWING DATABASE TABLES :{" "}
                        {formik?.values?.tgt_table_name}
                      </>
                    );
                  })()}
                </>
              ) : null}

              <IconButton onClick={handleClose} color="inherit">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <PreviewData
                handleClose={handlePreview}
                previewInfo={previewInfo}
              />
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </>
  );
};

export default DataQuality;
