import React from "react";
import {
  Autocomplete,
  FormControl,
  TextField,
  FormHelperText,
} from "@mui/material";

const AutoCompleteSelect = (props) => {
  const {
    field_id,
    display_name,
    options,
    handleBlur,
    handleChange,
    touched,
    errors,
    values,
  } = props;

  return (
    <FormControl
      sx={{
        gridColumn: "span 4",
        width: "360px!important",
        marginLeft: "12px",
        minHeight: "10px",
      }}
      error={!!touched[field_id] && !!errors[field_id]}
    >
      <Autocomplete
        disablePortal
        disableClearable
        id={`${field_id}-autocomplete`}
        options={Array.isArray(options) ? options : []}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        value={
          options.find((option) => option.id === values[field_id]) || null
        }
        onChange={(event, newValue) => {
          handleChange({
            target: {
              name: field_id,
              value: newValue ? newValue.id : "",
            },
          });
        }}
        onBlur={handleBlur}
        renderInput={(params) => (
          <TextField
            {...params}
            label={display_name}
            error={!!touched[field_id] && !!errors[field_id]}
            InputLabelProps={{
              shrink: true,
              style: { color: "black" },
            }}
          />
        )}
      />
      {touched[field_id] && errors[field_id] && (
        <FormHelperText>{errors[field_id]}</FormHelperText>
      )}
    </FormControl>
  );
};

export default AutoCompleteSelect;
