import React, { useRef, useEffect } from "react";
import { TextField } from "@mui/material";

const TextAreaComponent = ({
  field_id,
  display_name,
  field_type,
  handleBlur,
  handleChange,
  touched,
  errors,
  values,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    // Update the input value only if it differs from the current value
    if (inputRef.current && inputRef.current.value !== values[field_id]) {
      inputRef.current.value = values[field_id] || "";
    }
  }, [values, field_id]);

  const handleInputChange = (event) => {
    const { value } = event.target;
    handleChange({
      target: {
        name: field_id,
        value,
      },
    });
  };

  return (
    <TextField
      fullWidth
      multiline
      rows={5}
      variant="outlined"
      type={field_type}
      label={display_name}
      sx={{
        gridColumn: "span 4",
        width: "360px!important",
        marginLeft: "12px",
        minHeight: "10px",
        fontFamily: 'Poppins, sans-serif',
        '&.MuiInputLabel-shrink': {
          fontSize: '16px', // Adjust this value as needed for the larger font size when shrunk
          backgroundColor: 'white', // Adds white background behind the label
          padding: '0 4px', // Optional: Adds some padding for better appearance
          marginLeft: '-4px', // Optional: Adjusts margin to avoid shifting the label
        },
      }}
    
     
      InputLabelProps={{
        style: { color: "black", fontSize: "18px" },
        shrink: true, // Force label to shrink always
      }}
      onBlur={handleBlur}
      onChange={handleInputChange}
      defaultValue={values[field_id] || ""}
      name={field_id}
      error={!!touched[field_id] && !!errors[field_id]}
      helperText={touched[field_id] && errors[field_id]}
      inputRef={inputRef}
    />
  );
};

// Wrap the component with React.memo to avoid unnecessary re-renders
const TextArea = React.memo(TextAreaComponent);

export default TextArea;
