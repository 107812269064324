import React from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useChangePasswordMutation } from "../state/apiSlice";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  TextField,
  Box,
  Stack,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useTheme,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import {toast } from 'react-hot-toast';
import useStyles from "useStyles";

const ChangePassword = () => {
  const styles = useStyles();
  const location = useLocation();
  const navigate = useNavigate();  // for navigation
  const theme = useTheme();
  const login_id = location.pathname.split("/")[2].slice(0);

  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const [errorMessage, setErrorMessage] = React.useState("");

  const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old password is required"),
    newPassword: Yup.string().required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handleSubmit = (values, { resetForm }) => {
    changePassword({ login_id, ...values })
      .unwrap()
      .then(() => {
        resetForm();
        setErrorMessage("");
        toast.success("Password updated successfully");

        // Close the popup after 3 seconds
        setTimeout(() => {
          navigate(-1);  // Navigate back (equivalent to the Back button functionality)
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error occurred while updating password");
        setErrorMessage(
          error.response?.data?.message || "Error occurred while updating password."
        );
      });
  };

  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle
        sx={{
          backgroundColor: "var(--primary-color)",
          color: "white",
          fontFamily: "Poppins, sans-serif",
          fontWeight: "500",
        }}
      >
        <Typography
          variant="h6"
          component="div"
          sx={{
            margin: 0,
            fontFamily: "Poppins, sans-serif",
            fontSize: "17px!important",
            fontWeight: "500 !important",
            lineHeight: "1.6!important",
            color: "white"
          }}
        >
          Change Password
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => navigate(-1)}  // This will navigate back (same as the Back button)
          sx={{
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched, handleChange, handleBlur, values }) => (
            <Form>
              <Stack spacing={2} marginTop={2}>
                <Box>
                  <TextField
                    label="Old Password"
                    name="oldPassword"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={values.oldPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.oldPassword && Boolean(errors.oldPassword)}
                    helperText={touched.oldPassword && errors.oldPassword}
                    InputLabelProps={{
                      style: {
                        color: "black",
                        fontSize: "15px",
                        fontFamily: "Poppins",
                      },
                    }}
                  />
                </Box>

                <Box>
                  <TextField
                    label="New Password"
                    name="newPassword"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={values.newPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.newPassword && Boolean(errors.newPassword)}
                    helperText={touched.newPassword && errors.newPassword}
                    InputLabelProps={{
                      style: {
                        color: "black",
                        fontSize: "15px",
                        fontFamily: "Poppins",
                      },
                    }}
                  />
                </Box>

                <Box>
                  <TextField
                    label="Confirm Password"
                    name="confirmPassword"
                    type="password"
                    variant="outlined"
                    fullWidth
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                    helperText={touched.confirmPassword && errors.confirmPassword}
                    InputLabelProps={{
                      style: {
                        color: "black",
                        fontSize: "15px",
                        fontFamily: "Poppins",
                      },
                    }}
                  />
                </Box>
              </Stack>
              {errorMessage && (
                <Box
                  display="flex"
                  justifyContent="end"
                  marginTop={2}
                  style={styles.errorMsg}
                >
                  {errorMessage}
                </Box>
              )}
              <DialogActions style={{ marginTop: "20px" }}>
                <Button
                  type="submit"
                  disabled={isLoading}
                  style={styles.myBackground}
                >
                  {isLoading ? "Loading..." : "Change Password"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ChangePassword;
