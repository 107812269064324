import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "state/authSlice";
import Breadcrumbs from "components/elements/Breadcrumbs";
import ReactMarkdown from 'react-markdown';
import { Alert, AlertTitle, ToggleButton, ToggleButtonGroup, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ModeEditOutlineTwoToneIcon from '@mui/icons-material/ModeEditOutlineTwoTone';
import EditOffTwoToneIcon from '@mui/icons-material/EditOffTwoTone';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  useMediaQuery,
  Button,
  TextField, 
  useTheme,
} from "@mui/material";
import { ModeEditOutline } from "@mui/icons-material";
import FlexBetween from "../components/FlexBetween";
import {
  useGetProjectsQuery,
  useGetObjectFormQuery,
  useAddObjectMutation,
  useUpdateObjectMutation,
} from "../state/apiSlice";
import Header from "../components/Header";
import { Link, useParams, useNavigate } from "react-router-dom";
import Popup from "../components/Popup";
import ObjectForm from "../pages/ObjectForm";
import { useLocation } from 'react-router-dom';
import useStyles from "useStyles";
import Loading from "components/elements/Loading";

const Projects = () => {
  const styles = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const user = currentUser.login_id
  const theme = useTheme();
  const { id } = useParams();
  const location = useLocation();
  const { itemData } = location.state || {};
  const [openPopupHelp, setOpenPopupHelp] = useState(false);
  const closeModal = () => {
    setOpenPopupHelp(false);
  };
  const [markdownContent, setMarkdownContent] = useState('');

  useEffect(() => {
    fetch('/docs/project/project.md')
      .then(response => response.text())
      .then(data => setMarkdownContent(data))
      .catch(error => console.error('Error fetching project.md:', error));
  }, []);
  const searchParams = new URLSearchParams(location.search);
  const programName = searchParams.get('program_name');
  console.log("Id:", id);
  const isNonMobile = useMediaQuery("(min-width: 1000px)");
  const { data: projectRecords, isLoading: isRecordLoading, error: recordError } =
    useGetProjectsQuery({ id, user });
  const { data: formData, isLoading: isFormLoading, error: formError } =
    useGetObjectFormQuery({
      name: "Project",
      user: user,
    });
  const [addObject] = useAddObjectMutation();
  const [updateObject] = useUpdateObjectMutation();

  const [initValue, setInitValue] = useState(null);
  const [fieldsList, setFieldList] = useState(null);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);

  const [searchQuery, setSearchQuery] = useState(''); 

  if (!isFormLoading && initValue === null && !isRecordLoading) {
    if (formError) {
      console.error("Error fetching form data:", formError);
    } else {
      setInitValue(formData[0]?.initialvalues);
      setFieldList(formData[0]?.field_list);
    }
  }

  const addOrEdit = async (values, resetForm) => {
    if (values?.id === 0) {
      await addObject({ object: "project", data: values, user: user });
    } else {
      await updateObject({ object: "project", data: values, user: user });
    }

    const delayMilliseconds = 3000; // 3 seconds
    await new Promise(resolve => setTimeout(resolve, delayMilliseconds));
    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);
  };

  let pageTitle = "Projects";
  let pageSubTitle = "View/Manage Project";
  if (typeof id !== "undefined") {
    pageTitle = "Projects";
    pageSubTitle = "See your list of Project.";
  }

  const handleToggle = (event, newToggle) => {
    if (newToggle !== null) {
      setToggle(newToggle);
    }
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };


  const navigate = useNavigate();

  const handleClick = async (item, itemData) => {
    navigate(`/pipelines/${item.id}`, { state: { itemProjectData: item, itemProgramData: itemData } });
  };

  const breadcrumbPaths = [
    { path: '/dashboard', label: 'Home' },
  ];

  if (itemData?.program_name !== undefined) {
    breadcrumbPaths.push({ path: '/programs', label: `Programs(${itemData.program_name})` });
  }

  breadcrumbPaths.push({ path: '/projects', label: 'Projects' });

  const [toggle, setToggle] = useState(true);

  const filteredProjects = projectRecords?.filter((item) =>
    item.project_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Box m="1.5rem 2.5rem">
        <Header title={pageTitle} />

        <Breadcrumbs breadcrumbPaths={breadcrumbPaths} style={{ color: 'blue' }} />

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Header subtitle={pageSubTitle} />
          <Box display="flex" alignItems="center" gap={2}>
            <TextField
              variant="outlined"
              placeholder="Search By Project Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              size="small"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "var(--primary-color) !important", // default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--primary-color) !important", // border color on hover
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--primary-color) !important", // border color when focused
                  },
                  height: "35px", // Match the height of buttons
                },
              }}
            />
            <ToggleButtonGroup
              value={toggle}
              exclusive
              onChange={handleToggle}
              aria-label="Toggle between Active and All"
              sx={{
                borderRadius: "80px",

              }}
            >
              <ToggleButton
                value={true}
                aria-label="All"
                sx={{
                  ...styles.myBackground,

                  backgroundColor: !toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  color: !toggle ? "#ffffff" : styles.myBackground.color,
                  '&:hover': {
                    backgroundColor: !toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  },
                }}
              >
                All
              </ToggleButton>
              <ToggleButton
                value={false}
                aria-label="Active"
                sx={{
                  ...styles.myBackground,
                  backgroundColor: toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                  color: toggle ? "#ffffff" : styles.myBackground.color, // Text color changes based on toggle state
                  '&:hover': {
                    backgroundColor: toggle ? "var(--secondary-color) !important" : styles.myBackground.backgroundColor,
                    color: toggle ? "#ffffff" : styles.myBackground.color, // Ensure hover color is consistent with the toggle state
                  },
                }}
                disabled={!toggle}
              >
                Active
              </ToggleButton>
            </ToggleButtonGroup>
            <Button
              onClick={() => {
                setOpenPopup(true);
                setRecordForEdit({
                  ...initValue,        
                  program_id: itemData?.id
              });
              }}
              sx={{
                ...styles.myBackground,

              }}
            >
              Create
            </Button>

            <Button
              onClick={() => {
                setOpenPopupHelp(true);
              }}
              sx={{
                ...styles.myBackgroundToggle,
              }}
            >
              Help
            </Button>
          </Box>


        </Box>

      </Box>

      <Box m="1.5rem 2.5rem">
        {filteredProjects?.length === 0 && !isRecordLoading ? (
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            justifyContent="space-between"
            rowGap="20px"
            columnGap="1.33%"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
            <Typography variant="h5">
              No Project belongs to Program Id: {id}
            </Typography>
          </Box>
        ) : (
          <></>
        )}
        {recordError ? (
          <Alert severity="error" style={{ width: '100%', height: '100vh', margin: 'auto' }}>
            <AlertTitle><strong>Error</strong></AlertTitle>
            <Typography style={{ fontWeight: 'bold' }}>
              {recordError?.data?.message}
            </Typography>
          </Alert>

        ) : isRecordLoading ? (
          <Loading />
        ) : filteredProjects ? (
          <Box
            mt="20px"
            display="grid"
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
            justifyContent="space-between"
            rowGap="20px"
            columnGap="1.33%"
            sx={{
              "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
            }}
          >
           {filteredProjects
              .filter((item) => (toggle ? item.is_active === 'Y' || item.is_active === 'N' : item.is_active === 'Y'))
              .map((item) => {
                const isDisabled = item.is_active === 'N';
                return (
                  <Card
                    key={item.id}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      boxShadow: "4px 5px 6px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(1 0 0 / 14%), 4px 2px 1px 1px rgb(0 0 0 / 12%) !important",
                      background: "var(--card-bgcolor) !important",
                      borderRadius: "0.55rem",
                    
                      transition: 'transform 0.3s ease-in-out',
                      borderLeft: "6px solid var(--primary-color) !important",

                      '&:hover': {
                        transform: 'scale(1.05)',
                      },
                      ...(isDisabled && {
                        opacity: 0.5,
                        pointerEvents: 'none',
                        backgroundColor: "lightgrey",
                      }),
                    }}
                  >
                    <CardContent>
                      <FlexBetween>
                      <Typography
                          sx={{
                            color: "black",
                            fontWeight: "700!important",
                            fontSize: "14px!important",
                          }}
                          gutterBottom
                        >
                          ID: {item.id}
                        </Typography>
                        <Box display="flex" alignItems="center" gap={1} >
                          <Tooltip title="Explore Pipelines">
                            <Link
                               to={`/pipelines/${item.id}`}
                               onClick={(e) => {
     
                                 e.preventDefault();
                                 handleClick(item, itemData);
                               }}
                               style={{ color: 'var(--primary-color) !important', textDecoration: 'none' }} 
                            >
                              <AddCircleOutlineIcon sx={styles.iconButton} />
                            </Link>
                          </Tooltip>
                          <Box
                            color="secondary"
                            onClick={() => {
                              if (item.option_write === "1") {
                                openInPopup(item);
                              }
                            }}
                            disabled={item.option_write !== "1"}
                          >
                            {item.option_write === "1" ? (
                              <ModeEditOutlineTwoToneIcon sx={{ ...styles.editButton, cursor: 'pointer' }} />
                            ) : (
                              <EditOffTwoToneIcon sx={{ ...styles.editButtonDisable, cursor: 'pointer' }} />
                            )}
                          </Box>
                        </Box>
                      </FlexBetween>


                      <Typography variant="h5" component="div"
                        sx={{
                          color: "var(--sidebar-select-color) !important",
                          fontWeight: "600!important",
                          fontFamily: "Poppins",
                          fontSize:"13px"
                        }}>
                        {item.project_name}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "700!important",
                          color: "black",
fontSize:"12px"
                        }}
                      >
                        {item.project_manager}
                      </Typography>
                      <Typography
                        sx={{
                          color: "black",
                          fontSize:"12px"
                        }}
                      >
                        {item.project_lead}
                      </Typography>
                      <Typography sx={{
                        fontSize: '12px',
                        fontFamily: "Poppins",
                        fontStyle: 'italic',
                      }}
                      >

                        {item.project_description}
                      </Typography>
                    </CardContent>




                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: 2,
                      }}
                    >
                      {/* <CardActions>
                        <Link
                          to={`/pipelines/${item.id}`}
                          onClick={(e) => {

                            e.preventDefault();
                            handleClick(item, itemData);
                          }}
                          style={{

                            color: 'var(--primary-color) !important',
                          }}
                        >Explore Pipelines</Link>
                      </CardActions> */}
                    </Box>
                  </Card>
                );
              })
            }
          </Box>
        ) : (
          <>No Data Found</>
        )}
      </Box>
      <Popup
        title="Project Form"
        openPopup={openPopup}
        width={"sm"}
        setOpenPopup={setOpenPopup}
      >
        {formError ? (
          <Alert severity="warning" style={{ width: 'fit-content', margin: 'auto' }}>
            {/* <AlertTitle><strong>Warning</strong></AlertTitle> */}
            <Typography>{formError?.data?.message}</Typography>
          </Alert>
        ) : (
          <ObjectForm
            fieldsList={fieldsList}
            values={recordForEdit}
            addOrEdit={addOrEdit}
            setOpenPopup={setOpenPopup}
            object={"Project"}
          />)}
      </Popup>

      <Popup
        title="Projects"
        openPopup={openPopupHelp}
        width={"md"}
        okBtn={true}
        handleOkBtnClick={closeModal}
        setOpenPopup={setOpenPopupHelp}
      >
        <ReactMarkdown>{markdownContent}</ReactMarkdown>

      </Popup>
    </>
  );
};

export default Projects;
