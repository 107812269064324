import { useState } from "react";
import { TextField, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const Password = ({
  field_id,
  display_name,
  field_value,
  handleBlur,
  handleChange,
  touched,
  errors,
  values,
  type,
  ...otherProps
}) => {
  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const renderField = () => {
    const shouldDisplayPassword =
      values.authentication_type === "basic" ||
      (type && type !== "Application");

    if (shouldDisplayPassword) {
      return (
        <TextField
          id="standard-basic"
          fullWidth
          variant="outlined"
          type={showPassword ? "text" : "password"} // Toggle between text and password
          label={display_name}
          sx={{
            gridColumn: "span 4",
            width: "360px!important",
            marginLeft: "12px",
            minHeight: "10px",
            'input::-ms-reveal': {
            display: 'none',
          },
            
          }}
          InputLabelProps={{
            style: { color: "black", fontSize: "18px" },
            shrink: true, // Ensure the label stays above the input
          }}
          InputProps={{
            // Add eye icon inside the input field
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={togglePasswordVisibility}
                  edge="end"
                  aria-label="toggle password visibility"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          value={values != null ? values[field_id] : ""}
          name={field_id}
          error={!!touched[field_id] && !!errors[field_id]}
          helperText={touched[field_id] && errors[field_id]}
          {...otherProps}
        />
      );
    } else {
      return null;
    }
  };

  return renderField();
};

export default Password;
