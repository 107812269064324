import React, { useState, useEffect } from "react";
import {
  CircularProgress,
  Box,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Card,
  CardContent,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import { TbDatabaseImport } from "react-icons/tb";
import Tooltip from "@mui/material/Tooltip";
import { LuFileJson } from "react-icons/lu";

import {
  useGetCommitsQuery,
  useFetchPublishedJsonQuery,
  useRestructureJsonMutation,
} from "../state/apiSlice";
import { formatDistanceToNow } from "date-fns";
import useStyles from "../useStyles";
import { toast } from "react-hot-toast";
import Popup from "../components/Popup";
import Loading from "../components/elements/Loading";


const GitRevert = ({ taskValues, setOpenPopup }) => {
  const filePath = `programs/${taskValues.project_name}/pipelines/tasks/${taskValues.task_name}.json`;
  const jsonFile = `JSON: ${taskValues.task_name}`;

  const [selectedUser, setSelectedUser] = useState("");
  const { data, error, isLoading, refetch } = useGetCommitsQuery({ filePath });

  const users = data ? Object.keys(data) : [];
  const [open, setOpen] = useState(false);
  const [viewJson, setViewJson] = useState("");
  const [triggerRestructure, setTriggerRestructure] = useState(false);
  const [selectedCommitSha, setSelectedCommitSha] = useState(null);
  const [loadingButtons, setLoadingButtons] = useState({}); // Tracks loading state for each button

  // Fetch JSON data (automatically refetches when selectedCommitSha changes)
  const { data: jsonData, isLoading: jsonLoading } = useFetchPublishedJsonQuery(
    { commitSha: selectedCommitSha, filePath },
    { skip: !selectedCommitSha } // Skip the query if no commit SHA is selected
  );

  const [restructureJson] = useRestructureJsonMutation();
  console.log(setOpenPopup, "Loading");

  useEffect(() => {
    if (setOpenPopup === true) {
      // Refetch the query when setOpenPopup becomes false
      refetch();
    }
  }, [setOpenPopup, refetch]);

  useEffect(() => {
    if (data && users.length > 0 && !selectedUser) {
      setSelectedUser(users[0]);
    }
  }, [data, users, selectedUser]);

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const softRefresh = () => {
    
    // Add a delay (e.g..3 seconds)
    setTimeout(() => {
      window.location.reload(false); // Perform the soft refresh after the delay
    }, 3000); // 3000 milliseconds = 3 seconds
  };
  
  

  const getRelativeTime = (date) => {
    const now = new Date();
    const diffInMs = now - new Date(date);
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
    if (diffInDays >= 7) {
      const weeks = Math.floor(diffInDays / 7);
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    }
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  };

  // Handle View JSON button
  const handleJsonClick = async (sha) => {
    setSelectedCommitSha(sha); // This triggers the query to fetch JSON data
    setOpen(true);
  };

  // Add a useEffect to handle jsonData after selectedCommitSha is set
  useEffect(() => {
    const handleJsonDataFetch = async () => {
      setViewJson(jsonData);
      if (!selectedCommitSha || jsonLoading || !jsonData || !triggerRestructure)
        return;

      try {
        // Check if jsonResponse has valid data
        if (!jsonData) throw new Error("Failed to fetch JSON data");

        // Restructure the JSON data
        const response = await restructureJson(jsonData);
        if (response.error) {
          toast.error(response.error.data.message);
        } else {
          toast.success(
            "Task Json successfully updated to the respective MetaData tables"
          );
          
          // softRefresh()
        }

        // Success toast
      } catch (error) {
        // Error toast
        toast.error("Error: " + error.message);
      } finally {
        // Stop loading spinner
        setLoadingButtons((prevState) => ({
          ...prevState,
          [selectedCommitSha]: false,
        }));
        setTriggerRestructure(false); // Reset the trigger after execution
      }
    };

    handleJsonDataFetch();
  }, [jsonData, jsonLoading, selectedCommitSha, triggerRestructure]);

  // Function that triggers restructuring (from the button click)
  const handleDbImportClick = (sha) => {
    // Set selected SHA (this will trigger the RTK query)
    setSelectedCommitSha(sha);

    // Start loading spinner for that SHA
    setLoadingButtons((prevState) => ({ ...prevState, [sha]: true }));

    // Set the flag to trigger the restructuring process
    setTriggerRestructure(true);
  };

  const styles = useStyles();
  const userCommits = data && selectedUser ? data[selectedUser] : [];

  const groupedCommits = userCommits.reduce((acc, commit) => {
    const date = new Date(commit.date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });

    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(commit);
    return acc;
  }, {});

  if (isLoading) {
    return <Loading />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  return (
    <Box>
      <Box sx={{ marginBottom: "10px" }}>
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Commits
        </Typography>
        <Divider sx={{ margin: "5px 0" }} />
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "20px",
          fontWeight: "500",
        }}
      >
        <Typography variant="body1" component="h6" sx={{ fontWeight: "bold" }}>
          History for {taskValues.project_name} / {taskValues.pipeline_name} /
          tasks / {taskValues.task_name}
        </Typography>

        <FormControl variant="outlined" sx={{ width: "200px" }}>
          <InputLabel
            id="user-select-label"
            sx={{
              color: "black", // Initial color
              "&.Mui-focused": { color: "black" }, // Color when focused
              "&.MuiFormLabel-filled": { color: "black" }, // Color when a value is selected
            }}
          >
            Branch
          </InputLabel>
          <Select
            labelId="user-select-label"
            value={selectedUser}
            onChange={handleUserChange}
            label="user"
          >
            {users.map((user) => (
              <MenuItem key={user} value={user}>
                {user}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box sx={{ marginTop: "20px", position: "relative" }}>
        {Object.entries(groupedCommits).length > 0 ? (
          <>
            {Object.entries(groupedCommits).map(([date, commits]) => (
              <Box key={date} sx={{ marginBottom: "30px", display: "flex" }}>
                {/* Vertical timeline line with circular ends */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: "10px",
                    marginTop: "5px",
                    marginBottom: "5px",
                    position: "relative",
                    width: "20px", // Adjusted width for the container to fit circles and text
                    //   height: "300px",
                  }}
                >
                  {/* Top Circle with horizontal lines */}
                  <Box
                    sx={{
                      position: "relative",
                      width: "12px",
                      height: "12px",
                      backgroundColor: "#0f295c",
                      borderRadius: "50%",
                      marginBottom: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        content: '""',
                        position: "absolute",
                        top: "50%",
                        left: "-6px",
                        width: "5px",
                        height: "2px",
                        backgroundColor: "#0f295c",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        content: '""',
                        position: "absolute",
                        top: "50%",
                        right: "-6px",
                        width: "5px",
                        height: "2px",
                        backgroundColor: "#0f295c",
                      }}
                    ></Box>
                  </Box>

                  {/* Vertical Line */}
                  <Box
                    sx={{
                      width: "2px",
                      flex: 1, // Allow the line to stretch vertically
                      backgroundColor: "#0f295c",
                    }}
                  ></Box>
                </Box>

                <Box sx={{ flex: 1 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* "Commits on date" aligned beside the divider */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center", // Align circle and text on the same line
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ marginBottom: "10px", color: "#0f295c" }}
                      >
                        {"Commits on " + date}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ marginBottom: "10px" }} />

                  {commits.map((commit, commitIndex) => (
                    <Card key={commitIndex} sx={{ marginBottom: "10px" }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ flex: 1 }}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: "bold", color: "#0f295c" }}
                          >
                            {commit.message}
                          </Typography>
                          <Typography variant="caption">
                            <Typography
                              variant="caption"
                              component="span"
                              sx={{ fontStyle: "italic", color: "grey" }}
                            >
                              {commit.author}
                            </Typography>{" "}
                            committed {getRelativeTime(commit.date)} at{" "}
                            {new Date(commit.date).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "15px",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontFamily: "monospace",
                              fontWeight: "bold",
                            }}
                          >
                            {commit.sha.slice(0, 6)}
                          </Typography>
                          <Tooltip title="View Object as JSON" arrow>
                            <IconButton
                              onClick={() => handleJsonClick(commit.sha)}
                            >
                              <LuFileJson />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Sync to Db" arrow>
                            <IconButton
                              onClick={() => handleDbImportClick(commit.sha)}
                            >
                              {loadingButtons[commit.sha] ? (
                                <>
                                  <CircularProgress
                                    variant="determinate"
                                    value={100} // Value for the static gray background ring
                                    sx={{
                                      color: "gray", // Static ring color
                                      position: "absolute",
                                    }}
                                    size={24}
                                    thickness={3}
                                  />
                                  <CircularProgress
                                    variant="indeterminate" // Rotating progress
                                    disableShrink // Keeps the size constant during rotation
                                    sx={{
                                      color: "var(--primary-color)", // Rotating color
                                    }}
                                    size={24}
                                    thickness={3}
                                  />
                                </>
                              ) : (
                                <TbDatabaseImport />
                              )}
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Box>
            ))}

            {/* The final section for the circular end of the divider */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "-33px",
                marginLeft: "4px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center", // Align circle and text on the same line
                }}
              >
                {/* Bottom Circle with horizontal lines */}
                <Box
                  sx={{
                    position: "relative",
                    width: "12px",
                    height: "12px",
                    backgroundColor: "#0f295c",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                >
                  <Box
                    sx={{
                      content: '""',
                      position: "absolute",
                      top: "50%",
                      left: "-6px",
                      width: "5px",
                      height: "2px",
                      backgroundColor: "#0f295c",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      content: '""',
                      position: "absolute",
                      top: "50%",
                      right: "-6px",
                      width: "5px",
                      height: "2px",
                      backgroundColor: "#0f295c",
                    }}
                  ></Box>
                </Box>

                {/* "End of commit history" text aligned beside the divider */}
                <Typography
                  variant="subtitle2"
                  sx={{ color: "#0f295c", marginLeft: "4px" }}
                >
                  End of commit history for this file
                </Typography>
              </Box>
            </Box>
          </>
        ) : (
          <Typography>No commits found.</Typography>
        )}
      </Box>

      <Popup
        title={jsonFile}
        openPopup={open}
        width={"sm"}
        setOpenPopup={setOpen}
      >
        {jsonLoading ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "75vh",
            }}
          >
            <Loading />
            {/* <p>Please wait while we fetch json ...</p> */}
          </Box>
        ) : (
          <pre sx={{ fontSize: "0.9rem" }}>
            {JSON.stringify(viewJson, null, 2)}
          </pre>
        )}
      </Popup>
    </Box>
  );
};

export default GitRevert;
