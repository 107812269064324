import React, { useState,useEffect, useRef, useCallback ,useMemo} from 'react';
import { toast } from 'react-hot-toast';
import { AiTwotoneNotification } from 'react-icons/ai';
import { BiError } from 'react-icons/bi';
import { IoWarningOutline } from 'react-icons/io5';
import dagre from "dagre";
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useGetTransformationFormQuery } from 'state/apiSlice'
import ReactFlow, {
  addEdge,
  useNodesState,
  useEdgesState,
  Controls,
  MarkerType,
  ReactFlowProvider
} from 'reactflow';
import Expression from "./Expression";
import { useGetExpressionDescriptionsQuery } from 'state/apiSlice';
import 'reactflow/dist/style.css';
import {  Button} from '@mui/material';
import { selectCurrentUser } from "state/authSlice";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import Filter from './Filter'
import CustomNodes from '../components/CustomNodes';
import DefaultEdge from '../components/DefaultEdge';
import { TiTick } from 'react-icons/ti';
import { ImCross } from 'react-icons/im';

import * as yup from "yup";


import '../components/css/index.css';
import '../components/css/edgebutton.css';

import TransformationForm from './TransformationForm';
import { DeleteNodeProvider } from '../components/DeleteNodeContext';
import {checkAllConnectionsAndCycles} from '../services/graph.services';
import {
  findSectionField, checkLabel,
  handleNodeSetup,
  handleJoinerNode,
  handleFilterNode,
  handleOutputNode,
  handleSQLNode,
  handlePythonNode,
  handleExpressionNode,
  updateEdgeLabel,
  findSourceNode,
  addTaskDetail, 
  processFilterNode, 
  processExpressionNode, 
  processSQLNode, 
  processPythonNode, 
  processJoinerNode
} from '../services/transformation.services';


const handleDeleteNode = (id, setNodes, setEdges) => {
  setNodes((prevNodes) => {
    const newNodes = prevNodes.filter(node => node.id !== id);
    return newNodes;
  });
  setEdges((prevEdges) => {
    const newEdges = prevEdges.filter(edge => edge.source !== id && edge.target !== id);
    return newEdges;
  });
};

let id = 0;
const getId = () => `dndnode_${id++}`;
const edgeTypes = {
  defaultEdge: DefaultEdge,
};

const nodeTypes = {
  customNode: CustomNodes
};


const styles = {
  statusBar: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    backgroundColor: 'white',
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    zIndex: 1000,
    width: '300px',
  },
  statusBarTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold',
    marginBottom: '5px',
  },
  notificationIcon: {
    marginRight: '5px',
    fontSize: '1.5em',
  },
  issueList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  issueContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  issueIcon: {
    marginRight: '5px',
    fontSize: '1.5em',
    flexShrink: 0,
  },
  issueText: {
    flex: 1,
    wordWrap: 'break-word',
  },
  criticalIssue: {
    backgroundColor: '#f8d7da',
    color: '#721c24',
    borderLeft: '5px solid #f5c6cb',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '3px',
  },
  warningIssue: {
    backgroundColor: '#fff3cd',
    color: '#856404',
    borderLeft: '5px solid #ffeeba',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '3px',
  },
  successMessage: {
    backgroundColor: '#d4edda',
    color: '#155724',
    borderLeft: '5px solid #c3e6cb',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '3px',
  },
  errorMessage: {
    backgroundColor: '#f8d7da',
    color: '#721c24',
    borderLeft: '5px solid #f5c6cb',
    padding: '10px',
    marginBottom: '5px',
    borderRadius: '3px',
  },
};
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const nodeWidth = 172;
const nodeHeight = 36;

const getLayoutedElements = (nodes, edges, direction = "LR") => {
  const isHorizontal = direction === "LR";
  dagreGraph.setGraph({
    rankdir: direction,
    nodesep: 30,   // Reduced space between nodes
    edgesep: 30,   // Reduced minimum edge length
    ranksep: 60,   // Reduced separation between levels
    marginx: 10,   // Reduced margin for better spacing
    marginy: 10,   // Reduced margin for better spacing
  });

  // Setting nodes with zero positions initially
  const updatedNodes = nodes.map((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    return {
      ...node,
      position: { x: 0, y: 0 },  // Initially set to 0, 0
    };
  });

  // Setting edges
  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  // Perform the layout calculation
  dagre.layout(dagreGraph);

  let minX = Infinity, minY = Infinity;

  // Map the calculated positions back to the nodes
  const layoutedNodes = updatedNodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);

    // Update minX and minY to find the top-left corner of all nodes
    minX = Math.min(minX, nodeWithPosition.x - nodeWidth / 2);
    minY = Math.min(minY, nodeWithPosition.y - nodeHeight / 2);

    return {
      ...node,
      targetPosition: isHorizontal ? "left" : "top",
      sourcePosition: isHorizontal ? "right" : "bottom",
      position: {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      },
    };
  });

  // Apply offset to bring all nodes into view
  const offsetX = Math.abs(minX) + 20; // Add some margin
  const offsetY = Math.abs(minY) + 20;

  const adjustedNodes = layoutedNodes.map((node) => ({
    ...node,
    position: {
      x: node.position.x + offsetX,
      y: node.position.y + offsetY,
    },
  }));

  return { nodes: adjustedNodes, edges };
};
const Transformation = (props) => {
  let { transformationFlow: initialTransformationFlow } = props;


  // Initialize state with the prop value
  let [transformationFlow, setTransformationFlow] = useState([initialTransformationFlow[0]]);

  const [temporaryMessage, setTemporaryMessage] = useState(null);  
  const [initialSaveEnabled, setInitialSaveEnabled] = useState(false);
  const [skipFirstToggle, setSkipFirstToggle] = useState(true); 
   const [wasConnectedOnce, setWasConnectedOnce] = useState(false);
  const [isStatusBarVisible, setIsStatusBarVisible] = useState(false);
  const [isButtonActive, setButtonActive] = useState(false);

 
  const { data: operatorsData1, isLoading: isRecordLoading } = useGetExpressionDescriptionsQuery();
  const currentUser = useSelector(selectCurrentUser);
let user=currentUser?.login_id
  const { taskFormFields, values: editValue, addOrEditRecord,setOpenPopup  } = props;
  const [issues, setIssues] = useState([]);
  const [dismissedIssues, setDismissedIssues] = useState([]);


  const { data: transformationData, isLoading: isFormLoading, error: formError } = useGetTransformationFormQuery(user);
const mainRecord = findSectionField(transformationData, "main");
let inputRecord = findSectionField(transformationData, "Input");
const outputRecord = findSectionField(transformationData, "Output");
let joinerRecord = findSectionField(transformationData, "Joiner");
const filterRecord = findSectionField(transformationData, "Filter");
const expressionRecord = findSectionField(transformationData, "Expression");
const originalInitialvalues = expressionRecord?.initialvalues || {};
const extractInitialValue = (editValue) => {
  const detailsArray = editValue?.details?.details?.details || [];
  const mainEntry = detailsArray.find(item => item?.Main);
  return mainEntry ? mainEntry.Main[0].task?.job_execution : 'pandas';
};
const [selectedOption, setSelectedOption] = useState(extractInitialValue(editValue)||'pandas');
 
const initialvaluesStructure = Object.entries(originalInitialvalues).reduce((acc, [key, value]) => {
    if (key !== "transformation_name" && key !== "input_df" && key !== "output_df") {
        acc.expression_list[key] = value;
    } else {
        acc[key] = value;
    }
    return acc;
}, {
    transformation_name: "",
    input_df: "",
    output_df: "",
    expression_list: {},
    columns:[]
});

// Create a new object representing the modified expressionRecord
const modifiedExpressionRecord = {
    ...expressionRecord, // Copy other properties from the original expressionRecord
    initialvalues: initialvaluesStructure // Assign the new initialvalues structure
};
const sqlRecord = findSectionField(transformationData, "SQL");
const pythonRecord = findSectionField(transformationData, "Python");
const mainFormData = mainRecord?.fields_list;

const inputFormData = inputRecord?.fields_list;
const outputFormData = outputRecord?.fields_list;
const joinerFormData = joinerRecord?.fields_list;
const sqlFormData = sqlRecord?.fields_list;
const pythonFormData = pythonRecord?.fields_list;

const filterFormData = filterRecord?.fields_list;
const expressionFormData = expressionRecord?.fields_list;

const sections = transformationData
  ? transformationData
      .map(item => item.section)
      .filter(section => section !== 'main')
  : [];




  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [choice, setChoice] = useState(null);
  const [joinerSelection, setJoinerSelection] = useState({ joinerId: null, inputs: [] });
  const [outputSelection, setOutputSelection] = useState({ outputId: null, inputs: [] });
  const [sqlSelection, setSQLSelection] = useState({ sqlId: null, inputs: [] });
  const [pythonSelection, setPythonSelection] = useState({ pythonId: null, inputs: [] });


  
  const [filterSelection, setFilterSelection] = useState({ filterId: null, inputs: [] });
  const [expressionDataSelection, setExpressionDataSelection] = useState({ expressionId: null, inputs: [] });


  const [dbType, setDbType] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formData, setFormData] = useState();
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [nodeName, setNodeName] = useState('');
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [renderChoice, setrenderChoice] = useState(false);

  const { nodes: initialNodes, edges: initialEdges } = transformationFlow[0];

  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes || []);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges || []);

// Function to connect edges

const onConnect = useCallback(
  (params) => {
    const sourceNode = nodes.find((node) => node.id === params.source);
    let edgeLabel = checkLabel(sourceNode);
    const newEdge = {
      ...params,
      type: "defaultEdge",
      data: {
        // sourceNodeData: sourceNode.data,
        label: edgeLabel,
      },
    };

    setEdges((eds) => addEdge(newEdge, eds));
  },
  [nodes]
);

//Use effect will get triggered on every change in data for edge label to udpate it in default edges



useEffect(() => {
  setEdges((eds) =>
    eds.map((edge) => {
      const sourceNode = findSourceNode(edge, nodes);
      return sourceNode ? updateEdgeLabel(edge, sourceNode) : edge;
    })
  );
}, [nodes]);

  const defaultEdgeOptions = {
    // style: { stokeWidth: 2, stoke: "black" },
    type: "defaultEdge",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "black",
    },
  };
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);
  const findConnectedNodes = (nodeId, handleId = null) => {
    return edges
      .filter(edge => 
        (handleId ? edge.targetHandle === handleId : edge.target === nodeId)
      )
      .map(edge => getNode(edge.source));
  };
  
  const getNode = (nodeId) => {
    return nodes.find(node => node.id === nodeId);
  };

  let initValues = {};
  const initialDataByType = (label, savedData = {}) => {

  const commonData = {
    displayName: `${label}`,
  };

  const labelSpecificData = {
    Main: {},
    Input: {},
    Joiner: { select_columns: '' },
    SQL: {},
    Python: {},
    Filter: { select_columns: '' },
    Expression: { expression_data: {} },
    Output: {}
  };

  return {
    label,
    ...commonData,
    ...labelSpecificData[label] || {}
  };
};


  

  const deleteNodeCallback = useCallback(
    (id) => handleDeleteNode(id, setNodes, setEdges),
    [setNodes, setEdges]
  );
  
  useEffect(() => {
    const { allConnected, hasCycle, hasMultipleGraphs } = checkAllConnectionsAndCycles(nodes, edges);
  
    const newIssues = [];
    let sqlNodeWarningShown = false;
  
    if (hasCycle) {
      newIssues.push({ type: 'critical', message: "Cycle detected in the dataflow!" });
    }
    if (hasMultipleGraphs) {
      newIssues.push({ type: 'critical', message: "Multiple independent dataflow's detected!" });
    }
  
    const sortNodes = nodes.filter(node => node.data.label === 'SQL');
    sortNodes.forEach(sortNode => {
      const incomingEdges = edges.filter(edge => edge.target === sortNode.id);
      if (incomingEdges.length > 4) {
        const issueMessage = "Only four connections are allowed for SQL Node. Please fix the dataflow";
        if (!newIssues.find(issue => issue.message === issueMessage)) {
          newIssues.push({ type: 'warning', message: issueMessage });
          sqlNodeWarningShown = true;
        }
      }
    });
    const pythonNodes = nodes.filter(node => node.data.label === 'Python');
    pythonNodes.forEach(sortNode => {
      const incomingEdges = edges.filter(edge => edge.target === sortNode.id);
      if (incomingEdges.length > 4) {
        const issueMessage = "Only four connections are allowed for Python  Node. Please fix the dataflow";
        if (!newIssues.find(issue => issue.message === issueMessage)) {
          newIssues.push({ type: 'warning', message: issueMessage });
          sqlNodeWarningShown = true;
        }
      }
    });
  
    setIssues(newIssues);
    setButtonActive(allConnected && newIssues.filter(issue => issue.type === 'critical').length === 0 && !sqlNodeWarningShown);
  
    const visibleIssues = newIssues;
    const shouldShowSaveMessages = visibleIssues.length === 0;
  
    setIsStatusBarVisible(visibleIssues.length > 0 || !!temporaryMessage);
  
    if (skipFirstToggle && allConnected) {
      setWasConnectedOnce(true);
      setSkipFirstToggle(false);
      return;
    }
  
    if (wasConnectedOnce && shouldShowSaveMessages) {
      if (!allConnected && isButtonActive) {
        setTemporaryMessage({ type: 'error', message: 'Save is disabled. Please complete the dataflow' });
        setIsStatusBarVisible(true);
      } else if (allConnected && !isButtonActive) {
        setTemporaryMessage({ type: 'success', message: 'Save is enabled. You can now save your dataflow' });
        setIsStatusBarVisible(true);
      }
    }
  
    const timer = setTimeout(() => {
      setTemporaryMessage(null);
      setIsStatusBarVisible(visibleIssues.length > 0);
    }, 4500);
  
    return () => clearTimeout(timer);
  
  }, [nodes, edges, isButtonActive, wasConnectedOnce, skipFirstToggle, temporaryMessage]);
  
  function validateFormSchema(formData) {
    let validSchemaObject = {};
    formData.forEach(({ field_id, display_label, required }) => {
      if (required === "Y") {
        validSchemaObject[field_id] = yup
          .string()
          .required(`${field_id} is required`);
      }
      if (field_id === "task_name") {
        validSchemaObject[field_id] = validSchemaObject[field_id].matches(
          /^[a-zA-Z0-9_]+$/,
          `${field_id} must be alphanumeric and may contain underscores only`
        );
      }
    });
    return yup.object().shape(validSchemaObject);
  }
  
  function validateSchemaObject(section) {
    let validationObject = {};
  
    switch (section) {
      case "main":
        if (mainFormData != null) {
          validationObject[section] = validateFormSchema(mainFormData);
        }
        break;
  
      case "input":
        if (inputFormData != null) {
          validationObject[section] = validateFormSchema(
            inputFormData?.field_list
          );
        }
        break;
      case "joiner":
        if (joinerFormData != null) {
          validationObject[section] = validateFormSchema(
            joinerFormData?.field_list
          );
        }
        break;
      
        case "output":
          if (outputFormData != null) {
            validationObject[section] = validateFormSchema(
              outputFormData?.field_list
            );
          }
          break;
      default:
        break;
    }
  
    return validationObject;
  }
  

  let transformedFilter = [];
  let transformedExpression = [];
  if (editValue === null || editValue === undefined) {
    initValues = {
      ...mainFormData?.initialvalues,
      ...inputFormData?.initialvalues,
      ...outputFormData?.initialvalues,
      ...joinerFormData?.initialvalues,
      ...sqlFormData?.initialvalues,
      ...pythonFormData?.initialvalues,
    };
  } else {
    const { details, ...taskRecord } = editValue || {};

    const detailsArray = details?.details?.details || [];

    const inputDetails = detailsArray.find(item => item?.Input);
    const mainDetails=detailsArray.find(item => item?.Main)
    const joinerDetails = detailsArray.find(item => item?.Joiner);
    const sqlDetails = detailsArray.find(item => item?.SQL);
    const pythonDetails = detailsArray.find(item => item?.Python);
    const outputDetails = detailsArray.find(item => item?.Output);
    const filterDetails = detailsArray.find(item => item?.Filter);
    const expressionDetails = detailsArray.find(item => item?.Expression);
  
    const input = inputDetails ? inputDetails?.Input : null;
    const main = mainDetails ? mainDetails?.Main : null;
    const joiner = joinerDetails ? joinerDetails?.Joiner : null;
    const sql = sqlDetails ? sqlDetails?.SQL : null;
    const python = pythonDetails ? pythonDetails?.Python : null;
    const output = outputDetails ? outputDetails?.Output : null;
    const filter = filterDetails ? filterDetails?.Filter : null;
    const expression = expressionDetails ? expressionDetails?.Expression : null;

    let transformedJoiner = [];
    let transformedSQL = [];
    let transformedPython = [];

    let transformedInput = [];
    let transformedOutput = [];
    let transformedExpression = [];
  
    const prefixKeys = (item, prefix) => {
      const transformedItem = {};
      for (const key in item) {
        if (item.hasOwnProperty(key)) {
          transformedItem[`${prefix}-${key.toLowerCase()}`] = item[key];
        }
      }
      return transformedItem;
    };
  
    if (joiner) {
      transformedJoiner = joiner.map((item) => prefixKeys(item, "joiner"));
    }
    if (sql) {
      transformedSQL = sql.map((item) => prefixKeys(item, "sql"));
    }
    if (python) {
      transformedPython = python.map((item) => prefixKeys(item, "python"));
    }
    if (input) {
      transformedInput = input.map((item) => prefixKeys(item, "input"));
    }
  
    if (filter) {
      transformedFilter = filter.map((item) => prefixKeys(item, "filter"));
    }
  
    if (expression) {
      transformedExpression = expression.map((item) => prefixKeys(item, "expression"));
    }
  
    if (output) {
      transformedOutput = output.map((item) => prefixKeys(item, "output"));
    }
  
    if (!joiner && !input && !output) {
      console.log(null);
    }

    const detailRecord = Object.assign({}, ...transformedInput, ...transformedJoiner,...transformedSQL,...transformedPython, ...transformedOutput, ...transformedFilter, ...transformedExpression);
    initValues = { ...taskRecord, ...detailRecord };
  }


  const [datavalues, setDataValues] = useState("true");

  const updateTransformationFlow = useCallback((initValues) => {
    if (!initValues) return;
  
    setNodes((prevNodes) =>
      prevNodes.map((node) => {

        if(node.data.label == "Filter"|| node.data.label=="Expression"){
          const updatedData = initValues[node.id];
          const tranformationName = updatedData?.["transformation_name"];
          let appendTransformationName = {
            [tranformationName]:updatedData
          }
          if(node.data.label=="Filter"){
          let filterUpdatedData ={
            "Filter":appendTransformationName
          }
          if (updatedData) {
            return {
              ...node,
              data: {
                ...node.data,
                ...filterUpdatedData,
              },
            };
          }
          
        }
        else if(node.data.label=="Expression"){
          let expressionUpdatedData={
            "Expression":appendTransformationName
          }
          if (updatedData) {
            return {
              ...node,
              data: {
                ...node.data,
                ...expressionUpdatedData,
              },
            };
          }
        }
        return node;
        }

        const updatedData = initValues[node.id];
        if (updatedData) {
          return {
            ...node,
            data: {
              ...node.data,
              ...updatedData,
            },
          };
        }
        return node;
      })
    );
  
  }, []);
  
  useEffect(() => {
    if (datavalues === "true") {
      updateTransformationFlow(initValues);
      setDataValues("false"); 
    }
  }, [datavalues, initValues, updateTransformationFlow]);

  
const [formValues, setFormValues] = useState(initValues);
    const validationSchema = validateSchemaObject(sections);
    const formik = useFormik({
      initialValues: initValues,
      validationSchema: validationSchema[sections],

      onSubmit: (values, { resetForm }) => {
        const taskNameRegex = /^[a-zA-Z0-9_]+$/;
        if (!taskNameRegex.test(values?.task_name)) {
          return;
        }
        handleFormSubmit(values, resetForm);
      },
    });



    const handleNodeFormSubmit = (nodeId, formData, resetForm) => {
      setNodes((prevNodes) =>
        prevNodes.map((node) =>
          node.id === nodeId
            ? {
                ...node,
                data: {
                  ...node.data,
                  ...formData,
                },
              }
            : node
        )
      );
    
      // Set the form data for the connected node
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [nodeId]: formData,
      }));
    
      resetForm();
      setIsFormVisible(false);
    };
    
    
    const handleFormSubmit = async (values, resetForm) => {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        [selectedNodeId]: values,
      }));
      resetForm();
    };
    
    
    const formMain = (formikProps) => {
      return (
        <TransformationForm
          taskProps={mainRecord}
          formProps={formikProps}
          object={"Task"}
          onSave={handleSaveForm} 
          initialValues={initValues}
        />
      );
    };
    const [newOptions,setOptions]=useState()
   

    const handleOutDfValues = (outDfValues) => {
      setOptions(outDfValues)
    };
    const [actualColumns,setActualColums]=useState()


const handleActualColumnsValue=(onactualColumnsValue)=>{
  setActualColums(onactualColumnsValue)

}   
      
    const formJoiner = (formikProps, output_df) => {
      return (
        <TransformationForm
          taskProps={joinerRecord}
          connectedInputs={joinerSelection.inputs} 
          onSubmit={(formData) => handleNodeFormSubmit(selectedNodeId, { ...formData, output_df })}
          initialValues={formValues[selectedNodeId]}
          formProps={formikProps}
          object={"Joiner"}
          onSave={(formData) => handleSaveForm(formData)}
          onOutDfValues={handleOutDfValues} 

        />
      );
    };
    
    const formFilter = (formikProps) => {
      return (
        <Filter
        onSave={(formData) => handleSaveForm(formData)}
        connectedInputs={filterSelection.inputs} 

        initialValues={formValues[selectedNodeId]}

        actualColumns={actualColumns}
        filterRecord={filterRecord}
        filterDetails={transformedFilter}
        />
      );
    };

    const formSQL = (formikProps) => {
      return (
        <TransformationForm
        taskProps={sqlRecord}
        connectedInputs={sqlSelection.inputs} 

          formProps={formikProps}
          object={"SQL"}
          onSubmit={(formData) => handleNodeFormSubmit(selectedNodeId, formData)}
          initialValues={formValues[selectedNodeId]}
          onSave={(formData) => handleSaveForm(formData)}
        

        />
      );
    };   const formPython = (formikProps) => {
      return (
        <TransformationForm
        taskProps={pythonRecord}
        connectedInputs={pythonSelection.inputs} 

          formProps={formikProps}
          object={"Python"}
          onSubmit={(formData) => handleNodeFormSubmit(selectedNodeId, formData)}
          initialValues={formValues[selectedNodeId]}
          onSave={(formData) => handleSaveForm(formData)}
        

        />
      );
    };

    const formExpression = (formikProps) => {

      return (
        <Expression
            connectedInputs={expressionDataSelection?.inputs}
            onFormValuesSubmit={handleSaveForm}
            initialData={formValues[selectedNodeId]}
            formData={modifiedExpressionRecord}
            // initialData={selectedNode?.data}
            operatorsData={operatorsData1}
            expressionDetails={transformedExpression}
            

          />
      );
    };
    const formInput = (formikProps) => {
      return (
        <TransformationForm
        taskProps={inputRecord}

          formProps={formikProps}
          object={"Input"}
          onSubmit={(formData) => handleNodeFormSubmit(selectedNodeId, formData)}
          initialValues={formValues[selectedNodeId]}
          onSave={(formData) => handleSaveForm(formData)}
          onactualColumnsValue={handleActualColumnsValue} 
          onOutDfValues={handleOutDfValues} 

        />
      );
    };
    const formOutput = (formikProps) => {
      return (
        <TransformationForm
          taskProps={outputRecord}
          connectedInputs={outputSelection.inputs} 

          formProps={formikProps}
          onSubmit={(formData) => handleNodeFormSubmit(selectedNodeId, formData)}
          initialValues={{ ...formValues[selectedNodeId], ...initValues }} 
          object={"Output"}
          onSave={handleSaveForm}
          
        />
      );
    };
  
  // Use onLayout to set the positions of nodes based on the vertical layout
  const onLayoutCalled = useRef(false);

  const onLayout = useCallback(() => {
    if (!onLayoutCalled.current) {
      const layouted = getLayoutedElements(nodes, edges);
  
      setNodes([...layouted.nodes]);
      setEdges([...layouted.edges]);
  
      onLayoutCalled.current = true;
    }
  }, [nodes, edges]);
  
  onLayout(); 
  
 

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const passedArgs = event.dataTransfer.getData('application/reactflow');
      const { nodeType, nodeData } = JSON.parse(passedArgs);

      if (typeof nodeType === 'undefined' || typeof nodeData === 'undefined') {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: getId(),
        type: nodeType,
        position,
        data:initialDataByType(nodeData)
        
      };

      setNodes((nds) => nds.concat(newNode));
    },
    [reactFlowInstance],
  );
let [formComponent,setformComponent]=useState()
let [nodeLable,setNodeLable]=useState();

const handleResetForm = (nodeFormValues,label)=>{
  try{
  const fetchRecord = findSectionField(transformationData,label);
  const initialValues = fetchRecord?.initialvalues;
  if (Object.keys(nodeFormValues).length === 0){
    formik.setValues(initialValues)
  }else{
    formik.setValues(nodeFormValues);
  }
}catch(error){
  console.log("Error while handling form reset",error)
}
}

const onNodeClick = useCallback((event, node) => {
  // Pass setState functions to the node setup handler
  handleNodeSetup(
    node, 
    setSelectedNodeId, 
    setNodeName, 
    formValues, 
    handleResetForm, 
    setrenderChoice, 
    setOpen, 
    setNodeLable
  );

  // Handle specific node types
  switch (node.data.label) {
    case 'Joiner':
      handleJoinerNode(node, findConnectedNodes, setJoinerSelection);
      break;
    case 'Filter':
      handleFilterNode(node, findConnectedNodes, setFilterSelection);
      break;
    case 'Output':
      handleOutputNode(node, findConnectedNodes, setOutputSelection);
      break;
    case 'SQL':
      handleSQLNode(node, findConnectedNodes, setSQLSelection);
      break;
    case 'Python':
      handlePythonNode(node, findConnectedNodes, setPythonSelection);
      break;
    case 'Expression':
      handleExpressionNode(node, findConnectedNodes, setExpressionDataSelection, setChoice);
      break;
    default:
      break;
  }
}, [nodes, edges, formik, formValues, findConnectedNodes]);



useEffect(() => {
  let component = null;
  
  
    if (nodeLable === "main") {
      component = formMain(formik);
    } else if (nodeLable === "Joiner" ) {
      component = formJoiner(formik);
    }
    else if (nodeLable=== "Filter") {
      component = formFilter(formik);
    } else if (nodeLable=== "Expression") {
      component = formExpression(formik);
    } else if (nodeLable === "Input") {
      component = formInput(formik);
    } else if (nodeLable === "Output") {
      component = formOutput(formik);
    }
    else if (nodeLable === "SQL") {
      component = formSQL(formik);
    }
    else if (nodeLable === "Python") {
      component = formPython(formik);
    }
  

  setformComponent(component);
}, [nodeLable, selectedValue, formik]); 


const updateDisplayName = (id, newName) => {
  setNodes(prevNode =>
    prevNode.map(node =>
      node.id === id ? { ...node, data: { ...node.data, displayName: newName } } : node
    )
  );
};


const handleSaveForm = (formData, uiState) => {
  setNodes((currentNodes) => {
    const updatedNodes = currentNodes.map((node) => {
      if (node.id === selectedNodeId) {
        updateDisplayName(selectedNodeId,formData?.transformation_name ??
  Object.values(formData || {}).flatMap(obj => Object.values(obj || {})).find(o => o?.transformation_name)?.transformation_name);
        if(node?.data?.label==="Joiner"){
          return {
            ...node,
            data: {
              ...node.data,
              ...formData,
            }
        }        
      }
        console.log('Updating node:', node);
        return {
          ...node,
          data: {
            ...node.data,
            ...formData,
          },
        };
      }
      return node;
    });
 
    return updatedNodes;
  });
  setFormValues((prevFormValues) => ({
    ...prevFormValues,
    [selectedNodeId]: formData,
  }));
  setIsFormVisible(false);
 
};


const handleChange = (event) => {
  setSelectedOption(event.target.value);
};

const options = [
  { id: 'pandas', name: 'Pandas' },
  { id: 'polars', name: 'Polars' }
];
const handleSave = (resetForm) => {
  console.log(JSON.stringify(nodes, null, 2), "JSON Generated for nodes");

  let taskDetails = [];
  taskDetails.push({
    task_type: "Main",
    parameter_type: "task",
    key_01: "job_execution",
    value_01: selectedOption || "",
    sequence: 1,
    is_active: "Y",
  });

  nodes.forEach((node, index) => {
    const { label } = node.data;
    switch (label) {
      case "Filter":
        processFilterNode(taskDetails, node, index);
        break;
      case "Expression":
        processExpressionNode(taskDetails, node, index);
        break;
      case "SQL":
        processSQLNode(taskDetails, node, index);
        break;
      case "Python":
        processPythonNode(taskDetails, node, index);
        break;
      case "Joiner":
        processJoinerNode(taskDetails, node, index);
        break;
      default:
        // Process generic nodes or other node types
        for (const key in node.data) {
          if (key !== 'label' && key !== 'displayName') {
            addTaskDetail(taskDetails, label, node.data.transformation_name, key, node.data[key], index);
          }
        }
        break;
    }
  });

  let taskRecord = {
    ...editValue,
    details: taskDetails,
  };
  console.log(taskRecord, "ooo");

  try {
    addOrEditRecord(taskRecord, resetForm);
    if (taskRecord?.id === 0) {
      toast.success(`Task Created Successfully`);
      

     
    } else {
      toast.success(`Task Updated Successfully`);
      
    }
    setTimeout(() => {
      setOpenPopup(false);
    }, 3000);  // 5000 milliseconds = 5 seconds
    
  } catch (error) {
    console.error("Form Submission Error: ", error);
  }
};

  const onDragStart = (event, nodeData, nodeType) => {
    event.dataTransfer.setData('application/reactflow', JSON.stringify({ nodeData, nodeType }));
    event.dataTransfer.effectAllowed = 'move';
};


  return (
    <>
        {/* <button onClick={handleUpdateClick}>Update Transformation Flow</button> */}

      <div className="dndflow">
        <aside className='sideBar'>
          {sections.map((section, index) => (
            <div key={index} className={`dndnode ${section.toLowerCase()}`} draggable onDragStart={(event) => onDragStart(event, section, 'customNode')}>
              {section}
            </div>
          ))}
  <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel id="select-helper-mui5-label" style={{ color: 'black' }}>Job Execution</InputLabel>

      <Select
        labelId="select-helper-mui5-label"
        id="restartability"
        value={selectedOption}
        onChange={handleChange}
        label="Job Execution"
        InputLabelProps={{
          style: { color: "black" },
        }}
      >
        {options.map(option => (
          <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
        ))}
      </Select>
    </FormControl>
        </aside>

        <ReactFlowProvider>
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
          <DeleteNodeProvider deleteNodeCallback={deleteNodeCallback}>
            <ReactFlow
              onLayout={onLayout}
              nodes={nodes}
              edges={edges}
              fitView="onLoad" 
              onNodesChange={onNodesChange}
              onEdgesChange={onEdgesChange}
              onNodeClick={onNodeClick}
              onConnect={onConnect}
              onInit={setReactFlowInstance}
              nodeTypes={nodeTypes}
              edgeTypes={edgeTypes}
              onDrop={onDrop}
              onDragOver={onDragOver}
              defaultEdgeOptions={defaultEdgeOptions}
            >
              <Controls />
              <Button
  disabled={!isButtonActive}
  onClick={handleSave}
  variant="contained"
  style={{
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 1000,
    backgroundColor: isButtonActive ? 'blue' : 'grey',
    color: 'white' 
  }}
>
  Save
</Button>
{isStatusBarVisible && (
        <div style={styles.statusBar}>
          <div style={styles.statusBarTitle}>
            <AiTwotoneNotification style={styles.notificationIcon} />
            Status Bar
          </div>
          <ul style={styles.issueList}>
            {issues.map((issue, index) => (
              <li key={index} style={issue.type === 'critical' ? styles.criticalIssue : styles.warningIssue}>
                <div style={styles.issueContent}>
                  {issue.type === 'critical' ? <BiError style={styles.issueIcon} /> : <IoWarningOutline style={styles.issueIcon} />}
                  <span style={styles.issueText}>{issue.message}</span>
                </div>
              </li>
            ))}
            {temporaryMessage && (
              <li style={temporaryMessage.type === 'success' ? styles.successMessage : styles.errorMessage}>
                <div style={styles.issueContent}>
                  {temporaryMessage.type === 'success' ? <TiTick style={styles.issueIcon} /> : <ImCross style={styles.issueIcon} />}
                  <span style={styles.issueText}>{temporaryMessage.message}</span>
                </div>
              </li>
            )}
          </ul>
        </div>
      )}
            </ReactFlow>
            </DeleteNodeProvider>
          </div>
       
        </ReactFlowProvider>
      </div>
    

      {formComponent}
    
    </>
  );
};

export default Transformation