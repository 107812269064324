const hasMultipleIndependentGraphs = (nodes, edges) => {
    // Create an adjacency list to represent the graph
    const adjacencyList = new Map();
  
    // Initialize the adjacency list with each node
    nodes.forEach((node) => adjacencyList.set(node.id, []));
  
    // Populate the adjacency list with edges
    edges.forEach(({ source, target }) => {
      adjacencyList.get(source).push(target);
      adjacencyList.get(target).push(source); // Assuming it's an undirected graph
    });
  
    // Helper function for DFS
    const dfs = (node, visited) => {
      visited.add(node);
      adjacencyList.get(node).forEach((neighbor) => {
        if (!visited.has(neighbor)) {
          dfs(neighbor, visited);
        }
      });
    };
  
    // Find connected components using DFS
    const visited = new Set();
    let componentCount = 0;
  
    for (let node of nodes) {
      if (!visited.has(node.id)) {
        // New component found, start a DFS from this node
        dfs(node.id, visited);
        componentCount++;
  
        // If more than one component is found, return true
        if (componentCount > 1) {
          return true;
        }
      }
    }
  
    // If only one component is found, return false
    return false;
  };

  export const checkAllConnectionsAndCycles = (nodes, edges) => {
    const allConnected = nodes.every(node => {
      const incoming = edges.filter(edge => edge.target === node.id).length;
      const outgoing = edges.filter(edge => edge.source === node.id).length;
  
      switch (node.data.label) {
        case 'Input':
          return outgoing >= 1;
        case 'Joiner':
          return incoming >= 2 && outgoing >= 1;
        case 'Expression':
        case 'Filter':
          return incoming >= 1 && outgoing >= 1;
          case 'SQL':
          return incoming <=4 && outgoing >= 1;
          case 'Python':
            return incoming <=4 && outgoing >= 1;
        case 'Output':
          return incoming >= 1;
        default:
          return false;
      }
    });
  
    if (!allConnected) {
      return { allConnected, hasCycle: false, hasMultipleGraphs: false };
    }
  
    // Function to check for cycles using Depth First Search (DFS)
    const hasCycle = (node, edges, visited = new Set(), recStack = new Set()) => {
      if (recStack.has(node)) {
        // Cycle detected
        return true;
      }
  
      if (visited.has(node)) {
        return false;
      }
  
      visited.add(node);
      recStack.add(node);
  
      const nextNodes = edges.filter(edge => edge.source === node).map(edge => edge.target);
  
      for (const nextNode of nextNodes) {
        if (hasCycle(nextNode, edges, visited, recStack)) {
          return true;
        }
      }
  
      recStack.delete(node);
      return false;
    };
  
    // Initialize visited and recursion stack sets
    const visited = new Set();
    const recStack = new Set();
  
    // Check for cycles starting from each node
    for (const node of nodes) {
      if (hasCycle(node.id, edges, visited, recStack)) {
        return { allConnected, hasCycle: true, hasMultipleGraphs: false };
      }
    }
  
    // Check for multiple independent graphs
    const hasMultipleGraphs = hasMultipleIndependentGraphs(nodes, edges);
  
    return { allConnected, hasCycle: false, hasMultipleGraphs };
  };