import React, { useEffect, useState,useRef, useCallback } from "react";
import { Box ,Button,Dialog,DialogContent,DialogTitle,IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import Element from "../components/Element";
import {

  useLazyGetPreviewFileQuery,
  useLazyGetPreviewDatabaseQuery,
} from "../state/apiSlice";
import PreviewData from "../components/PreviewData";

const TransformationForm = (props) => {
  const { taskProps, formProps,object,onSave,connectedInputs,onOutDfValues,randomString } = props;
  
  const [previewInfo, setPreviewInfo] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [getPreviewFile] = useLazyGetPreviewFileQuery();
  const [getPreviewDatabase] = useLazyGetPreviewDatabaseQuery();
 
  const handlePreview = async () => {
    let data = {};
    let filetype;
    try {
      setLoading(true);
      const connectionType = (formProps?.values?.source_type || formProps?.values?.target_type)?.toLowerCase().replace(/\s/g, '');
        
        filetype = formProps.values.file_type;
        if (
          connectionType === "remoteserver" ||
          connectionType === "awss3" ||
          connectionType === "localserver"
        ) {
          data = {
            connection_id: formProps.values.connection_name,
            table_name: formProps.values.file_name,
            filepath: formProps.values.file_path,
            header: formProps.values.header,
            file_type: formProps.values.file_type,
            delimiter: formProps.values.delimiter,
            encoding: formProps.values.encoding,
            file_name:formProps.values.file_name          };
        } else {
          data = {
            connection_id: formProps.values?.connection_name,
            table_name: formProps.values?.table_name,
            schema_name: formProps.values?.schema,
            header: formProps.values?.select_columns,
            query: formProps.values?.query_params||formProps.values?.query,
            content_type: formProps?.values.content_type,
          };

        
      }

   

      let response;

      if (
        connectionType === "awss3" ||
        connectionType === "localserver" ||
        connectionType === "remoteserver"
      ) {
        response = await getPreviewFile({
          connectionType,
          filetype,
          params: data,
        });
      } else {
        response = await getPreviewDatabase({ connectionType, params: data });
      }

      if (response.isSuccess) {
        setPreviewInfo(response.data);
        setOpen(true);
        setError(null); 
      } else {
        throw new Error(response.error.data.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
 const [options,setOptions]=useState()


  const handleChangeOption = async () => {
    let data = {};
    let filetype;
    let connectionType;
    let response;
    
    try {
      setLoading(true);
      connectionType = (formProps?.values?.source_type || formProps?.values?.target_type)?.toLowerCase().replace(/\s/g, '');

      filetype = formProps.values.file_type;
  
      if (
        connectionType === "remoteserver" ||
        connectionType === "awss3" ||
        connectionType === "localserver"
      ) {
        data = {
          connection_id: formProps.values.connection_name,
          table_name: formProps.values.file_name,
          filepath: formProps.values.file_path,
          header: formProps.values.header,
          file_type: formProps.values.file_type,
          delimiter: formProps.values.delimiter,
          encoding: formProps.values.encoding,
          file_name:formProps.values.file_name, 
        };
      } else {
        data = {
          connection_id: formProps.values?.connection_name,
          table_name: formProps.values?.table_name,
          schema_name: formProps.values?.schema,
          header: formProps.values?.select_columns,
          query: formProps.values?.query_params,
          content_type: formProps?.values.content_type,
          query: formProps.values?.query 
        };
      }
  
      if (
        connectionType === "awss3" ||
        connectionType === "localserver" ||
        connectionType === "remoteserver"
      ) {
        response = await getPreviewFile({
          connectionType,
          filetype,
          params: data,
        });
      } else {
        response = await getPreviewDatabase({ connectionType, params: data });
      }
  
      if (response && response.isSuccess) {
        const responseData = response.data;
        if (responseData) {
          const aliasColumnsArray = responseData.metaDataInfo.map(item => item.aliasColumns);
          const actualColumnsArray = responseData.metaDataInfo.map(item => item.actualColumns);
          setOptions(aliasColumnsArray);
          const aliasColumnsString = aliasColumnsArray.join(",");
        }
            
      } else {
        throw new Error(response.error.data.message);
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
 
  
  useEffect(() => {
    if (object === "Input") {
      handleChangeOption(); 
    }
  }, [formProps?.values, formProps.values.connection_name, URL]);
  
  

  const { fields_list: fieldsList = [], additional_fields: additionalFields = [] } = taskProps || [];
  let taskParamFieldList = [...fieldsList];
  const handleSave = () => {

    onSave(formProps.values); 
  };
  const [additionalFieldsList, setAdditionalFieldsList] = useState([]);
  const findAdditionalFields = (sourceType, options) => {
    // Find the additional fields based on the source type
    const fields = additionalFields?.find((field) => field?.source_type === sourceType)?.field_list || [];
    
    // Clone the fields array and its objects to avoid mutation
    const updatedFields = fields.map(field => {
      // Clone the field object to avoid mutation
      const clonedField = { ...field };
  
      if (clonedField.field_id === "select_columns") {
        clonedField.option_list = options;
      }
      
      return clonedField;
    });
  
    return updatedFields;
  };
  
  const prevInputDfRef = useRef(formProps.values.input_df);

useEffect(() => {
  if (formProps && object === "Output") {
    let outputDf = null;

    // Find the output_df based on the label condition
    if (connectedInputs && connectedInputs.length > 0) {
      if (connectedInputs[0]?.label === "Filter") {
        outputDf = Object?.values(connectedInputs[0].Filter)?.find(obj => obj?.output_df);
      } else if (connectedInputs[0]?.label === "Expression") {
        outputDf = Object?.values(connectedInputs[0].Expression)?.find(obj => obj?.output_df);
      } else {
        outputDf = Object?.values(connectedInputs[0])?.find(obj => obj?.output_df);
      }
    }

    const outputDfValue = connectedInputs[0]?.output_df || outputDf?.output_df || "";

    // Only update formProps if the new value is different from the previous one
    if (outputDfValue && prevInputDfRef.current !== outputDfValue) {
      formProps.setValues((prevValues) => ({
        ...prevValues,
        input_df: outputDfValue,
      }));
      prevInputDfRef.current = outputDfValue; // Update the previous value reference
    } else if (!outputDfValue) {
      // Preserve the current input_df if no new value is found
      formProps.setValues((prevValues) => ({
        ...prevValues,
        input_df: prevValues.input_df, 
      }));
    }
  }
}, [formProps, connectedInputs, object]);

  const [able, setAble] = useState(false);
  const checkSelectColumns = useCallback(() => {
    const selectColumns = formProps?.values?.select_columns || '';
    setAble(!selectColumns);  // Disable if select_columns is empty
  }, [formProps]);

  useEffect(() => {
    if (formProps && object === "SQL") {
      const inputDfs = connectedInputs.reduce((accumulator, currentValue) => {
        if (currentValue.output_df) {
          accumulator.push(currentValue.output_df);
        }
        return accumulator;
      }, []);

      const inputDfValue = inputDfs.join(',');

      // Only update formProps if needed
      if (inputDfValue && formProps.values.input_df !== inputDfValue) {
        formProps.setValues((prevValues) => ({
          ...prevValues,
          input_df: inputDfValue,
        }));
      }

      // Check select_columns immediately without debounce
      checkSelectColumns();

    }
  }, [formProps, connectedInputs, checkSelectColumns, object]);

  // Immediate function to disable button and manage column appearance based on `script_type`
  const checkDisableButton = useCallback(() => {
    let shouldDisable = false;
    if (formProps.values.script_type === 'shell_script') {
      const selectColumns = connectedInputs[0]?.select_columns || '';

      // Update select_columns only if necessary
      // if (formProps.values.select_columns !== selectColumns) {
        formProps.setValues((prevValues) => ({
          ...prevValues,
          select_columns: selectColumns,
          select_columns_disabled: true, // Disable select_columns for shell_script
        }));
      // }
      shouldDisable = !selectColumns;
    } else if (formProps.values.script_type === 'inline_code') {
      // Enable select_columns for inline_code
      formProps.setValues((prevValues) => ({
        ...prevValues,
        select_columns_disabled: false, // Reset to enable select_columns
      }));
      shouldDisable = !formProps.values.select_columns_1;
    }

    // Set `able` based on whether the fields should be disabled
    setAble(shouldDisable);
  }, [formProps, connectedInputs]);

  useEffect(() => {
    if (formProps && object === "Python") {
      const inputDfs = connectedInputs.reduce((accumulator, currentValue) => {
        if (currentValue.output_df) {
          accumulator.push(currentValue.output_df);
        }
        return accumulator;
      }, []);

      const inputDfValue = inputDfs.join(',');

      // Only update formProps if needed
      if (inputDfValue && formProps.values.input_df !== inputDfValue) {
        formProps.setValues((prevValues) => ({
          ...prevValues,
          input_df: inputDfValue,
        }));
      }

      // Check immediately based on script_type and select_columns
      checkDisableButton();
    }
  }, [formProps, connectedInputs, object, checkDisableButton]);
  taskParamFieldList = taskParamFieldList
  .filter(field => field.field_id !== 'output_df' && field.field_id !== 'input_df')
  .sort((a, b) => a.display_sequence - b.display_sequence);
  additionalFieldsList.sort((a, b) => a.display_sequence - b.display_sequence);

  

 
  const inputRandomString = () => {
    var randomstring = require("randomstring");
    let generatedRandomString = randomstring.generate({
      length: 3,
      charset: "alphabetic",
      capitalization: "lowercase",
    });
    let inputRandomString = "inp_".concat(generatedRandomString);
    return inputRandomString;
  };
  
  const sqlRandomString = () => {
    var randomstring = require("randomstring");
    let generatedRandomString = randomstring.generate({
      length: 3,
      charset: "alphabetic",
      capitalization: "lowercase",
    });
    let inputRandomString = "sql_".concat(generatedRandomString);
    return inputRandomString;
  };
  const pythonRandomString = () => {
    var randomstring = require("randomstring");
    let generatedRandomString = randomstring.generate({
      length: 3,
      charset: "alphabetic",
      capitalization: "lowercase",
    });
    let inputRandomString = "python_".concat(generatedRandomString);
    return inputRandomString;
  };

  const joinerRandomString = () => {
    var randomstring = require("randomstring");
    let generatedRandomString = randomstring.generate({
      length: 3,
      charset: "alphabetic",
      capitalization: "lowercase",
    });
    let inputRandomString = "jnr_".concat(generatedRandomString);
    return inputRandomString;
  };
  const [isSwapping, setIsSwapping] = useState(false);
const [isSwapped, setIsSwapped] = useState(false);
const isInitialMount = useRef(true);

useEffect(() => {
  if (isInitialMount.current) {
    isInitialMount.current = false;
  }

  if (formProps && object === "Joiner") {
    const getSelectColumns = (input, suffix) => {
      if (!input) return [];
    
      let selectColumnsKey = '';
    
      // Determine which key to use based on script_type
      if (input.script_type === 'shell_script') {
        selectColumnsKey = 'select_columns';
      } else if (input.script_type === 'inline_code') {
        selectColumnsKey = 'select_columns_1';
      } else {
        // Fallback to select_columns if script_type is not defined or doesn't match
        selectColumnsKey = 'select_columns';
      }
    
      const selectColumns = input[selectColumnsKey];
    
      return typeof selectColumns === 'string'
        ? selectColumns.split(',').map(column => column.trim() + suffix)
        : Array.isArray(selectColumns)
        ? selectColumns.map(column => column + suffix)
        : [];
    };

    const selectColumns_left = getSelectColumns(connectedInputs[0], '_left');
    const selectColumns_right = getSelectColumns(connectedInputs[1], '_right');
    const concatenatedSelectColumns = [...selectColumns_left, ...selectColumns_right].join(',');

    // Determine if swap is needed based on select_columns suffixes
    const savedSelectColumns = formProps.values.select_columns?.split(',') || [];
    console.log(savedSelectColumns,'ppp')
    const isSwapNeeded = savedSelectColumns.some(col => col.endsWith('_right')) &&
                         savedSelectColumns.every(col => !col.endsWith('_left'));

    if (isSwapNeeded) {
      setIsSwapped(true);
    }

    const updateValues = (swap = false) => {
      const leftInput = swap 
        ? connectedInputs[1]?.output_df 
        : connectedInputs[0]?.output_df || Object.values(connectedInputs[0] || {}).flatMap(obj => Object.values(obj || {})).find(obj => obj?.output_df)?.output_df;
      const rightInput = swap 
        ? connectedInputs[0]?.output_df 
        : connectedInputs[1]?.output_df || Object.values(connectedInputs[1] || {}).flatMap(obj => Object.values(obj || {})).find(obj => obj?.output_df)?.output_df;
      const updatedSelectColumns = swap
        ? selectColumns_right.map(col => col.replace('_right', '_left')).concat(
            selectColumns_left.map(col => col.replace('_left', '_right'))
          ).join(',')
        : concatenatedSelectColumns;

      formProps.setValues({
        ...formProps.values,
        left_input_df: leftInput || '',
        right_input_df: rightInput || '',
        select_columns: updatedSelectColumns
      });
    };

    if (!isSwapping) {
      if (!formProps.values.left_input_df || !formProps.values.right_input_df ||
          formProps.values.left_input_df !== (isSwapped ? connectedInputs[1]?.output_df : connectedInputs[0]?.output_df) ||
          formProps.values.right_input_df !== (isSwapped ? connectedInputs[0]?.output_df : connectedInputs[1]?.output_df) ||
          formProps.values.select_columns !== (isSwapped ? concatenatedSelectColumns.split(',').map(col => col.includes('_left') ? col.replace('_left', '_right') : col.replace('_right', '_left')).join(',') : concatenatedSelectColumns)) {
        updateValues(isSwapped);
      }
    }

    if (formProps.values.output_df === '') {
      formProps.setValues({
        ...formProps.values,
        output_df: joinerRandomString()
      });
    }

    const outDfValues = connectedInputs?.map(input => input?.output_df);
    onOutDfValues(outDfValues);
  }
}, [formProps, object, connectedInputs, isSwapping, isSwapped, onOutDfValues]);

const handleSwap = () => {
  const { left_input_df, right_input_df, select_columns } = formProps.values;

  if (!select_columns || typeof select_columns !== 'string') {
    console.error("select_columns is undefined or not a string");
    return;
  }

  // Set the swapping flag to true
  setIsSwapping(true);

  // Swap the left and right select columns
  const newSelectColumns = select_columns.split(',').map(column => {
    if (column.endsWith('_left')) {
      return column.replace('_left', '_right');
    } else if (column.endsWith('_right')) {
      return column.replace('_right', '_left');
    } else {
      return column;
    }
  }).join(',');

  // Update state immutably and persist the swapped state
  formProps.setValues({
    ...formProps.values,
    left_input_df: right_input_df,
    right_input_df: left_input_df,
    select_columns: newSelectColumns
  });

  // Update swap state
  setIsSwapped(!isSwapped);
  setIsSwapping(false);
};


if (object === 'Input' && formProps.values.output_df === '') {
  formProps.setValues({
    output_df:inputRandomString()
  })
  // formProps.values.output_df = inputRandomString()
}
if (object === 'SQL' && formProps.values.output_df === '') {
  formProps.setValues({
    output_df:sqlRandomString()
  })
}
if (object === 'Python' && formProps.values.output_df === '') {
  formProps.setValues({
    output_df:pythonRandomString()
  })
  // formProps.values.output_df = pythonRandomString()
}
  

  useEffect(() => {
    if (formProps && object === "Input") {
      const additionalFields = findAdditionalFields(formProps.values.source_type, options);
      setAdditionalFieldsList(additionalFields);
    } else if (formProps && object === "Output") {
      const additionalFields = findAdditionalFields(formProps.values.target_type, options);
      setAdditionalFieldsList(additionalFields);
    } else {
      setAdditionalFieldsList([]);
    }
  }, [formProps.values, options]);
  


  const handleClose = () => {
    setOpen(false);
    setError(null);
  };
  return (
    <Box margin="2rem 15rem">
      <Box
        display="grid"
        gap="10px"
        rowGap="10px"
        gridTemplateColumns="repeat(2, 1fr)" // Each column takes up half of the available space
      >
        {/* Rendering taskParamFieldList */}
        {taskParamFieldList.map((field, index) => (
          <div key={`row_${index}`}>
            <Element
              key={field.field_id}
              field={field}
              values={formProps.values}
              touched={formProps.touched}
              errors={formProps.errors}
              handleBlur={formProps.handleBlur}
              handleChange={formProps.handleChange}
              disabled={formProps.values.select_columns_disabled}
            />
          </div>
        ))}
        

        {additionalFieldsList.map((field, index) => (
          <div key={`additional_row_${index}`}>
            <Element
              key={field.field_id}
              field={field}
              values={formProps.values}
              touched={formProps.touched}
              errors={formProps.errors}
              handleBlur={formProps.handleBlur}
              handleChange={formProps.handleChange}
            />
          </div>
        ))}
      </Box>
      <br></br>
      <Button
  variant="contained"
  sx={{
    backgroundColor: able ? "grey" : "rgb(0, 72, 190)",
    color: "white",
    "&:disabled": {
      backgroundColor: "grey",
      color: "white",
    }
  }}
  onClick={handleSave}
  disabled={able}
>
  Update
</Button>
   
      {object === 'Input' || object === 'Output' ? (
        <Button
          variant="contained"
          sx={{ backgroundColor: "rgb(0, 72, 190)!important", marginLeft: "10px", color: "white" }}
          onClick={handlePreview}
        >
          Preview
        </Button>
      ) : null}
       {object === 'Joiner' && (
        <Button
          variant="contained"
          sx={{ backgroundColor: "rgb(0, 72, 190)!important", marginLeft: "10px", color: "white" }}
          onClick={handleSwap}
        >
          Swap
        </Button>
      )} 
      <Dialog
            open={open}
            PaperProps={{
              style: {
                marginTop: "75px",
                minWidth: "90%",
                height: "90vh",
                maxWidth: "none",
                maxHeight: "none",
                resize: true,
              },
            }}
          >
            <DialogTitle
              style={{
                backgroundColor: "#0cf5ea8a",
                display: "flex",
                justifyContent: "center",
                padding: "8px 16px", 
                alignItems: "center",
              }}
            >
          
                  {(() => {
                    const connectionType = formProps?.values?.source_type?.toLowerCase().replace(/\s/g, '');
                    return connectionType === "awss3" ||
                      connectionType === "localserver" || connectionType === "restapi" || connectionType === "remoteserver" ? (
                      <> <center></center>
                        PREVIEWING :{" "}
                        {formProps?.values?.file_name || "REST API"}
                      </>
                    ) : (
                      <> <center></center>
                        PREVIEWING DATABASE TABLES : {" "}
                        {formProps?.values?.table_name}
                      </>
                    );
                  })()}
                <IconButton
                      style={{
                        position: "absolute",
                        right: "5px",
                      }}
                      onClick={handleClose}
                      color="inherit"
                    >
                      <CloseIcon style={{ fontSize: "20px" }} />
                    </IconButton>

           

        
            </DialogTitle>
            <DialogContent>
              <PreviewData
                handleClose={handlePreview}
                previewInfo={previewInfo}
              />
            </DialogContent>
          </Dialog>
    </Box>
  );
};

export default TransformationForm;