import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  FormHelperText,
  StepLabel,
  useTheme,
  Stepper,
  Dialog,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Checkbox,
  InputLabel,
  MenuItem,
  DialogTitle,
  DialogActions,
  StepIcon,
  Step,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  useGetConnectionOptionsQuery,
  useGetConnectionByIdQuery,
  useLazyGetBulkIngPreviewFileQuery,
} from "../state/apiSlice";
import { useFormik } from "formik";
import * as yup from "yup";
import BulkIngestionParamsForm from "./BulkIngestionParamsForm";
import BulkIngestionPropertiesForm from "./BulkIngestionPropertiesForm";
import TaskForm from "./TaskForm";
import BulkForm from "./BulkForm";
import { isEmpty } from "lodash";
import { ConstructionOutlined, ModeEditOutline } from "@mui/icons-material";
import Popup from "components/Popup";
const findSectionField = (formObject, section, field = null) => {
  const sectionRecord = formObject?.find((rec) => rec.section === section);

  if (field) {
    const fieldRecord = sectionRecord.fields_list.find(
      (rec) => rec.field_id === field
    );

    return fieldRecord;
  }

  return sectionRecord;
};


const BulkIngestionFormEdit = (props) => {
  const { taskFormFields,onFormValuesChange, values: editValue, addOrEditRecord } = props;
  const steps = ["Main", "Source", "Target", "Review & Save"];
  const theme = useTheme();

  const [getBulkPreviewDatabase] = useLazyGetBulkIngPreviewFileQuery();
  const { data: connectionOptions } = useGetConnectionOptionsQuery();

  const mainRecord = findSectionField(taskFormFields, "Main");
  const sourceRecord = findSectionField(taskFormFields, "Source");
  const targetRecord = findSectionField(taskFormFields, "Target");

  const [extractionType, setExtractionType] = useState([]);
  const [actionOnTable, setActionOnTable] = useState([]);
  const [auditFields, setAuditFields] = useState([]);

  const [numSelected, setNumSelected] = useState(0);
  const [mergedData, setMergedData] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [selectedRow, setSelectedRow] = useState(null);

  const [editedRow, setEditedRow] = useState(null);
  const [rowData, setRowData] = useState(null);

  const [recordForEdit, setRecordForEdit] = useState(null);
  const [rowIdSQL, setRowIdSQL] = useState(null);

  const [openSqlExecutionFormPopup, setopenSqlExecutionFormPopup] =
    useState(false);

  
  const handleClosePopup = () => {
    setEditedRow(null);
    setIsPopupOpen(false);
  };
 
  
  const mainForm = mainRecord?.fields_list || [];
  const sourceFormData = sourceRecord?.fields_list || [];
  const targetFormData = targetRecord?.fields_list || [];

  const [currSrcConnSubType, setCurrSrcConnSubType] = useState(null);
  const [currTgtConnSubType, setCurrTgtConnSubType] = useState(null);
  const [sourceForm, setSourceForm] = useState(null);
  const [targetForm, setTargetForm] = useState(null);
  const [previewInfo, setPreviewInfo] = useState(null);
  const [previewSchemaInfo, setPreviewSchemaInfo] = useState(null);

  const sourceFormFieldIds = sourceForm?.field_list?.map(
    (field) => field.field_id
  );
  const mainFormFieldIds = mainForm?.map(
    (field) => field.field_id
  );

  const targetFormFieldIds = targetForm?.field_list?.map(
    (field) => field.field_id
  );
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({});

  const [stepFormValues, setStepFormValues] = useState({});
  const [formType, setFormType] = useState(null);
  useEffect(() => {
    if (activeStep === 0) {
      setFormType("source");
    } else if (activeStep === 1) {
      setFormType("source");
    } else if (activeStep === 2) {
      setFormType("target");
    } else if (activeStep === 3) {
      setFormType("review");
    }
  }, [activeStep]);

  let initValues = {};

  if (editValue === null || editValue === undefined) {
    initValues = {
      ...mainForm?.initialvalues || {},
      ...sourceForm?.initialvalues || {},
      ...targetForm?.initialvalues || {},
    };
  } else {
    const { details, ...taskRecord } = editValue;
  
    if (details && Object.keys(details).length !== 0) {
      const { Source, Target } = details;
      let detailRecord = Object.assign({}, Source, Target);
      initValues = { ...taskRecord, ...detailRecord };
    } else {
      initValues = { ...taskRecord };
    }
  }
  
  const keys = Object.keys(initValues);
  




  const formik = useFormik({
    initialValues: initValues,
    // onSubmit: (values, { resetForm }) => {
    //   handleFormSubmit(values);
    // },
  });

  const srcSourceOption = formik?.values?.source_type;
  const tgtTargetOption = formik?.values?.target_type;
  const [selectedConnsrc, setSelectedConnectionListSrc] = useState({});
  const [selectedConntgt, setSelectedConnectionListTgt] = useState({});

  useEffect(() => {
    const selectedConnection = connectionOptions?.find(
      (option) => option?.connection_type === srcSourceOption
    );

    if (selectedConnection) {
      const selectedConnectionList = selectedConnection.connection_list;
      setSelectedConnectionListSrc(selectedConnectionList);
    } else {
      console.log("No matching connection type found for source_type");
    }
  }, [srcSourceOption, connectionOptions]);

  useEffect(() => {
    const selectedConnection = connectionOptions?.find(
      (option) => option?.connection_type === tgtTargetOption
    );

    if (selectedConnection) {
      const selectedConnectionList = selectedConnection.connection_list;
      setSelectedConnectionListTgt(selectedConnectionList);
    } else {
      console.log("No matching connection type found for target_type");
    }
  }, [tgtTargetOption, connectionOptions]);


  const [sourceType, setSourceType] = useState(null);
  const [targetType, setTargetType] = useState(null);


  useEffect(() => {
    const sourceTypeFromFormik = formik?.values?.source_type;
    const targetTypeFromFormik = formik?.values?.target_type;

    setSourceType(sourceTypeFromFormik);
    setTargetType(targetTypeFromFormik);

  }, [formik?.values?.source_type, formik?.values?.target_type]);


  useEffect(() => {
    changeConnSubType("source", sourceType);
    changeConnSubType("target", targetType)
  }, [sourceType, targetType]);
  const [connectionType1, setConnectionType1] = useState(null);
  const [connectionType2, setConnectionType2] = useState(null);
  useEffect(() => {
    changeConnSubType('source', sourceType);
  }, [formik?.values?.src_connection_name, connectionType1]);


  useEffect(() => {
    changeConnSubType('target', targetType);
  }, [formik?.values?.tgt_connection_name, connectionType2]);

  async function changeConnSubType(type, connSubType) {
    if (type === "source") {
      const connSubType = "Files";
      setCurrSrcConnSubType(connSubType);
      const srcSelectedItem = sourceFormData.find(
        (item) => item.connection_subtype === connSubType
      );

      if (srcSelectedItem) {
        const updatedFieldList = await Promise.all(
          srcSelectedItem.field_list.map(async (field) => {
            if (field.field_id === "src_connection_name") {
              return { ...field, option_list: selectedConnsrc };
            } else if (field.field_id === "src_schema_name") {
              const data = {
                connection_id: formik.values.src_connection_name,
              };

              const response = await getBulkPreviewDatabase({
                connectionType: connectionType1?.toLowerCase(),
                params: data,
              });
              if (response.isSuccess) {
                return { ...field, option_list: response.data.schemas || [] };
              } else {
                console.error('Error fetching schemas:', response.error.data.message);
                return field;
              }
            } else {
              return field;
            }
          })
        );

        setSourceForm((prevSourceForm) => ({
          ...prevSourceForm,
          ...srcSelectedItem,
          field_list: updatedFieldList,
        }));
      }
    }

    if (type === "target") {
      const connSubType = targetType;
      setCurrTgtConnSubType(connSubType);
      const tgtSelectedItem = targetFormData.find(
        (item) => item.connection_subtype === connSubType
      );

      if (tgtSelectedItem) {
        const updatedFieldList = await Promise.all(
          tgtSelectedItem.field_list.map(async (field) => {
            if (field.field_id === "tgt_connection_name") {
              return { ...field, option_list: selectedConntgt };
            } else {
              return field;
            }
          })
        );
        if (tgtSelectedItem) {
          const updatedFieldList = await Promise.all(
            tgtSelectedItem.field_list.map(async (field) => {
              if (field.field_id === "tgt_connection_name") {
                return { ...field, option_list: selectedConntgt };
              } else if (field.field_id === "tgt_schema_name") {
                const data = {
                  connection_id: formik.values.tgt_connection_name,
                };

                const response = await getBulkPreviewDatabase({
                  connectionType: connectionType2?.toLowerCase(),
                  params: data,
                });
                if (response.isSuccess) {
                  return { ...field, option_list: response.data.schemas || [] };
                } else {
                  console.error('Error fetching schemas:', response.error.data.message);
                  return field;
                }
              } else {
                return field;
              }
            })
          );

          setTargetForm((prevTargetForm) => ({
            ...prevTargetForm,
            ...tgtSelectedItem,
            field_list: updatedFieldList,
          }));
        }
      }
    }
  }

  function validateFormSchema(formData) {
    let validSchemaObject = {};
    formData?.forEach(({ field_id, required }) => {
      if (required === "Y") {
        validSchemaObject[field_id] = yup
          .string()
          .required(`${field_id} is required`);
      }
      if (field_id === "task_name") {
        validSchemaObject[field_id] = validSchemaObject[field_id]?.matches(
          /^[a-zA-Z0-9_]+$/,
          `${field_id} must be alphanumeric and may contain underscores only`
        );
      }
    });
    return yup.object().shape(validSchemaObject);
  }
 

  if (formType === "source" && currSrcConnSubType === null) {
    if (editValue && editValue.source !== "none") {
      changeConnSubType("source", editValue.source);
    } else {
      changeConnSubType("source", sourceFormData[0].connection_subtype);
    }
  }

  if (formType === "target" && currTgtConnSubType === null) {
    if (editValue && editValue.target !== "none") {
      changeConnSubType("target", editValue.target);
    } else {
      changeConnSubType("target", targetFormData[0].connection_subtype);
    }
  }



  const formTask = (formikProps) => {
    return (
      <BulkForm
        taskProps={mainRecord}
        formProps={formikProps}
        object={"Task"}
      />
    );
  };
  
  const souConnType = formik?.values?.source_type;
  const tgtConnType = formik?.values?.target_type;
  const formSourceTask = (formikProps, sourceConnType) => {

    return (
      <BulkIngestionParamsForm
        taskProps={{
          currSrcConnSubType: sourceConnType,
          fieldList: sourceForm?.field_list,
        }}
        formProps={formikProps}

        handleConnSubTypeChange={handleSourceConnSubTypeChange}
        object={"Source"}
      />
    );
  };
 

  const formEditTask = (formikProps, sourceConnType, row) => {

    const fieldSet = new Set();
    const modifiedFieldList = sourceForm?.field_list?.filter((field) => {
  
      const isExcluded = [
        "src_connection_name",
        "src_file_path",
        "src_files_filter_name",
        "src_subfolder_included",
      ].includes(field.field_id);
  
      if (!isExcluded) {
        if (field.field_id === "src_connection_name") {
          const isNotDuplicate = !fieldSet.has(field.field_name);
  
          if (isNotDuplicate) {
            fieldSet.add(field.field_name);
          }
  
          return isNotDuplicate;
        } else {
          return true;
        }
      }
  
      return false;
    });
  
    return (
      <BulkIngestionParamsForm
        taskProps={{
          currSrcConnSubType: sourceConnType,
          fieldList: modifiedFieldList,
        }}
        formProps={formikProps}
        handleConnSubTypeChange={handleSourceConnSubTypeChange}
        object={"Source"}
      />
    );}
  

    const formTargetTask = (formikProps, targetConnType) => {
      const fieldSet = new Set();
    
      const modifiedFieldList = targetForm?.field_list?.filter((field) => {
        if (tgtConnType === "DB") {
          const isExcluded = [
            "tgt_object_name",
            "tgt_audit_fields",
            "tgt_action_on_table",
            "tgt_primary_key",
          ].includes(field.field_id);
          return !isExcluded;
        }
    
        if (souConnType === "Files" && tgtConnType === "Files") {
          const includedFields = [
            "tgt_connection_name",
            "tgt_file_path",
            "tgt_object_prefix_name",
            "tgt_object_sufix_name",
          ];
          return includedFields.includes(field.field_id);
        }
    
        if (field.field_id === "tgt_connection_name") {
          const isNotDuplicate = !fieldSet.has(field.field_name);
    
          if (isNotDuplicate) {
            fieldSet.add(field.field_name);
          }
    
          return isNotDuplicate;
        }
    
        return true; 
      });
      return (
        <BulkIngestionParamsForm
          taskProps={{
            currSrcConnSubType: targetConnType,
            fieldList: modifiedFieldList,
          }}
          formProps={formikProps}
          handleConnSubTypeChange={handleTargetConnSubTypeChange}
          object={"Target"}
        />
      );
    };
    

  function handleSourceConnSubTypeChange(subTypeValue) {
    changeConnSubType("source", subTypeValue);
  }

  function handleTargetConnSubTypeChange(subTypeValue) {
    changeConnSubType("target", subTypeValue);
  }

  

  let formComponent = null;
  if (formType === "main") {
    formComponent = formTask(formik);
  } else if (formType === "source") {
    formComponent = formEditTask(formik, currSrcConnSubType);
  } else if (formType === "target") {
    formComponent = formTargetTask(formik, currTgtConnSubType);
  }


  
  const handleSave = (formValues) => {
    console.log("formik.values:", formValues);
    onFormValuesChange(formValues);
  };
  return (
    <>
      <Box sx={{ padding: 2 }}>
          <Grid item xs={12} md={8} sx={{ padding: "20px" }}>
            {formComponent}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>

          </Box>

        </Grid>
        <Button color="primary" variant="contained" onClick={() => handleSave(formik.values)}>
          Submit
        </Button>
      </Box>
    </>
  );
}  

export default BulkIngestionFormEdit;
