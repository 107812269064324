import React, { useState, useEffect } from "react";
import {
  Menu as MenuIcon,
  ArrowDropDownOutlined,
  Lock,
} from "@mui/icons-material";
import Popup from "./Popup";
import {
  AppBar,
  Box,
  Typography,
  Button,
  IconButton,
  Toolbar,
  useTheme,
  MenuItem,
  Menu,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import FlexBetween from "./FlexBetween";
import { useDispatch } from "react-redux";
import { setOpenLogin, logOut } from "../state/authSlice";
import { useNavigate } from "react-router-dom";

const NavBar = ({ user, isSidebarOpen, setIsSidebarOpen }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem("userDetails");
    dispatch(logOut());
    navigate("/");
  };
  const handleChangePassword = () => {
    setAnchorEl(null);
    navigate(`/changepassword/${user.login_id}`);
  };

  const [markdownContentRel, setMarkdownContentRel] = useState("");
  const [openPopupHelp, setOpenPopupHelp] = useState(false);

  useEffect(() => {
    fetch("/docs/release_ver.md")
      .then((response) => response.text())
      .then((data) => setMarkdownContentRel(data))
      .catch((error) => console.error("Error fetching release_ver.md:", error));
  }, []);

  const closeModal = () => {
    setOpenPopupHelp(false);
  };

  return (
    <AppBar
      sx={{
        position: "fixed", // Changed to fixed
        top: 0, // Stick to the top
        left: 0,
        width: "100%", // Ensure it spans the full width
        zIndex: theme.zIndex.drawer + 1, // Ensure it's above the sidebar
        minHeight: "45px",
        maxHeight: "45px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2) !important",
        backgroundColor: "var(--primary-color) !important",
        "& .MuiToolbar-root": {
          mt: "-10px",
        },
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {/* LEFT SIDE */}
        <FlexBetween>
          {user && Object.keys(user).length > 0 && (
            <IconButton
              onClick={() => setIsSidebarOpen(!isSidebarOpen)}
              sx={{ ml: -2 }}
            >
              <MenuIcon sx={{ color: "white", fontSize: 28 }} />
            </IconButton>
          )}
          <FlexBetween borderRadius="9px" gap="2rem" p="0.1rem 1.5rem">
            <Typography
              variant="h3"
              fontWeight="semibold"
              sx={{ color: "white", fontFamily: "Poppins" }}
            >
              iKart
            </Typography>
          </FlexBetween>
        </FlexBetween>

        {/* RIGHT SIDE */}
        <FlexBetween gap="1rem">
          <Button
            onClick={() => setOpenPopupHelp(true)}
            sx={{
              color: "white",
              fontSize: "15px",
              fontWeight: "semibold",
              fontFamily: "Poppins",
            }}
          >
            ReadMe
          </Button>
          <FlexBetween>
            {!user || Object.keys(user ?? {}).length === 0 ? (
              <Button
                color="inherit"
                startIcon={
                  <Lock
                    sx={{ color: "white", fontSize: 16, verticalAlign: "middle" }}
                  />
                }
                onClick={() => dispatch(setOpenLogin({ openLogin: true }))}
                sx={{
                  fontSize: 15,
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Poppins",
                }}
              >
                Login
              </Button>
            ) : (
              <>
                <Button
                  onClick={handleClick}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    textTransform: "none",
                    gap: "1rem",
                  }}
                >
                  <Box
                    as="span"
                    height="30px"
                    width="30px"
                    borderRadius="50%"
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontSize: "14px",
                      color: "white",
                      fontWeight: "bold",
                      backgroundColor: theme.palette.secondary[300],
                    }}
                  >
                    {user?.full_name
                      ?.split(" ")
                      .map((word) => word.charAt(0).toUpperCase())
                      .join("")}
                  </Box>
                  <Box textAlign="left">
                    <Typography
                      fontWeight="bold"
                      fontSize="0.75rem"
                      sx={{ color: "white", fontFamily: "Poppins" }}
                    >
                      {user.full_name}
                    </Typography>
                    <Typography
                      fontSize="0.64rem"
                      sx={{ color: "white", fontFamily: "Poppins" }}
                    >
                      {user.user_email}
                    </Typography>
                  </Box>
                  <ArrowDropDownOutlined
                    sx={{
                      color: "white",
                      fontSize: "25px",
                    }}
                  />
                </Button>
                <Menu
                  anchorEl={anchorEl}
                  open={isOpen}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "30px",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      fontSize="0.75rem"
                      sx={{
                        color: theme.palette.secondary[100],
                        marginBottom: "8px",
                      }}
                    >
                      {user.full_name}
                    </Typography>

                    <Box
                      as="span"
                      height="32px"
                      width="32px"
                      borderRadius="50%"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "16px",
                        color: "#000",
                        fontWeight: "bold",
                        backgroundColor: "#e0e0e0",
                        textAlign: "center",
                        marginBottom: "8px",
                      }}
                    >
                      {user?.full_name
                        ?.split(" ")
                        .map((word) => word.charAt(0).toUpperCase())
                        .join("")}
                    </Box>

                    <MenuItem onClick={handleChangePassword}>
                      Change Password
                    </MenuItem>

                    <MenuItem onClick={handleLogout}>Log Out</MenuItem>
                  </Box>
                </Menu>
              </>
            )}
          </FlexBetween>
        </FlexBetween>
      </Toolbar>
      <Popup
        title="Release Notes"
        openPopup={openPopupHelp}
        width={"md"}
        okBtn={true}
        handleOkBtnClick={closeModal}
        setOpenPopup={setOpenPopupHelp}
      >
        <ReactMarkdown>{markdownContentRel}</ReactMarkdown>
      </Popup>
    </AppBar>
  );
};

export default NavBar;
